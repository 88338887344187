import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({   
   container:{
        padding: "2%",
        // border: "1px solid grey",
        boxShadow: "0 0 5px 2px #aaafb3",
        margin:"2% 1% 1% 1%",
        borderRadius:'3px',
        width:'48%'
   }
});

export default function LeaveCard({ children, className }){
    const classes = useStyles();
    return (
        <div className={`${classes.container} ${className}`}>
            {children}
        </div>
    )
}