import React, {useState,useEffect} from "react";
import { CDBBtn } from "cdbreact";
import NameLogo from '../images/NameLogo.png'
import {useSelector} from "react-redux"
import { selectUser } from 'src/stores/userSlice';
import axios from "axios";
import { toast } from 'react-toastify';
import { MyProfile } from "./MyProfile"
import { USProfile } from "./USProfile"
// import utAvatar from "../images/utAvatar.png"

export const MyAccount = () => {
	const user = useSelector(selectUser)
	const [profile,setProfile] = useState({})
	const [country,setCountry] = useState('')
	const [profPic, setProfPic] = useState('')
	const DEFAULT_LOCATION = "India";

	const getMyDetails = async () =>{
		try{
			const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/profile`)
			setProfile(data[0])
			setCountry(data[0]?.EMP_COUNTRY)
			let img_path =  data[0]?.IMG_PATH ? `${process.env.REACT_APP_PUBLIC_URL}/${data[0]?.IMG_PATH}` :  `${process.env.REACT_APP_PUBLIC_URL}/public/img/utAvatar.png`
			setProfPic(img_path)
		}catch(error){
			console.log(error)
		}
	}

	const openFileDialogBox = () => {
		let input = document.getElementById("imgDialogBox");
      	input.click();
	}

	const uploadImage = async (evt) => {
	 	var files = evt.target.files || evt.dataTransfer.files;
      	if (!files.length) return;
      	let formData = new FormData();
      	formData.append("file", files[0]);
		try{
			await axios.post(`${process.env.REACT_APP_BASE_URL}/bio/uploadImage`, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			})		
			toast.success("Image uploaded successfully",{autoClose:3000})			
			setTimeout(() => {
				window.location.reload()
			}, 3000);			
		}catch(error){				
			let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
			toast.error(errmsg)
		}
	}

	useEffect(()=>{
		getMyDetails();
	},[])

	return (
		<div className="d-flex profile">
			<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
				<div style={{ height: "100%" }}>
					<div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
						<h2 >Profile</h2>
						<div className="container rounded bg-white " style={{ margin: "5px", boxShadow: "0 0 5px 2px #aaafb3" }} >
							<div className="row">
								<div className="col-md-3">
									<div className="d-flex flex-column align-items-center text-center p-2">
										<div className="avatar-container" style={{ marginTop: "170px" }}>
											<img												
												src={profPic}
												alt={user?.name}
												width="150px"
												height="150px"
												className="img-thumbnail"
												style={{ objectFit: "cover", borderRadius: "50%" }}
											/>
											
										</div>
										<span className="font-weight-bold">{user?.name}</span>
										<span className="text-black-50" style={{marginBottom:"1rem"}}>{user?.mail}</span>
										<CDBBtn color="dark" className="btn btn-dark rounded" flat onClick={openFileDialogBox}>
											change profile picture
										</CDBBtn>
										<input type="file" name="images" id="imgDialogBox" style={{visibility: "hidden"}} onChange={uploadImage} accept=".jpg, .jpeg, .png"/>										
									</div>
								</div>
								<div className="col-md-8">
									{
										country?.toLowerCase() === DEFAULT_LOCATION?.toLowerCase() ? <MyProfile/> : <USProfile/>
									}
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


