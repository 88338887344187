import React, { useEffect, useState } from 'react'
import "../css/ApplyLeave.css"
import "../css/commonStyles.css"
import ms from "ms";
import { CDBBtn } from 'cdbreact'
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from "react-redux"
import { selectUser } from "../stores/userSlice";
import { toast } from 'react-toastify';
import LeaveCard from 'src/components/LeaveCard';
import "../css/leave.css"
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    selectBox: {
        width: '100%',
        marginBottom: '15px'
    },
    dateContainer: {
        width: "13rem"
    },
    container: {
        display: "flex",
        flexDirection: "row"
    },
    red: {
        color: "red",
        fontSize: "12px"
    }
});

function ApplyLeave(props) {
    const classes = useStyles();
    const user = useSelector(selectUser)
    const [flag, setFlag] = useState(false);
    const [leaves, setLeaves] = useState([]);
    const [columns, setColumns] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [officeHolidays, setOfficeHolidays] = useState([]);
    const [holidayCols, setHolidayCols] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [minDate, setMinDate] = useState(null)
    const [aprMax, setAprMax] = useState(null)
    const currentYear = moment().format("YYYY");
    const [daysDisabled, setDaysDisabled] = useState(true);
    const [balance, setBalance] = useState({});
    //Initialize leave request for edit type
    const [editLeave, setEditLeave] = useState({})
    const leaveOptions = [
        { value: "Personal Leave", label: "Personal Leave" },
        { value: "Sick/Medical Leave", label: "Sick/Medical Leave" },
        { value: "Emergency Leave", label: "Emergency Leave" },
        { value: "Maternity Leave", label: "Maternity Leave" },
        { value: "Paternity Leave", label: "Paternity Leave" },
        { value: "Bereavement Leave", label: "Bereavement Leave" },
        { value: "Special Leave", label: "Special Leaves - Birthday/ Marriage Anniversary" }
    ];
    const initialState = {
        leaveType: '',
        startDt: '',
        endDt: '',
        reason: '',
    }
    const [state, setState] = useState(initialState)
    const [FullOrHalf, setFullOrHalf] = useState(false)
    useEffect(() => {
        if (state.startDt == state.endDt && state.startDt !== "") {
            setDaysDisabled(false)
        } else {
            setFullOrHalf(false);
            setDaysDisabled(true)
        }
    }, [state])


    function handleChange(evt) {
        const value = evt.target.value;
        const day = new Date(value).getUTCDay();

        if (day === 0 || day === 6) {
          evt.target.value = "";
          toast.error(
            "Saturdays and Sundays are not allowed. Please select a weekday."
          );
          setState((prevState) => ({
            ...prevState,
            startDt: "",
            endDt: "",
          }));
        } else {
          // If it's a valid weekday, call handleChange to update the state
          setState({
            ...state,
            [evt.target.name]: value,
          });
        }
    }

    function handleFullOrHalf() {
        setFullOrHalf(!FullOrHalf);
    }

    const isInArray = (value) => {
        return (officeHolidays.find(item => { return item === value }) || []).length > 0;
    }

    const findNoOfDays = (sDate, eDate) => {
        const startDate = new Date(sDate)
        const endDate = new Date(eDate);
        endDate.setDate(endDate.getDate() + 1);
        const daysOfWeek = [];
        let i = 0;
        while (startDate < endDate) {
            if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
                if (!isInArray(moment(startDate).format("YYYY-MM-DD")))
                    daysOfWeek.push(startDate.getDay());
            }
            startDate.setDate(startDate.getDate() + 1);
            i++;
        }
        return daysOfWeek.length;
    }

    const requestLeave = async () => {
        if (state.leaveType && state.startDt && state.endDt && state.reason) {
            if (new Date(state.startDt) <= new Date(state.endDt)) {
                var noOfDays = "";
                if (state.startDt == state.endDt) {
                    if (FullOrHalf) {
                        noOfDays = 0.5;
                    } else {
                        noOfDays = 1;
                    }
                } else {
                    noOfDays = findNoOfDays(state.startDt, state.endDt)
                }
                try {
                    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/applyLeave`, {
                        code: user.emp_code,
                        name: user.name,
                        mail: user.mail,
                        type: state.leaveType,
                        noOfDays: noOfDays,
                        startDt: state.startDt,
                        endDt: state.endDt,
                        reason: state.reason,
                        requestType: editLeave.REQUEST_ID ? "edit" : "",
                        requestId: editLeave.REQUEST_ID
                    })
                    setIsDisabled(false)
                    toast.success(editLeave.REQUEST_ID ? "Leave request updated successfully" : "Leave applied successfully", { autoClose: 3000 })
                    setState(initialState)
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                } catch (error) {
                    console.log(error)
                    // let errmsg = (error?.response?.data?.message) ? (error?.response?.data?.message) : (`${error?.response?.status} - ${error?.response?.statusText}`)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                    setIsDisabled(false)
                }
            } else toast.error("Start Date should be lesser than End Date")
        } else toast.error("All fields are required")
    }

    const upcomingLeaves = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/leave/upComingLeaves`)
            let sortedData = data.sort(function (a, b) {
                return new Date(a["START_DATE"]) - new Date(b["START_DATE"]);
            });
            setLeaves(sortedData)
            let leaveTableCols = [{
                headerName: "Employee",
                field: "EMP_NAME",
                sortable: true,
                width: 250,
            }, {
                headerName: "Start Date",
                field: "START_DATE",
                sortable: true,
                width: 100,
                valueGetter: (params) => {
                    return moment(params.row?.START_DATE).format("DD-MMM-YY");
                }
            }, {
                headerName: "End Date",
                field: "END_DATE",
                sortable: true,
                width: 100,
                valueGetter: (params) => {
                    return moment(params.row?.END_DATE).format("DD-MMM-YY");
                }
            },
            {
                headerName: "",
                field: "LEAVE_STATUS",
                sortable: true,
                width: 100,
                valueGetter: (params) => {
                    return params?.row?.LEAVE_STATUS == null ? "(Applied)":null;
                }
            }]
            setColumns(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }

    const initDateRange = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDt) - minsec);
        setMinDate(moment(min_date).format('YYYY-MM-DD'));
    }

    const monthWiseHolidays = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/leave/upcomingHoliday`)
            let sortedData = data.filter((el) => {
                let startDt = el.DATE
                let date = new Date()
                return Date.parse(startDt) >= Date.parse(date)
            }).sort(function (a, b) {
                return new Date(a["DATE"]) - new Date(b["DATE"]);
            });
            sortedData.length > 0 ? setFlag(true) : setFlag(false)

            setHolidays(sortedData)
            let leaveTableCols = [{
                headerName: "Date",
                field: "DATE",
                sortable: true,
                width: 100,
                valueGetter: (params) => {
                    return moment(params.row?.DATE).format("DD-MMM-YY");
                }
            }, {
                headerName: "Day",
                field: "DAY",
                sortable: true,
                width: 70,
                valueGetter: (params) => {
                    return (params.row?.DAY).substring(0, 3);
                }
            }, {
                headerName: "Occasion",
                field: "OCCASION",
                sortable: true,
                width: 220,
            }, {
                headerName: "Location",
                field: "LOCATION",
                sortable: true,
                width: 80
            }]
            setHolidayCols(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }

    const initLeaveData = (leave) => {
        setState({
            leaveType: leave.LEAVE_TYPE,
            startDt: leave.START_DATE,
            endDt: leave.END_DATE,
            reason: leave.LEAVE_REASON
        })
        setEditLeave(leave)
        if (leave.startDt === leave.endDt && leave.startDt !== "") {
            setDaysDisabled(false)
            if (leave.NO_OF_DAYS == 0.5) {
                setFullOrHalf(true)
            } else {
                setFullOrHalf(false)
            }
        }

    }

    const getOfficeHolidays = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/officeholiday`, {
                year: currentYear
            })
            let result = data.filter((el) => {
                if (el.LOCATION === user?.country || (user?.country !== "India" && el.LOCATION != "India"))
                    return el;
            }).map((el) => moment(el.DATE).format("YYYY-MM-DD"));
            setOfficeHolidays(result)
        } catch (error) {
            console.log(error)
        }
    }

    const getLeaveBalance = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/leave/LeaveBalance`)
            setBalance(data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getLeaveBalance();
        upcomingLeaves();
        monthWiseHolidays();
        getOfficeHolidays();
        initDateRange();
      
        var today = new Date()
        var max = "";
        if (today.getMonth() < 3) {
            max = today.getFullYear() + "03-31"
        } else {
            max = (today.getFullYear() + 1) + "-03-31"
        }
        setAprMax(max)
    }, []);

    useEffect(() => {
        if (Object.keys(props.leave).length > 0) {
            initLeaveData(props.leave)
        }
    }, [props.leave])

    useEffect(() => {
        initDateRange();
    }, [state.startDt]);


    


    return (
        <div className={classes.container}>
            <LeaveCard>
                <h4>Apply Leave</h4>
                <div className='row mt-2'>
                    <div className='col-md-12'>
                        <label htmlFor="leaveType">Leave Type<span className={classes.red}>*</span></label><br />
                        <select name="leaveType" value={state.leaveType} className="form-select" style={{ marginBottom: '4%' }} onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                leaveOptions.map((leave) => {
                                    return <option key={leave.value} value={leave.value}>{leave.label}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-5'>
                        <label htmlFor='startDt'>Start Date<span className={classes.red}>*</span></label><br />
                        <input name='startDt' type='date' className="form-control" value={state.startDt} onChange={handleChange} max={aprMax} required />
                    </div>
                    <div className='col-md-5'>
                        <label htmlFor='endDt'>End Date<span className={classes.red}>*</span></label><br />
                        <input name='endDt' type='date' className="form-control" value={state.endDt} onChange={handleChange} min={minDate} max={aprMax} required />
                    </div>
                    <div className='col-md-2'>
                        <label>Half Day</label><br></br>
                        <label className="switch">
                            <input type="checkbox" checked={FullOrHalf} onChange={handleFullOrHalf} disabled={daysDisabled} />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div className='col-md-12'>
                        <label htmlFor="reason">Reason for Leave<span className={classes.red}>*</span></label>
                        <textarea name="reason" className="form-control" value={state.reason} onChange={handleChange} required></textarea>
                    </div>
                    <div className='col-md-5'>
                        <CDBBtn color="dark" flat className="CDBBtn" onClick={requestLeave} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                            Request Leave
                        </CDBBtn>
                    </div>
                </div>
                <div>
                    {
                        balance.length > 0 ?
                            <p style={{ color: "red", marginTop: '20px' }}>No of days left : <span >{parseFloat(balance?.[0]?.CURRENT_YEAR_LEAVE_BALANCE) + parseFloat(balance?.[0]?.TOTAL_LEAVE_BALANCE)} Days</span></p>
                            : null
                    }
                </div>
            </LeaveCard>
            <LeaveCard>
                <h4>Upcoming Vacation</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.EMP_NAME + row.START_DATE + row.END_DATE}
                    rows={leaves}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    hideFooterSelectedRowCount
                />
                {
                    flag && <>
                        <h4>Upcoming Holiday</h4>
                        <DataGrid
                            className={classes.root}
                            getRowId={row => row.HOLIDAY_ID}
                            rows={holidays}
                            columns={holidayCols}
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 5, page: 0 },
                                },
                            }}
                            hideFooterSelectedRowCount
                        />
                    </>
                }
            </LeaveCard>
        </div>
    )
}

export default ApplyLeave