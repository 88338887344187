import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import { useNavigate, useParams } from 'react-router-dom';
import TeamPic from '../images/team4.jpeg'
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'src/stores/userSlice';
import { EditNotificationAction, selectNotification } from 'src/stores/notificationSlice';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    markasread: {
        border: "none",
        borderRadius: "5px"
    }
});

function OurTeamCard() {
    const navigate = useNavigate();
    const [todayLeaveData, setTodayLeaveData] = useState(null);
    const [todayWfhData, setTodayWfhData] = useState(null);
    const [Events, setEvents] = useState([]);
    const [BirthDay, setBirthDay] = useState([]);
    const user = useSelector(selectUser)
    const notification = useSelector(selectNotification)
    const dispatch = useDispatch()
    const classes = useStyles();
    const getTodayLeave = async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getLeaveRecords`, {
            code: '',
            startDt: moment(new Date()).format('YYYY-MM-DD'),
            endDt: moment(new Date()).format('YYYY-MM-DD'),
        })
        var todayLeaveData = data.filter((ele) => ele.LEAVE_STATUS == "Accepted");
        setTodayLeaveData(data);
    }

    const getTodayWFH = async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getWfhRecords`, {
            code: '',
            startDt: moment(new Date()).format('YYYY-MM-DD'),
            endDt: moment(new Date()).format('YYYY-MM-DD'),
        })
        var todayWfhData = data.filter((ele) => ele.STATUS == "Approved" || ele.STATUS == "Accepted");
        setTodayWfhData(data)
    }

    const getEvents = async () => {
        const alertsAndEvents = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getAlertsAndEvents`);
        var Events = alertsAndEvents?.data?.filter((ele) => ele.TYPE == "Event");
        setEvents(Events)
    }

    const getBirthday = async () => {
        const birthday = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/todayBirthday`);
        setBirthDay(birthday.data)
    }

    useEffect(() => {
        getTodayLeave();
        getTodayWFH();
        getEvents();
        getBirthday();
    }, [])

    const handleUpcomingEventscontainers = () => {
        setTimeout(() => {
            document.getElementById("upcomingEventsCartToShow").scrollIntoView({ block: 'center', behavior: 'smooth' });
            document.getElementById('upcomingEventsCartToShow').style.scrollMarginTop = '100px'
            document.getElementById("upcomingEventsCartToShow").style.boxShadow = 'rgb(170, 175, 179) 0px 0px 7px 7px'
            setTimeout(() => {
                document.getElementById("upcomingEventsCartToShow").style.boxShadow = '0 0  5px 2px #aaafb3'
            }, 1000)
        }, 100);
    }
    var param = useParams();
    useEffect(() => {
        if (param.component == "upcomingEvents") {
            handleUpcomingEventscontainers();
            if (Events.length > 0 && user) {
                var unread = Events.filter((ele) => !ele?.IS_READ?.includes(user.emp_code));
                if (unread.length > 0) {
                    markAsRead();
                }
            }
        }
    }, [param, Events, user])

    const markAsRead = async () => {
        var unReadEvents = Events.filter((ele) => !ele?.IS_READ?.includes(user.emp_code))
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/dashboard/readAlertsOrEvents`,
                { Unread: unReadEvents, empcode: user.emp_code });
            var data = { ...notification, ['Events']: "No" };
            dispatch(EditNotificationAction(data))
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
            <div className="container-fluid">
                <div className="row ml-5 mb-3">
                    <div className="col-6">
                        <div className=" m-3 p-4 me-1 dashboard_card" style={{ boxShadow: "0 0 5px 2px #aaafb3", height: "95%" }}>
                            <div>
                                <div className="">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="font-weight-bold text-dark">Folks On Leave Today</h4>
                                    </div>
                                    <div className="m-3 d-flex align-items-center justify-content-between">
                                        <div style={{ height: '200px', overflowY: 'scroll' }} className="w-100">
                                            <ul>
                                                {
                                                    todayLeaveData != null ?
                                                        todayLeaveData?.length > 0 ? todayLeaveData.map((ele) => {
                                                            return (<li key={ele.EMP_CODE}>{ele.EMP_NAME} {ele.LEAVE_STATUS == null ? " - (Applied)":null}</li>)
                                                        }) : "No One Is On Leave Today"
                                                    :null
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="m-3 p-4 ms-1 dashboard_card" style={{ boxShadow: "0 0 5px 2px #aaafb3", height: "95%" }}>
                            <div >
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold text-dark">Folks Working From Home Today</h4>
                                </div>
                                <div className="m-3 d-flex align-items-center justify-content-between">
                                    <div style={{ height: '200px', overflowY: 'scroll' }} className="w-100">
                                        <ul>
                                            {
                                                todayWfhData != null ?
                                                    todayWfhData?.length > 0 ? todayWfhData.map((ele) => {
                                                        return (<li key={ele.EMP_CODE}>{ele.EMP_NAME}  {ele.STATUS == "Pending" ? " - (Applied)":null}</li>)
                                                    }) : "Everyone is Working From Office"
                                                :null
                                            }
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-5">
                    <div className="col-6">
                        <div className=" m-3 p-4 me-1 dashboard_card" style={{ boxShadow: "0 0 5px 2px #aaafb3", height: "95%" }}>
                            <div className="">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="font-weight-bold text-dark">Our Team</h4>
                                    <div class="ml-auto rounded-circle bg-grey py-1 px-2">
                                        <i className="fas fa-users" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                                    <img src={TeamPic} alt="Data Aces Team" height="200px" style={{ objectFit: "cover" }} />
                                </div>
                                <p style={{ textAlign: 'center' }}> Data Aces Inc</p>
                                <p className="c-p text-dark font-weight-bold text-right mt-3 mb-0"
                                    onClick={() => {
                                        navigate('/our-team')
                                    }}
                                >
                                    More Details
                                    <i className="fas fa-arrow-right ml-1"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="m-3 p-4 ms-1 dashboard_card" id="upcomingEventsCartToShow" style={{ boxShadow: "0 0 5px 2px #aaafb3", height: "95%" }}>
                            <div id="messagecontainer">

                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold text-dark">Upcoming Events</h4>
                                    <div className="ml-auto rounded-circle bg-grey py-1 px-2"><i className="fas fa-comment-alt mx-4"></i></div>
                                </div><br />
                                <div style={{ height: '200px', overflowY: 'scroll' }}>
                                    <ul>
                                        {
                                            Events?.length > 0 ?
                                                <>
                                                    {
                                                        Events?.map((ele) =>
                                                            <>
                                                                <li key={ele.REQUEST_ID}><span>{ele.DESCRIPTION}</span></li><br></br>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        BirthDay?.map((ele) =>
                                                            <>
                                                                <li key={ele.EMP_CODE}><span><strong>{ele.EMP_NAME}</strong> is celebrating <strong>BirthDay Today</strong></span></li><br />
                                                            </>
                                                        )
                                                    }
                                                </>
                                                : "No Upcoming Events"
                                        }
                                    </ul>
                                </div>
                                <div>
                                    {
                                        notification.Events == "Yes" ?
                                            <div class="d-flex justify-content-end">
                                                <span>
                                                    <button onClick={() => { markAsRead() }} class={classes.markasread}>Mark As Read</button>
                                                </span>
                                            </div>

                                            : null
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>



            {/* <footer className="footer">
			<div className="d-flex align-items-center" style={{ marginLeft: "15%" }}>
				<span className="footer-rem" style={{ fontSize: "3em", margin: "-2rem 0px -1.5rem 1rem", color: "#C4C4C4" }}>&#8226;</span>
				<small className="ml-2 mt-1">&copy; Data-Aces 2022. All rights reserved.</small>
			</div>

		</footer> */}
            <Footer></Footer>
        </div>
    )
}

export default OurTeamCard