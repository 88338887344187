import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { CDBBtn } from 'cdbreact';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Card from 'src/components/Card';
import { Modal, ModalBody, Tooltip } from 'reactstrap'
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    }, modal: {
        marginTop: "200px"
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    }
});
function QueriesHR(props) {
    const classes = useStyles();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false)
    const [openResolve, setOpenResolve] = useState(false)
    const [description, setDescription] = useState([])
    const [chatToken, setChatToken] = useState("");
    const initialState = {
        message: ""
    }
    const [state, setState] = useState(initialState);
    function handleChangeMessage(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            message: value
        });
    }
    const viewDesc = (desc) => {
        setOpen(true)
        setDescription(desc)
    }

    const viewResolve = (message) => {
        setOpenResolve(true)
        setChatToken(message.REQUEST_ID);
        setState(initialState);
    }

    const resolve = async () => {
        if (state.message.length > 2) {
            const data = {
                'raised_by': "HR",
                'raised_date': moment(new Date).format("YYYY-MM-DD"),
                'message': state.message,
                'raised_id': chatToken,
                'TYPE': "Resolved",
                'status': "Resolved",
            }
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/user/addQueries`, data)
                toast.success('Resolved added successfully')
                setState(initialState)
                getQueries();
                setOpenResolve(false);
                props.checkQueries();
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong, please try again later")
            }
        } else {
            toast.error("All fields are required")
        }
    }
    const handleDelete = async (row) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/deleteQuery`, { data: { requestId: row.REQUEST_ID } })
            toast.success("Query Deleted Successfully");
            getQueries();
        } catch (error) {
            toast.error("Something went wrong. Please try again later")
        }
    }

    const getQueries = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getQueries`)
            var panding = await data?.filter((ele) => ele.STATUS == "Pending")
            setRows(panding)
            let leaveTableCols = [{
                headerName: "Raised Date",
                field: "RAISED_DATE",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span>{moment(params?.row?.RAISED_DATE).format("DD-MMM-YYYY")}</span>
                )
            }, {
                headerName: "Raised By",
                field: "RAISED_BY",
                sortable: true,
                width: 200,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span>{params?.row?.RAISED_BY}</span>
                )
            }, {
                headerName: "Description",
                field: "DESCRIPTION",
                sortable: true,
                width: 350,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span className='text-center'> <CDBBtn onClick={() => viewDesc(params?.row?.messages)} color="dark" className="btn btn-dark text-center ml-3" flat >
                        View
                    </CDBBtn></span>
                )
            },
            {
                headerName: "Status",
                field: "STATUS",
                sortable: true,
                width: 100,
                renderCell: (params) => (
                    <span>{params?.row?.STATUS}</span>
                )
            }, {
                headerName: "Resolve",
                field: "resolve",
                sortable: true,
                width: 100,
                renderCell: (params) => {
                    return <CDBBtn color="dark" onClick={() => viewResolve(params?.row)} className="btn btn-dark text-center ml-3" flat >
                        RESOLVE
                    </CDBBtn>
                }
            }, {
                headerName: "Action",
                field: "Action",
                sortable: true,
                width: 100,
                renderCell: (params) => {
                    return <>
                        <i className="fa fa-trash " aria-hidden="true" onClick={() => handleDelete(params?.row)} style={{ color: "rgb(79 159 207)", cursor: "pointer" }}  ></i>
                    </>
                }
            }]
            setColumns(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getQueries();
    }, [])



    return (
        <div className={classes.container}>
            <Card>
                <h4>Employee Queries</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={rows => rows.REQUEST_ID}
                    rows={rows ?? []}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    // pageSizeOptions={[5, 10]}
                    hideFooterSelectedRowCount
                />

            </Card>
            <Modal isOpen={open} className={classes.modal} toggle={() => setOpen(!open)}>
                <ModalBody className={classes.modalBody}>
                    <div>
                        {
                            description?.map((ele) => {
                                if (ele.TYPE == "EMP_QUERY") {
                                    return (
                                        <>
                                            <div> <strong>EMPLOYEE QUERY</strong>   <small>{ele.RAISED_DATE}</small></div>

                                            <p>{ele.MESSAGE}</p>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div> <strong>RESOLVED MESSAGE</strong>   <small>{ele.RAISED_DATE}</small></div>
                                            <p>{ele.MESSAGE}</p>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={openResolve} className={classes.modal} toggle={() => setOpenResolve(!openResolve)}>
                <ModalBody className={classes.modalBody}>
                    <h4>Submit Your Resolved Message </h4><br />
                    <div className='row mt-2'>

                        <div className="col-md-9">
                            <label htmlFor="password">Message<span className={classes.red}>*</span></label><br />
                            <textarea rows={4} className="form-control" value={state.message} onChange={handleChangeMessage} placeholder="Enter message" required></textarea>
                        </div>
                        <div className="col-md-3 mt-5 pt-4">
                            <div style={{ float: "right" }}>
                                {/* onClick={() => handleSubmit()} */}
                                <CDBBtn color="dark" flat className="CDBBtn" onClick={() => resolve()}>
                                    Submit
                                </CDBBtn>
                            </div>
                        </div>
                        <span style={{ color: 'red', marginTop: "20px" }}>Note*-Message should not exceed 30 words</span>
                    </div>
                </ModalBody>
            </Modal>
        </div>

    )
}

export default QueriesHR