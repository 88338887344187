import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useEffect } from 'react';
import Logo from './../images/Logo.webp';
import * as Yup from 'yup';
import '../css/login.css'
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function Login (){
    const navigate = useNavigate();
    const Kaitongo_Mail = process.env.REACT_APP_KAITONGO_MANAGER_EMAILS.split(',');
    const initialValues = {
        username:'',
        password: ''
    };
    const validationSchema =Yup.object().shape({       
        username: Yup.string()
            .email("*Field should contain a valid data-aces e-mail")
            .required('*User name is required'),
        password: Yup.string()
            .required('*Password is required'),
    });

    // axios.defaults.withCredentials=true;
    const validatUser = async (values, { setSubmitting }) => { 
        setSubmitting(true);
        if ((values.username.indexOf("@data-aces") !== -1 || values.username.indexOf("@kaitongo")!== -1)  && values.password) {
            try{
                const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth`, {
                    username: values.username,
                    password: values.password,
                })
                setSubmitting(false);
                setTimeout(()=>{
                    if(Kaitongo_Mail.includes(values.username)){
                        window.location.href = "/admin";  
                    }else{
                        if(data?.role == "Admin"){
                            window.location.href = "/dashboard";
                        }else if(data?.role == "Manager"){
                            window.location.href ="/admin";
                        }else if(data?.role == "HR"){
                            window.location.href ="/hr-panel";
                        }else{
                            window.location.href = "/daily-status";  
                        }
                    }                   
                    localStorage.setItem('authToken', data?.token);
                },1000)                
            }catch(error){
                setSubmitting(false);
                console.log(error)
                alert("Error! Login failed wrong user credentials");
            }
        } else
            alert("Error! Please enter the valid inputs");            
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={validatUser}
            >
            {({ isSubmitting }) => {
                return(
                    <div id="loginform">
                        <div className="login-container login-width">
                            <div className="image-wrapper">
                                <img src={Logo} height="60" alt="dataAces Logo"/>
                                <p className="dsr-login">Sign in</p>
                                <p className="dsr-subheader">Enter your login credentials below</p>
                                <hr align="center" width="120" style={{margin:"7% 30%", border:'1px solid #e5e5e7', }}/>
                            </div>
                            <Form style={{width:'100%'}}>
                                <div className="text-wrapper">
                                    <label style={{fontSize:14}}>
                                    Username <Field autoFocus type="text" name="username" className={"form-control input-box text-focus"} />
                                    <ErrorMessage name="username" component="div" className="text-danger"/>
                                    </label>
                                </div>
                                <div className="text-wrapper">
                                    <label style={{fontSize:14}}>
                                    Password
                                    <Field type="password" name="password" className={"form-control input-box"}/>
                                    <ErrorMessage name="password" component="div" className="text-danger"/>
                                    </label>
                                </div>
                                <div className="button-wrapper">
                                    <button type="button" id="forgot-password" onClick={() => navigate('/forgot-password')}>
                                        Forgot password?
                                    </button>
                                    <button type="submit" id="sign-in" disabled={isSubmitting} style={{opacity:isSubmitting ? '50%' : '100%'}}>
                                    Submit
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    );
                }}
    </Formik>
  )
};
export default Login;