import React from "react";
import { CDBBtn } from "cdbreact";
import "../css/Hero404.css";

export const Hero404 = () => {
	return(
		<div className="hero404">
			<div className="page-container">				
        <section className="page-body">
					<div className="message404">
						<h4 className="h1 font-weight-bold">Oops</h4>
						<h4 className="h3 my-4">Something went wrong</h4>
						<p>Sorry about that, hmmm... probably a missing page or the link's incorrect.</p>
						<CDBBtn flat color="dark" className="py-2 btn-block">Back Home</CDBBtn>
					</div>
					<img className="image404" alt="404" src="/img/pages/hero404.png"/>
				</section>
			</div>
		</div>
	);
}
