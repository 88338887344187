import React, { useState } from 'react'
import Card from 'src/components/Card'
import { makeStyles } from '@mui/styles';
import { CDBBtn } from 'cdbreact';
import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    }, modal: {
        marginTop: "200px"
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    }
});
function Policies() {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [pdfURL, setPdfURL] = useState("");
    const showFile = (fileName) => {
        if (fileName) {
            setOpen(true);
            setPdfURL(`${process.env.REACT_APP_PUBLIC_URL}/public/policies/${fileName}`)
        } else toast.error("Something went wrong, please try again later")
    }

    return (
        <div className={classes.container}>
            <Card>
                <h4>Policies</h4>
                <div className='mt-5'>
                    <div className='row mb-4'>
                        <div className='col-4'>1,Leave Policy</div>
                        <div className='col-4'>
                            <CDBBtn color="dark" flat className="CDBBtn mt-0 " onClick={() => showFile('leave_policy.pdf')}>View</CDBBtn>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>2,WFH Policy</div>
                        <div className='col-4'>
                            <CDBBtn color="dark" flat className="CDBBtn mt-0 " onClick={() => showFile('wfh_policy.pdf')}>View</CDBBtn>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>3,Maternity Leave Policy</div>
                        <div className='col-4'>
                            <CDBBtn color="dark" flat className="CDBBtn mt-0 " onClick={() => showFile('maternity_leave_policy.pdf')}>View</CDBBtn>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-4'>4,Paternity Leave Policy</div>
                        <div className='col-4'>
                            <CDBBtn color="dark" flat className="CDBBtn mt-0 " onClick={() => showFile('paternity_leave_policy.pdf')}>View</CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Modal size="lg" isOpen={open} toggle={() => setOpen(!open)}>
                <ModalBody style={{
                    overflow: "auto",
                }}>
                    <div>
                        <iframe
                            title="PDF Viewer"
                            src={pdfURL}
                            style={{ width: '100%', height: '500px' }}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <CDBBtn color="dark" flat className="CDBBtn  mx-auto w-25" onClick={() => setOpen(!open)}>
                            Close
                        </CDBBtn>
                    </div>
                </ModalBody>
            </Modal>
        </div>

    )
}

export default Policies