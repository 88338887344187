import { Box, Toolbar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { CDBBtn } from 'cdbreact';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';
import Card from 'src/components/Card';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import img from '../../src/images/DAFullLogo.png';
import footerimg from '../../src/images/NameLogo.png'
import InternOfferLetter from './InternOfferLetter';
import OfferLetter from './OfferLetter';
const useStyles = makeStyles({
    root: {
        "& .modal-content": {
            backgroundColor: "#ffffff05",
            border: "none"
        },
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        "& .modal-dialog": {
            backgroundColor: "red"
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
    }, modal: {
        marginTop: "45vh"
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    }
    ,
    modalContent: {
        position: 'absolute',
        width: 400,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    green: {
        color: "green",
        fontWeight: "400",
        fontSize: "0.875rem"
    }
});
const initialState = {
    Name: "",
    Mail: "",
    number: "",
    reportingManager: "",
    dateOfJoin: "",
    exitDate: "",
    intern_code: "",
    type: "",
    location: ""
}
function InternHr() {
    const [state, setState] = useState(initialState)
    const classes = useStyles();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false);
    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const printHandler = (content, pdfName) => {
        const printElement = ReactDOMServer.renderToString(content);
        // const printElement = pdfJSX();
        const opt = {
            margin: [30, 10, 30, 10],
            filename: `${pdfName}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            html2canvas: { dpi: 75, scale: 2, letterRendering: true },
            // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            pagebreak: { before: '.pagebreakneed', avoid: ['img', 'table', 'p', 'tr', 'th'] }
        };
        const pageBreakElements = document.getElementsByClassName('page-break');
        for (let i = 0; i < pageBreakElements.length; i++) {
            pageBreakElements[i].style.pageBreakBefore = 'always';
        }
        html2pdf().from(printElement).set(opt).toPdf().get('pdf').then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                // set footer to every page
                pdf.setPage(i);
                // set footer font
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
                pdf.text(78,
                    pdf.internal.pageSize.getHeight() - 20, 'D-ACES INDIA PRIVATE LIMITED');
                pdf.text(23,
                    pdf.internal.pageSize.getHeight() - 15, 'Gateway Office Parks - Block B6 - First Floor | 16 GST Road | Perungalathur Chennai 600063 | INDIA');
                // you can add the line separator as an image, consult the docs below to properly set the place of the image
                pdf.addImage(img, 'png', 80, 5, 45, 13)
                pdf.addImage(footerimg, 'png', 90, pdf.internal.pageSize.getHeight() - 30, 32, 5)
            }
        }).save();
    }

    const handleIntern = async () => {
        if (state.Name && state.Mail && state.number && state.intern_code && state.reportingManager && state.dateOfJoin && state.location) {
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/newIntern`, {
                    name: state.Name,
                    mail: state.Mail,
                    number: state.number,
                    manager: state.reportingManager,
                    joiningDate: state.dateOfJoin,
                    exit: state.exitDate,
                    intern_code: state.intern_code,
                    type: state.type,
                    location: state.location,
                    submission: moment(new Date()).format("YYYY-MM-DD")
                })
                toast.success("New Candidate added successfully")
                setState(initialState)
                getQueries();
            }
            catch (error) {
                console.log(error)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        } else toast.error("All fields are mandatory to create new Candidate")
    }


    const getQueries = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getIntern`)
            var currentCandidate = data.filter((ele)=>ele.COMPLETION_STATUS!="Yes")
            setRows(currentCandidate)
            let leaveTableCols = [{
                headerName: "Code",
                field: "CODE",
                sortable: true,
                width: 70,
                renderCell: (params) => (
                    <span>{params?.row?.INTERN_CODE}</span>
                )
            }, {
                headerName: "Location",
                field: "LOCATION",
                sortable: true,
                width: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span>{params?.row?.LOCATION}</span>
                )
            }, {
                headerName: "Type",
                field: "TYPE",
                sortable: true,
                width: 200,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span>{params?.row?.TYPE}</span>
                )
            }, {
                headerName: "Name",
                field: "NAME",
                sortable: true,
                width: 200,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span>{params?.row?.NAME}</span>
                )
            }, {
                headerName: "Email",
                field: "EMAIL",
                sortable: true,
                width: 350,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span>{params?.row?.MAIL}</span>
                )
            },
            {
                headerName: "Link",
                field: "LINK",
                sortable: true,
                width: 150,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    params?.row?.LINK != null && (params?.row?.verified == "No" || !params?.row?.verified) ?
                        params?.row?.LOCATION == "INDIA" ?
                            <span onClick={() => navigator.clipboard.writeText(`${window.location.origin}/onboarding/${params?.row?.LINK_ID}`)} >
                                <i class="fa-regular fa side-icon fa-copy"></i></span>
                            : <span onClick={() => navigator.clipboard.writeText(`${window.location.origin}/us-onboarding/${params?.row?.LINK_ID}`)} >
                                <i class="fa-regular fa side-icon fa-copy"></i></span>
                        : null
                )
            }, {
                headerName: "Action",
                field: "Action",
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    return params?.row?.verified == "No" || !params?.row?.verified ?
                        <CDBBtn color="dark" className="btn btn-dark text-center ml-3" flat onClick={() => { createLink(params?.row) }} >
                            Create Link And Send
                        </CDBBtn>
                        : <span>-</span>
                }
            }, {
                headerName: "Verification",
                field: "Verification",
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    return params?.row?.IsSubmitted == "Yes" && params?.row?.verified == "No" ?
                        params?.row?.LOCATION == "US" ?
                            <Link to={`/us-candidate-info/${params?.row?.LINK_ID}`} >
                                <CDBBtn color="dark" className="btn btn-dark text-center ml-3" flat>
                                    Verify
                                </CDBBtn>
                            </Link>
                            :
                            <Link to={`/candidate-info/${params?.row?.LINK_ID}`} >
                                <CDBBtn color="dark" className="btn btn-dark text-center ml-3" flat>
                                    Verify
                                </CDBBtn>
                            </Link>
                        : params?.row?.verified == "Yes" ?
                            <span className={classes.green}>verified</span>
                            : null
                }
            }, {
                headerName: "Letter",
                field: "LETTER",
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    return params?.row?.TYPE == "Intern" && params?.row?.verified == "Yes" ?
                        <CDBBtn color="dark" className="btn btn-dark text-center ml-3" onClick={() => InternOffer(params?.row?.LINK_ID)} flat>
                            Internship Offer
                        </CDBBtn>
                        : params?.row?.TYPE == "Employee" && params?.row?.verified == "Yes" ?
                            <CDBBtn color="dark" className="btn btn-dark text-center ml-3" onClick={() => EmpOfferLetter(params?.row?.LINK_ID)} flat>
                                Offer Letter
                            </CDBBtn>
                            : "-"
                }
            }]
            setColumns(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }

    const createLink = async (row) => {
        // if (row?.LINK == null) {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/createOnBoardingLink`, {
                name: row?.NAME,
                mail: row?.MAIL,
                intern_id: row?.ID,
                location: row?.LOCATION
            })
            toast.success("Link Created successfully")
            setLoading(false);
            getQueries();
        }
        catch (error) {
            console.log(error)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
            setLoading(false);
        }
        // } else toast.error("Link Already Created For This Intern")
    }

    const InternOffer = async (link_id) => {
        try {
            var data = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getCandidate`, { params: { token: link_id } })
           var data = {
                Name: data.data[0].Name,
                valid: data.data[0].validDate,
                collegename: "",
                startdate: "",
                enddate: "",
            }
            printHandler(<InternOfferLetter data={data} />, "Intern-Offer-Letter")
        } catch (error) {
            toast.error("Something went wrong, please try again later")
        }
    }


    const EmpOfferLetter = async (link_id) => {
        try {
            var data = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getCandidate`, { params: { token: link_id } })
            var data = {
                Name: data.data[0].Name,
                valid: data.data[0].validDate,
                designation: data.data[0].Designation,
                ctc: data.data[0].CTC,
                address1: data.data[0].Location,
                pincode: data.data[0].pincode,
                mail: data.data[0].PersonalEmail,
                number: data.data[0].MobileNumber,
                basic: "",
                hra: "",
                conveyance: "",
                doj: data.data[0].DATE_OF_JION,
                medical: "",
                sp: "",
                pf: "",
                graduity: "",
                bonus: "",
                total: ""
            }
            printHandler(<OfferLetter data={data} />, "Offer-Letter")
        } catch (error) {
            toast.error("Something went wrong, please try again later")
        }
    }
    useEffect(() => {
        getQueries();
    }, [])

    return (
        <div className={classes.container}>
            <Card>
                <h4>Add New Candidate</h4>
                <form className='form'>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="Name">Name<span className={classes.red}>*</span></label><br />
                            <input className="form-control mb-0" type='text' name="Name" value={state.Name} placeholder='Enter Name' onChange={handleChange} required />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="empMail"> Personal Email<span className={classes.red}>*</span></label>
                            {
                                // emailError !== "Valid" && <span className={classes.errormsg}>{emailError}</span>
                            } <br />
                            <input className="form-control mb-0" type='email' name="Mail" value={state.Mail} placeholder='Enter Email' onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="intern_code">Code<span className={classes.red}>*</span></label>
                            {
                                // emailError !== "Valid" && <span className={classes.errormsg}>{emailError}</span>
                            } <br />
                            <input className="form-control mb-0" type='text' name="intern_code" value={state.intern_code} placeholder='Enter Candidate Code' onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="Type">Candidate Type<span className={classes.red}>*</span></label><br />
                            <select className="form-select" name="type" value={state.type} onChange={handleChange} required>
                                <option disabled hidden></option>
                                <option value="Employee">Employee</option>
                                <option value="Intern">Intern</option>
                                <option value="GraduateTrainee">Graduate Trainee</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="number"> Contact Number<span className={classes.red}>*</span></label>
                            {
                                // emailError !== "Valid" && <span className={classes.errormsg}>{emailError}</span>
                            } <br />
                            <input className="form-control mb-0" type='number' name="number" value={state.number} placeholder='Enter Contact Number' onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="Reporting Manager"> Reporting Manager<span className={classes.red}>*</span></label><br />
                            <select className="form-select" name="reportingManager" value={state.reportingManager} onChange={handleChange} required>
                                <option disabled hidden></option>
                                <option value="venkata@data-aces.com">Venkata </option>
                                <option value="shanti@data-aces.com">Shanti </option>
                                <option value="naren@data-aces.com">Naren </option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="location">Candidate Location<span className={classes.red}>*</span></label><br />
                            <select className="form-select" name="location" value={state.location} onChange={handleChange} required>
                                <option disabled selected hidden></option>
                                <option value="INDIA">India</option>
                                <option value="US">us</option>
                            </select>
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="dateOfJoin">Stating Date<span className={classes.red}>*</span></label><br />
                            <input className="form-control mb-0" type='date' name="dateOfJoin" value={state.dateOfJoin} onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="dateOfJoin">Ending Date</label><br />
                            <input className="form-control mb-0" type='date' name="exitDate" value={state.exitDate} onChange={handleChange} required /><br />
                        </div>
                        <div className='col-md-8'></div>
                        <div className="col-md-4">
                            <div style={{ float: "right" }}>
                                <CDBBtn color="dark" flat className="CDBBtn" onClick={() => handleIntern()}>
                                    Add New Candidate
                                </CDBBtn>
                            </div>
                        </div>
                    </div>
                </form>

            </Card>

            <Card>
                <h4>Send onboarding Link</h4>

                <DataGrid

                    className={classes.root}
                    getRowId={rows => rows.INTERN_CODE + rows.MAIL}
                    rows={rows ?? []}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    // pageSizeOptions={[5, 10]}
                    hideFooterSelectedRowCount
                />

            </Card>
            <Modal isOpen={loading} className={classes.modal}>
                {/* <ModalBody className={classes.modalBody}> */}
                <div className={classes.modalContent}>
                    <div className='d-flex justify-content-center h-100 align-items-center'>
                        <div className="spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                {/* </ModalBody> */}
            </Modal>
        </div>
    )
}

export default InternHr