import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TokenExpired from './TokenExpired';
import FullLogo from '../images/DAFullLogo.png'
import { makeStyles } from '@mui/styles';
import Card from 'src/components/Card';
import { CDBBtn } from 'cdbreact';
import { Modal, ModalBody } from 'reactstrap';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import OnboardSuccess from './OnboardSuccess';


const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    },
    errormsg: {
        color: "red",
        fontSize: "12px",
        marginLeft: "10px",
    },
    radio: {
        width: "2rem"
    },
    redForP: {
        color: "red",
    },
    focus: {
        boxShadow: "0 0 9px 2px #ec0c0ca8;",
    }
});
function UsOnBoarding() {
    const params = useParams();
    const classes = useStyles();
    const [isVaild, setisVaild] = useState('')
    const nav = useNavigate();
    const [checked, setChecked] = useState(false);
    const [modal, setModal] = useState(false);
    const [internId, setInternId] = useState("");
    const [isSuccess, setIsSuccess] = useState(false)
    const [type, setType] = useState("");
    const [emptyFields, setEmptyFields] = useState([]);
    const [workDate, setWorkDate] = useState([{ min: "" }, { min: "" }, { min: "" }, { min: "" }, { min: "" }]);
    const modalOpen = () => {
        if (checked === false)
            setModal(true)
    }
    const initialState = {
        Salutation: "",
        Full_Name: "",
        Gender: "",
        Nationality: "",
        FathersName: "",
        MaritalStatus: "",
        SpouseName: "",
        BloodGroup: "",
        PhysicallyChallenged: "",
        DateOfBirth: "",
        Bank_Name: "",
        BankAccountNumber: "",
        NameInBank: "",
        FreshEmployementForPf: "",
        address: "",
        PersonalEmail: "",
        MobileNumber: "",
        EmergencyContact: "",
        RelationshipOfEmergency: "",
        ciia: "",
        RollNo: "",
        pincode: "",
        ugDegree1: '',
        ugdepartment1: '',
        ugcollegeName1: '',
        ugugPassed1: '',
        ugDegree2: '',
        ugdepartment2: '',
        ugcollegeName2: '',
        ugugPassed2: '',
        ugDegree3: '',
        ugdepartment3: '',
        ugcollegeName3: '',
        ugugPassed3: '',
        pgDegree1: '',
        pgdepartment1: '',
        pgcollegeName1: '',
        pgPgPassed1: '',
        pgDegree2: '',
        pgdepartment2: '',
        pgcollegeName2: '',
        pgPgPassed2: '',
        pgDegree3: '',
        pgdepartment3: '',
        pgcollegeName3: '',
        pgPgPassed3: '',
        Designation1: '',
        From1: '',
        To1: '',
        Designation2: '',
        From2: '',
        To2: '',
        Designation3: '',
        From3: '',
        To3: '',
        Designation4: '',
        From4: '',
        To4: '',
        Designation5: '',
        From5: '',
        To5: '',
        CANDIDATE_COUNTRY: 'US',
        BankBranch: "",
        IFCCode: "",
        PAN_Number: "",
        AADHAAR_Number: "",
        NamePerAadhaar: "",
        GenderPerAadhaar: "",
        Date_of_Birth_as_per_Aadhaar: "",
        Pan_Card_Upload: "",
        Aadhaar_Card_Upload: "",
        Tenth_Marksheet_Upload: "",
        Twelth_Marksheet_Upload: "",
    }

    const pdfinitialState = {
        ciia: "",
        UG_Provisional_Or_Degree_Certificate: "",
        PG_Provisional_Or_Degree_Certificate: ""
    }
    const [state, setState] = useState(initialState);
    const [PdfError, setPdfError] = useState(pdfinitialState);
    const [ImgError, setImgError] = useState("");
    const [diaplayPassport, setDisplayPassport] = useState(false);
    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };
    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
        if (evt.target.name == "FreshEmployementForPf") {
            if (value == "No") {
                setDisplayWork(true)
                setWork([{ Designation: '', From: '', To: '', Experience_Certificate: '' }])
            } else {
                setDisplayWork(false)
                setWork([]);
            }
        }
        if (evt.target.name == "holdPassport") {
            if (value == "Yes") {
                setDisplayPassport('yes')
            } else {
                setDisplayPassport('No')
            }
        }
    }

    function handleImageChange(evt) {
        var files = evt.target.files || evt.dataTransfer.files;
        if (!files.length) return;
        if (evt.target.files[0].type.startsWith('image/')) {
            setImgError("")
        } else {
            setImgError("Only image files (JPG, PNG) are allowed")
        }
        setState({
            ...state,
            [evt.target.name]: evt.target.files[0]
        });
    }

    function handlePdfChange(evt) {
        var files = evt.target.files || evt.dataTransfer.files;
        if (!files.length) return;
        if (evt.target.files[0].type !== 'application/pdf') {
            setPdfError({
                ...PdfError,
                [evt.target.name]: "Only PDF files are allowed"
            })
        } else {
            setPdfError({
                ...PdfError,
                [evt.target.name]: ""
            })
        }
        setState({
            ...state,
            [evt.target.name]: evt.target.files[0]
        });
    }

    const tokenCheck = async () => {
        try {
            var data = await axios.post(`${process.env.REACT_APP_BASE_URL}/action/onboardingTokenCheck`, { token: params.token })
            if (data.data.message == "invalid token") {
                nav("/login")
            }
            setisVaild(data.data.message)
            setInternId(data?.data[0]?.INTERN_ID)
            setType(data.data[0].TYPE);
        } catch (error) {
            console.log(error);
        }
    }

    const isSubmitted = async () => {
        try {
            var data = await axios.post(`${process.env.REACT_APP_BASE_URL}/action/getOnboard`, { "intern_id": internId })
            var info = data.data.filter((ele) => ele.link_id == params.token)
            if (info.length > 0) setIsSuccess(true)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        tokenCheck();
    }, [])

    useEffect(() => {
        isSubmitted();
    }, [internId])
    const findEmptyFields = (obj) => {
        const emptyFields = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === "") {
                emptyFields.push(key);
            }
        }
        return emptyFields;
    };

    const fieldsToCheck = ['BloodGroup', 'Salutation', 'Full_Name', 'Gender', 'Nationality', 'FathersName', 'MaritalStatus', 'DateOfBirth', 'FreshEmployementForPf', 'address', 'PersonalEmail', 'MobileNumber', 'EmergencyContact', 'Upload_Passport_Size_photo_Copy', 'ciia', 'Bank_Name', 'BankAccountNumber', 'NameInBank', 'pincode', 'RelationshipOfEmergency', 'SSN', 'holdPassport'];

    //for passport 
    if (diaplayPassport == "yes") {
        fieldsToCheck.push('PassportNumber')
    } else {
        var index = fieldsToCheck.indexOf('PassportNumber');
        if (index > -1) {
            fieldsToCheck.splice(index, 1); // Remove array element
        }
    }

    const handleSubmit = async () => {
        state['intern_id'] = internId;
        state['link_id'] = params.token;
        if (type == "Intern") {
            fieldsToCheck.push('RollNo')
        }
        var emptyFields = fieldsToCheck.filter(fieldName => !state[fieldName]);
        const pdfFilter = Object.keys(PdfError).filter(key => PdfError[key] !== "");
        var ugtemp = ug.map(findEmptyFields)
        const mergedug = ugtemp.reduce((result, subArray, index) => {
            subArray.forEach((item) => {
                result.push(`${item}${index + 1}`);
            });
            return result;
        }, []);
        emptyFields = [...emptyFields, ...mergedug]
        var worktemp = work.map(findEmptyFields)
        const mergedwork = worktemp.reduce((result, subArray, index) => {
            subArray.forEach((item) => {
                result.push(`${item}${index + 1}`);
            });
            return result;
        }, []);
        emptyFields = [...emptyFields, ...mergedwork]
        if (emptyFields.length === 0) {
            setEmptyFields([]);
            if (pdfFilter.length !== 0 || ImgError !== "") {
                toast.error("Please Check The File Format")
                return;
            }
            if (checked) {
                var requestData = state;
                //convert up,pg,work into one state 
                var merged = {};
                ug.forEach((item, i) => {
                    for (const key in item) {
                        merged[`ug${key}${i + 1}`] = item[key];
                    }
                });
                requestData = { ...requestData, ...merged }
                merged = {};
                pg.forEach((item, i) => {
                    for (const key in item) {
                        merged[`pg${key}${i + 1}`] = item[key];
                    }
                });
                requestData = { ...requestData, ...merged }
                merged = {};
                work.forEach((item, i) => {
                    for (const key in item) {
                        merged[`${key}${i + 1}`] = item[key];
                    }
                });
                requestData = { ...requestData, ...merged }
                try {
                    const formData = new FormData();
                    formData.append('photo', state?.Upload_Passport_Size_photo_Copy);
                    formData.append('ciia', state?.ciia);
                    for (var i = 1; i <= 3; i++) {
                        formData.append('ug' + i, requestData?.["ugUG_Provisional_Or_Degree_Certificate" + i]);
                        formData.append('pg' + i, requestData?.["pgPG_Provisional_Or_Degree_Certificate" + i]);
                    }
                    for (var i = 1; i <= 5; i++) {
                        formData.append('work' + i, requestData?.["Experience_Certificate" + i]);
                    }
                    formData.append('link_id', params.token);
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/action/submitOnboard`, requestData)
                    try {
                        await axios.post(`${process.env.REACT_APP_BASE_URL}/action/submitOnboardFiles`, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            }
                        })
                    } catch (error) {
                        console.log(error)
                    }
                    toast.success("OnBoarding Submitted successfully", { autoClose: 3000 })
                    setTimeout(() => {
                        isSubmitted();
                    }, 3000);
                } catch (error) {
                    console.log(error)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }
            }
            else toast.error("Please select the checkbox")
        } else {
            (toast.error("Fill mandatory Fields"))
            setEmptyFields(emptyFields);
        }
    }

    // useEffect(() => {
    //   emptyFields.forEach(fieldName => {
    //       fieldName.current.focus();
    //   });
    // }, [emptyFields])

    //ug
    const [ug, setUg] = useState([{ Degree: '', department: '', collegeName: '', UG_Provisional_Or_Degree_Certificate: '', ugPassed: '' }]);

    const addUgForm = () => {
        if (ug.length != 3) {
            setUg([...ug, { Degree: '', department: '', collegeName: '', UG_Provisional_Or_Degree_Certificate: '', ugPassed: '' }]);
        }
    };

    const deleteUgForm = (index) => {
        const updatedUg = ug.filter((_, i) => i !== index);
        var error = PdfError;
        error["UG_Provisional_Or_Degree_Certificate" + (index + 1)] = "";
        for (var i = index + 1; i < 3; i++) {
            if (error["UG_Provisional_Or_Degree_Certificate" + (i + 1)]) {
                error["UG_Provisional_Or_Degree_Certificate" + (i)] = error["UG_Provisional_Or_Degree_Certificate" + (i + 1)]
                error["UG_Provisional_Or_Degree_Certificate" + (i + 1)] = "";
            }
        }
        setPdfError(error)
        if (updatedUg.length == 0) {
            setUg([{ Degree: '', department: '', collegeName: '', UG_Provisional_Or_Degree_Certificate: '', ugPassed: '' }])
        } else {
            setUg(updatedUg);
        }
    };

    const handleUgFormChange = (index, event) => {
        if (event.target.type == "file") {
            var files = event.target.files || event.dataTransfer.files;
            if (!files.length) return;
            if (event.target.files[0].type !== 'application/pdf') {
                setPdfError({
                    ...PdfError,
                    [event.target.name + (index + 1)]: "Only PDF files are allowed"
                })
            } else {
                setPdfError({
                    ...PdfError,
                    [event.target.name + (index + 1)]: ""
                })
                const { name, value } = event.target;
                const updatedUg = [...ug];
                updatedUg[index][name] = event.target.files[0];
                setUg(updatedUg);
            }
        } else {
            const { name, value } = event.target;
            const updatedUg = [...ug];
            updatedUg[index][name] = value;
            setUg(updatedUg);
        }
    };

    //pg
    const [pg, setPg] = useState([{ Degree: '', department: '', collegeName: '', PG_Provisional_Or_Degree_Certificate: '', PgPassed: '' }]);

    const addPgForm = () => {
        if (pg.length != 3) {
            setPg([...pg, { Degree: '', department: '', collegeName: '', PG_Provisional_Or_Degree_Certificate: '', PgPassed: '' }]);
        }
    };

    const deletePgForm = (index) => {
        const updatedPg = pg.filter((_, i) => i !== index);
        setPdfError({
            ...PdfError,
            ["PG_Provisional_Or_Degree_Certificate" + (index + 1)]: ""
        })
        if (updatedPg.length == 0) {
            setPg([{ Degree: '', department: '', collegeName: '', PG_Provisional_Or_Degree_Certificate: '', PgPassed: '' }])
        } else {
            setPg(updatedPg);
        }
    };

    const handlePgFormChange = (index, event) => {
        if (event.target.type == "file") {
            var files = event.target.files || event.dataTransfer.files;
            if (!files.length) return;
            if (event.target.files[0].type !== 'application/pdf') {
                setPdfError({
                    ...PdfError,
                    [event.target.name + (index + 1)]: "Only PDF files are allowed"
                })
            } else {
                setPdfError({
                    ...PdfError,
                    [event.target.name + (index + 1)]: ""
                })
                const { name, value } = event.target;
                const updatedPg = [...pg];
                updatedPg[index][name] = event.target.files[0];
                setPg(updatedPg);
            }
        } else {
            const { name, value } = event.target;
            const updatedPg = [...pg];
            updatedPg[index][name] = value;
            setPg(updatedPg);
        }
    };

    //work 
    const [work, setWork] = useState([]);
    const [diaplayWork, setDisplayWork] = useState(false);
    const addWorkForm = () => {
        if (pg.length != 5) {
            setWork([...work, { Designation: '', From: '', To: '', Experience_Certificate: '' }]);
        }
    };

    const deleteWorkForm = (index) => {
        const updatedWork = work.filter((_, i) => i !== index);
        if (updatedWork.length == 0) {
            setWork([{ Designation: '', From: '', To: '', Experience_Certificate: '' }])
        } else {
            setWork(updatedWork);
        }
    };

    const handleWorkFormChange = (index, event) => {
        if (event.target.type == "file") {
            var files = event.target.files || event.dataTransfer.files;
            if (!files.length) return;
            if (event.target.files[0].type !== 'application/pdf') {
                setPdfError({
                    ...PdfError,
                    [event.target.name + (index + 1)]: "Only PDF files are allowed"
                })
            } else {
                setPdfError({
                    ...PdfError,
                    [event.target.name + (index + 1)]: ""
                })
                const { name, value } = event.target;
                const updatedWork = [...work];
                updatedWork[index][name] = event.target.files[0];
                setWork(updatedWork);
            }
        } else {
            const { name, value } = event.target;
            const updatedWork = [...work];
            updatedWork[index][name] = value;
            if (name == "From") {
                var min = workDate
                min[index].min = value;
                setWorkDate(min)
                updatedWork[index]['To'] = "";
            }
            setWork(updatedWork);

        }
    };

    return (
        <>
            {isVaild != "Token Expired" ? isSuccess ? <OnboardSuccess /> :
                <div>
                    <nav class="navbar navbar-expand-md navbar-dark bg-dark p-3">
                        <a className="text-decoration-none" style={{ color: "inherit" }}>
                            <img src={FullLogo} alt="Data Aces" className='ms-5' height="40px" width="120px" />
                        </a>
                        <div class="collapse navbar-collapse" id="navbarsExample02" className='text-center ms-5 d-none  d-sm-block' style={{ "width": "65%" }}>
                            <div >
                                <h5 className='text-light h4 fw-1 mb-0'>D-Aces Onboarding</h5>
                                <p className='text-light mb-0'>Welcome To Data-Aces  </p>
                            </div>
                        </div>
                    </nav>
                    <div className='px-5 mt-3 '>
                        <Card>

                            <form className='form'>
                                <div className="row mt-2">
                                    <h3 className='fw-bold text-muted'>General Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Salutation<span className={classes.red}>*</span></label><br />
                                        <select className={emptyFields.includes("Salutation") ? `${classes.focus} form-select` : "form-select"} name="Salutation" value={state.Salutation} onChange={handleChange} required>
                                            <option selected hidden>Select Salutation</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Dr">Dr</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Full Name<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("Full_Name") ? `${classes.focus} form-control` : "form-control"} type='text' name="Full_Name" value={state.Full_Name} placeholder='Enter Name' onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Gender<span className={classes.red}>*</span></label><br />
                                        <select className={emptyFields.includes("Gender") ? `${classes.focus} form-select` : "form-select"} name="Gender" value={state.Gender} onChange={handleChange} required>
                                            <option selected hidden>Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Nationality<span className={classes.red}>*</span></label><br />
                                        {/* <input className="form-control " type='text' name="Nationality" value={state.Nationality} placeholder='Enter Your Natianality' onChange={handleChange} required /> */}
                                        <select className={emptyFields.includes("Nationality") ? `${classes.focus} form-select` : "form-select"} name="Nationality" value={state.Nationality} onChange={handleChange} required>
                                            <option selected hidden>Select Your Natianality</option>
                                            <option value="India">India</option>
                                            <option value="US">US</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Father's Name<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("FathersName") ? `${classes.focus} form-control` : "form-control"} type='text' name="FathersName" value={state.FathersName} placeholder="Enter Your Father's Name" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Marital Status<span className={classes.red}>*</span></label><br />
                                        <select className={emptyFields.includes("MaritalStatus") ? `${classes.focus} form-select` : "form-select"} name="MaritalStatus" value={state.MaritalStatus} onChange={handleChange} required>
                                            <option selected hidden>Select Marital Status</option>
                                            <option value="Married">Married</option>
                                            <option value="Single">Single</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Spouse Name</label><br />
                                        <input className="form-control " type='text' name="SpouseName" value={state.SpouseName} placeholder="Enter Your Spouse Name" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Blood Group<span className={classes.red}>*</span></label><br />
                                        {/* <input className="form-control " type='text' name="BloodGroup" value={state.BloodGroup} placeholder="Enter Your Blood Group" onChange={handleChange} required /> */}
                                        <select className={emptyFields.includes("BloodGroup") ? `${classes.focus} form-select` : "form-select"} name="BloodGroup" value={state.BloodGroup} onChange={handleChange} required>
                                            <option selected hidden>Select Your Blood Group</option>
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                            <option value="I Don't Know">I Don't Know</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Physically Challenged</label><br />
                                        <select className={emptyFields.includes("PhysicallyChallenged") ? `${classes.focus} form-select` : "form-select"} name="PhysicallyChallenged" value={state.PhysicallyChallenged} onChange={handleChange} required>
                                            <option selected hidden>Select Physically Challenged</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Date Of Birth<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("DateOfBirth") ? `${classes.focus} form-control` : "form-control"} type='date' name="DateOfBirth" value={state.DateOfBirth} placeholder="DD-MM-YYYY" max={`${(new Date().getFullYear() - 18)}-01-01`} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Upload Passport Size Photo Copy
                                            <span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("Upload_Passport_Size_photo_Copy") ? `${classes.focus} form-control` : "form-control"} onChange={handleImageChange} type='file' name="Upload_Passport_Size_photo_Copy" placeholder="Enter Your Emergency number" required />
                                        {ImgError && <div className={classes.red}>{ImgError}</div>}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Address<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("address") ? `${classes.focus} form-control` : "form-control"} type='text' name="address" value={state.address} placeholder="Enter Your Address" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">ZIP code<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("pincode") ? `${classes.focus} form-control` : "form-control"} type='number' name="pincode" value={state.pincode} placeholder="Enter Your Zip Code" onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Bank Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Bank Name<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("Bank_Name") ? `${classes.focus} form-control` : "form-control"} type='text' name="Bank_Name" value={state.Bank_Name} placeholder="Enter Your Bank Name" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Bank Account Number<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("BankAccountNumber") ? `${classes.focus} form-control` : "form-control"} type='text' name="BankAccountNumber" value={state.BankAccountNumber} placeholder="Enter Your Account Number" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Name in Bank Account<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("NameInBank") ? `${classes.focus} form-control` : "form-control"} type='text' name="NameInBank" value={state.NameInBank} placeholder="Enter Your Name In Bank" onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Professional Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Whether Fresh employement<span className={classes.red}>*</span></label><br />
                                        <select className={emptyFields.includes("FreshEmployementForPf") ? `${classes.focus} form-select` : "form-select"} name="FreshEmployementForPf" value={state.FreshEmployementForPf} onChange={handleChange} required>
                                            <option selected hidden>Select Fresh Employement</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">SSN<span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("SSN") ? `${classes.focus} form-control` : "form-control"} type='text' name="SSN" value={state.SSN} placeholder="Enter Your SSN" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Do you hold Passport<span className={classes.red}>*</span></label><br />
                                        <select className={emptyFields.includes("holdPassport") ? `${classes.focus} form-select` : "form-select"} name="holdPassport" value={state.holdPassport} onChange={handleChange} required>
                                            <option selected hidden disabled></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    {
                                        diaplayPassport == "yes" ?
                                            <div className="col-md-4">
                                                <label htmlFor="">Passport Number<span className={classes.red}>*</span></label><br />
                                                <input className={emptyFields.includes("PassportNumber") ? `${classes.focus} form-control` : "form-control"} type='text' name="PassportNumber" value={state.PassportNumber} placeholder="Enter Your Passport Number" onChange={handleChange} required />
                                            </div>
                                            : null
                                    }
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName"> CIIA
                                            <span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("ciia") ? `${classes.focus} form-control` : "form-control"} type='file' onChange={handlePdfChange} name="ciia" placeholder="Enter Your Emergency number" required />
                                        {PdfError?.ciia && <div className={classes.red}>{PdfError.ciia}</div>}
                                    </div>
                                    {
                                        diaplayWork ?
                                            <>
                                                <h5 className='fw-bold text-muted'>Work Experience</h5>
                                                {work.map((form, index) => (
                                                    <div className='row'>
                                                        <div className="col-md-3">
                                                            <label htmlFor="newEmpName">Designation<span className={classes.red}>*</span></label><br />
                                                            <input className={emptyFields.includes(`Designation${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} type='text' name="Designation" value={work[index].Designation} placeholder="Enter Your Designation" onChange={(event) => handleWorkFormChange(index, event)} required />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="newEmpName">From<span className={classes.red}>*</span></label><br />
                                                            <input className={emptyFields.includes(`From${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} type='date' name="From" value={work[index].From} placeholder="Select From" onChange={(event) => handleWorkFormChange(index, event)} required />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="newEmpName">To<span className={classes.red}>*</span></label><br />
                                                            <input min={workDate[index].min} className={emptyFields.includes(`To${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} type='date' name="To" value={work[index].To} placeholder="Select To" onChange={(event) => handleWorkFormChange(index, event)} required />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="newEmpName"> Experience Certificate
                                                                <span className={classes.red}>*</span></label><br />
                                                            <input className={emptyFields.includes(`Experience_Certificate${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} onChange={(event) => handleWorkFormChange(index, event)} type='file' name="Experience_Certificate" required />
                                                            {PdfError["Experience_Certificate" + (index + 1)] && <div className={classes.red}>{PdfError["Experience_Certificate" + (index + 1)]}</div>}
                                                        </div>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'>
                                                            <div className='d-flex justify-content-end'>
                                                                {
                                                                    index == (work.length - 1) && work.length !== 5 ?
                                                                        <button type="button" className='btn btn-info' onClick={addWorkForm}><i class="fa fa-solid fa-plus"></i></button>
                                                                        : <div></div>
                                                                }

                                                                <button type="button" className='btn btn-danger' onClick={() => deleteWorkForm(index)}>
                                                                    <i class="fa fa-solid fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </> : null

                                    }
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Contact Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Mobile Number
                                            <span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("MobileNumber") ? `${classes.focus} form-control` : "form-control"} type='text' name="MobileNumber" value={state.MobileNumber} placeholder="Enter Your Mobile Number" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Personal Email Address
                                            <span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("PersonalEmail") ? `${classes.focus} form-control` : "form-control"} type='text' name="PersonalEmail" value={state.PersonalEmail} placeholder="Enter Your Personal Email Address" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Emergency contact number
                                            <span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("EmergencyContact") ? `${classes.focus} form-control` : "form-control"} type='text' name="EmergencyContact" value={state.EmergencyContact} placeholder="Enter Your Emergency number" onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Relationship of emergency contact number
                                            <span className={classes.red}>*</span></label><br />
                                        <input className={emptyFields.includes("RelationshipOfEmergency") ? `${classes.focus} form-control` : "form-control"} type='text' name="RelationshipOfEmergency" value={state.RelationshipOfEmergency} placeholder="Enter Your Relationship" onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Educational Details</h3>

                                    {
                                        type == "Intern" ?
                                            <div className="col-md-3">
                                                <label htmlFor="">Enrollment Number In College<span className={classes.red}>*</span></label><br />
                                                <input className={emptyFields.includes("RollNo") ? `${classes.focus} form-control` : "form-control"} type='text' name="RollNo" value={state.RollNo} placeholder="Enter Your Roll No" onChange={handleChange} required />
                                            </div>
                                            : null
                                    }


                                    <h5 className='fw-bold text-muted mt-2'>Under graduate </h5>
                                    {ug.map((form, index) => (
                                        <div className='row'>
                                            <div className="col-md-2">
                                                <label htmlFor="newEmpName">Degree<span className={classes.red}>*</span></label><br />
                                                {/* <input className="form-control " type='text' name="Degree" value={ug[index].Degree} placeholder="Enter Your Degree" onChange={(event) => handleUgFormChange(index, event)} required /> */}
                                                <select className={emptyFields.includes(`Degree${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} name="Degree" value={ug[index].Degree} placeholder="Enter Your Degree" onChange={(event) => handleUgFormChange(index, event)} required>
                                                    <option value="" selected disabled>Select an option</option>
                                                    <option value="BSc">B.Sc</option>
                                                    <option value="BBA">B.B.A</option>
                                                    <option value="BE">B.E</option>
                                                    <option value="BTech">B.Tech</option>
                                                    <option value="BCA">B.CA</option>
                                                    <option value="BA">BA</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">Department<span className={classes.red}>*</span></label><br />
                                                <input className={emptyFields.includes(`department${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} type='text' name="department" value={ug[index].department} placeholder="Enter Your Department" onChange={(event) => handleUgFormChange(index, event)} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">College Name<span className={classes.red}>*</span></label><br />
                                                <input className={emptyFields.includes(`collegeName${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} type='text' name="collegeName" value={ug[index].collegeName} placeholder="Enter Your College Name" onChange={(event) => handleUgFormChange(index, event)} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">UG Degree / Equivalent Certificate
                                                    <span className={classes.red}>*</span></label><br />
                                                <input className={emptyFields.includes(`UG_Provisional_Or_Degree_Certificate${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} onChange={(event) => handleUgFormChange(index, event)} type='file'
                                                    value={ug[index]?.UG_Provisional_Or_Degree_Certificate ? ug[index]?.UG_Provisional_Or_Degree_Certificate?.File?.name : ''}
                                                    name="UG_Provisional_Or_Degree_Certificate" placeholder="Enter Your Emergency number" required />
                                                {PdfError["UG_Provisional_Or_Degree_Certificate" + (index + 1)] && <div className={classes.red}>{PdfError["UG_Provisional_Or_Degree_Certificate" + (index + 1)]}</div>}
                                            </div>
                                            <div className="col-md-1">
                                                <label htmlFor="newEmpName">Passed Year<span className={classes.red}>*</span></label><br />
                                                {/* <input className="form-control " type='number' name="ugPassed" value={ug[index].ugPassed} placeholder="Enter Your College Name" onChange={(event) => handleUgFormChange(index, event)} required /> */}
                                                <select className={emptyFields.includes(`ugPassed${(index + 1)}`) ? `${classes.focus} form-control` : "form-control"} name="ugPassed" value={ug[index].ugPassed} placeholder="Enter Your College Name" onChange={(event) => handleUgFormChange(index, event)} required>
                                                    <option value="" selected disabled></option>
                                                    {
                                                        [...Array(25 + 1)].map((_, index) => (
                                                            <option key={index} value={new Date().getFullYear() - index}>
                                                                {new Date().getFullYear() - index}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <div className='d-flex justify-content-end'>
                                                    {
                                                        index == (ug.length - 1) && ug.length !== 3 ?
                                                            <button type="button" className='btn btn-info' onClick={addUgForm}><i class="fa fa-solid fa-plus"></i></button>
                                                            : <div></div>
                                                    }

                                                    <button type="button" className='btn btn-danger' onClick={() => deleteUgForm(index)}>
                                                        <i class="fa fa-solid fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <h5 className='fw-bold text-muted'>Master's / Postgraduate</h5>
                                    {pg.map((form, index) => (
                                        <div className='row'>
                                            <div className="col-md-2">
                                                <label htmlFor="newEmpName">Degree</label><br />
                                                {/* <input className="form-control " type='text' name="Degree" value={pg[index].Degree} placeholder="Enter Your Degree" onChange={(event) => handlePgFormChange(index, event)} required /> */}
                                                <select className="form-control " name="Degree" value={pg[index].Degree} placeholder="Enter Your Degree" onChange={(event) => handlePgFormChange(index, event)} required>
                                                    <option value="" selected disabled>Select an option</option>
                                                    <option value="MSc">MSc</option>
                                                    <option value="MBA">MBA</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MTech">MTech</option>
                                                    <option value="MCA">MCA</option>
                                                    <option value="MA">MA</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">Department</label><br />
                                                <input className="form-control " type='text' name="department" value={pg[index].department} placeholder="Enter Your Department" onChange={(event) => handlePgFormChange(index, event)} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">College Name</label><br />
                                                <input className="form-control " type='text' name="collegeName" value={pg[index].collegeName} placeholder="Enter Your College Name" onChange={(event) => handlePgFormChange(index, event)} required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">Master's Degree / Equivalent Certificate
                                                </label><br />
                                                <input className="form-control " onChange={(event) => handlePgFormChange(index, event)} type='file' name="PG_Provisional_Or_Degree_Certificate" placeholder="Enter Your Emergency number" required />
                                                {PdfError["PG_Provisional_Or_Degree_Certificate" + (index + 1)] && <div className={classes.red}>{PdfError["PG_Provisional_Or_Degree_Certificate" + (index + 1)]}</div>}
                                            </div>
                                            <div className="col-md-1">
                                                <label htmlFor="newEmpName">Passed Year</label><br />
                                                {/* <input className="form-control " type='number' name="PgPassed" value={pg[index].PgPassed} placeholder="Enter Your College Name" onChange={(event) => handlePgFormChange(index, event)} required /> */}
                                                <select className="form-control " name="PgPassed" value={pg[index].PgPassed} placeholder="Enter Your College Name" onChange={(event) => handlePgFormChange(index, event)} required>
                                                    <option value="" disabled></option>
                                                    {
                                                        [...Array(25 + 1)].map((_, index) => (
                                                            <option key={index} value={new Date().getFullYear() - index}>
                                                                {new Date().getFullYear() - index}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <div className='d-flex justify-content-end'>
                                                    {
                                                        index == (pg.length - 1) && pg.length != 3 ?
                                                            <button type="button" className='btn btn-info' onClick={addPgForm}><i class="fa fa-solid fa-plus"></i></button>
                                                            : <div></div>
                                                    }

                                                    <button type="button" className='btn btn-danger' onClick={() => deletePgForm(index)}>
                                                        <i class="fa fa-solid fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='col-md-4'></div>
                                    <div className="col-md-12">
                                        <Checkbox checked={checked} onChange={handleCheckBoxChange} inputProps={{ 'aria-label': 'controlled' }} onClick={modalOpen} />
                                        <label style={{ fontSize: 15 }}>I ensure that all the details are correct to best of my knowledge</label>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <CDBBtn color="dark" flat className="CDBBtn mx-auto w-25" onClick={() => handleSubmit()} >
                                            Submit
                                        </CDBBtn>
                                    </div>
                                </div>
                            </form>
                        </Card>
                        <Modal isOpen={modal}>
                            <ModalBody>
                                <div>
                                    <p className="align-items-center text-center p-2">
                                        Profile details can be updated only once.<br /> Make sure that you update correct information
                                    </p>
                                    <div className="d-flex justify-content-center" type="submit" id="AddBtn">
                                        <CDBBtn color="dark" onClick={() => setModal(false)}>
                                            OK
                                        </CDBBtn>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div >
                : <TokenExpired />
            }
        </>
    )
}

export default UsOnBoarding