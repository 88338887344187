import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
export const projectSlice = createSlice({
    name:"projects",
    initialState:{
        projects:[],
    },
    reducers:{
        setProjects: (state,action) =>{
            state.projects = action.payload;
        },      
    }
});

export const fetchProjects = () => async dispatch => {   
    try{
        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/timesheet/getProjects`)
        data.sort((a,b) => (a.PROJECT_STATUS < b.PROJECT_STATUS) ? 1 : ((b.PROJECT_STATUS < a.PROJECT_STATUS) ? -1 : 0))
        dispatch(projectSlice.actions.setProjects(data))
    }
    catch(error){
        dispatch(projectSlice.actions.setProjects([]))
    }
}

export const {setProjects} = projectSlice.actions;
export const selectProjects = (state) => state.projects.projects;
export default projectSlice.reducer;