import { CDBBtn } from "cdbreact";
import { useEffect, useState } from "react";
import axios from "axios";
import ms from "ms";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import { DefaultEditor } from "react-simple-wysiwyg";
import "../css/Dailystatus.css";
import { useSelector } from "react-redux";
import { selectProjects } from "src/stores/projectSlice";
import { selectUser } from "src/stores/userSlice";
import Tooltip from "@mui/material/Tooltip";
// import { Editor, EditorState } from 'draft-js';
import { toast } from "react-toastify";
import Card from "../components/Card";
import { makeStyles } from "@mui/styles";
import { log } from "util";
import Select from "react-select";

const useStyles = makeStyles({
  container: {
    padding: "2%",
  },
});

export default function Compose(props) {
  const [selectedOptions2, setSelectedOptions2] = useState();
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [flag, setFlag] = useState(true);
  const projects = useSelector(selectProjects);
  const user = useSelector(selectUser);
  const [modalHeading, setModalHeading] = useState("Add");
  const [index, setIndex] = useState();
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const sortedProjects = projects
    .slice()
    .sort((a, b) =>
      a.PROJECT_NAME > b.PROJECT_NAME
        ? 1
        : b.PROJECT_NAME > a.PROJECT_NAME
        ? -1
        : 0
    )
    .filter((el) => el.PROJECT_STATUS === "Open");
  const [state, setState] = useState({
    employeeCode: user?.emp_code,
    projectCode: [],
    inputDate: moment(new Date()).format("YYYY-MM-DD"),
    dsr: [],
    bcc: user?.mail,
    SubmissionDate: moment(new Date()).format("YYYY-MM-DD"),
    reportID: "",
    taskID: "",
    type: "",
  });

  const initialState = {
    projectCode: [],
    projectName: "",
    taskName: "",
    description: "",
    status: "",
    hours: "",
    taskType: "",
  };
  const [subTasks, setSubTasks] = useState(initialState);
  const [disableAdd, setDisableAdd] = useState(0);
  // const [clientCode,setClientCode] = useState([])

  const getClientCode = (prjCode) => {
    let clientCode = [];
    for (let i = 0; i < prjCode.length; i++) {
      const client = projects.find((item) => item.PROJECT_CODE === prjCode[i]);
      clientCode.push(client?.CLIENT_CODE);
    }

    return clientCode;
  };

  const getProject = (code) => {
    const project = projects.find((item) => item.PROJECT_CODE === code);
    return project?.PROJECT_NAME;
  };
  const sendDsr = async (e) => {
    if (state.dsr.length === 0) {
      toast.error("Please add atleast one task");
      return;
    }

    if (
      state.projectCode.length === 0 ||
      state.bcc === "" ||
      state.inputDate === "" ||
      state.subject === ""
    ) {
      toast.error("Date can't be left empty");
      return;
    }

    var totHrs = 0;
    if (state.type == "edit") {
      totHrs = state.dsr[0].hours + props.otherTaskHours;
    } else {
      totHrs = state.dsr.reduce((e, currentValue) => {
        return e + currentValue.hours;
      }, 0);
    }
    if (totHrs > 12) {
      toast.error("Total worked hours should not exceed 12 hours");
      return;
    }
    let client = getClientCode(state.projectCode);
    let prjName = getProject(state.projectCode);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/dsr/sendDsr`,
        {
          project: state.projectCode,
          projectName: prjName,
          clientCode: client,
          subject:
            "DSR-" +
            user?.name +
            "-" +
            moment(state.inputDate).format("DD-MMM-YYYY"),
          date: state.inputDate,
          dsr: state.dsr,
          empCode: user?.emp_code,
          empMail: user?.mail,
          submissionDate: state.SubmissionDate,
          bcc: state.bcc,
          type: state.type,
          reportID: state.reportID,
          taskID: state.taskID,
        }
      );
      setState({
        employeeCode: user?.emp_code,
        projectCode: [],
        inputDate: "",
        dsr: [],
        bcc: user?.mail,
        SubmissionDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSubTaskArray([]);
      toast.success("DSR sent successfully", { autoClose: 3000 });
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    } catch (error) {
      // console.log(error)
      let errmsg = error?.response
        ? error?.response?.data?.message
          ? error?.response?.data?.message
          : `${error?.response?.status} - ${error?.response?.statusText}`
        : "Something went wrong. Please try again later";
      toast.error(errmsg);
    }
  };

  const [subTaskArray, setSubTaskArray] = useState([]);

  const projectName = (e) => {
    const Arr = e.value.split(",");
    setSelectedOptions2(e);
    setSubTasks({ ...subTasks, projectCode: Arr[0], projectName: Arr[1] });
  };

  const handleTaskTypeChange = (e) => {
    setSubTasks({ ...subTasks, taskType: e.value });
  };

  const status = (e) => {
    setSubTasks({ ...subTasks, status: e.target.value });
  };

  const descriptionCall = (e) => {
    setSubTasks({ ...subTasks, description: e.target.value });
  };

  const taskDetailsCall = (e) => {
    setSubTasks({ ...subTasks, taskName: e.target.value });
  };

  const hoursCall = (e) => {
    let { value, min, max } = e.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setSubTasks({ ...subTasks, hours: value });
  };

  const AddSubTask = (e) => {
    if (
      subTasks?.projectCode?.length > 0 &&
      subTasks?.taskName?.length > 0 &&
      subTasks?.description?.length > 0 &&
      subTasks?.status?.length > 0 &&
      subTasks?.hours > 0 &&
      subTasks?.taskType?.length > 0
    ) {
      if (modalHeading === "Add") {
        subTaskArray.push(subTasks);
        const prjCodeDef = subTaskArray.map((e) => e.projectCode);
        setState({ ...state, dsr: subTaskArray, projectCode: prjCodeDef });
        setSubTasks(initialState); 
        setModal(false);
      } else {
        subTaskArray.splice(index, 1, subTasks);
        const prjCodeDef = subTaskArray.map((e) => e.projectCode);
        setState({ ...state, dsr: subTaskArray, projectCode: prjCodeDef });
        setSubTasks(initialState); 
        setModal(false);
      }
    } else {
      toast.error("Please fill out all the fields");
    }
  };

  const deleteSubTasks = (index) => {
    let arr = state.dsr;
    arr.splice(index, 1);
    setState({ ...state, dsr: arr });
  };
  const inputDsrDate = (e) => {
    if (state.dsr.length === 0) {
      let formattedDate = e.target.value;
      setState({ ...state, inputDate: formattedDate });
    }
  };

  const resetTasks = (e) => {
    setState({ ...state, dsr: [] });
    setSubTaskArray({});
    setFlag(true);
  };

  const editSubTasks = (e, index) => {
    setSelectedOptions2({
      value: `${e.projectCode},${e.projectName}`,
      label: e.projectName,
    });
    setModal(true);
    setSubTasks(e);
    setModalHeading("Edit");
    setIndex(index);
  };

  const PrjList = sortedProjects.map((prjcts) => ({
    value: prjcts.PROJECT_CODE,
    label: prjcts.PROJECT_NAME,
  }));
  const PrjListOption = PrjList.map((ele) => ({
    value: `${ele.value},${ele.label}`,
    label: ele.label,
  }));
  const modalOpen = () => {
    setSelectedOptions2([]);
    setModal(true);
    setSubTasks({});
  };

  const initDateRange = () => {
    const minsec = ms("29d");
    const min_date = new Date(+new Date(state.inputDate) - minsec);
    const max_date = new Date(new Date());
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
    setMaxDate(moment(max_date).format("YYYY-MM-DD"));
  };
  const resetUpdateData = () => {
    setState({
      ...state,
      employeeCode: "",
      projectCode: [],
      inputDate: "",
      dsr: [],
      bcc: "",
      SubmissionDate: "",
      reportID: "",
      taskID: "",
      type: "",
    });

    setSubTasks({
      ...subTasks,
      initialState,
    });
    setSubTaskArray([]);

    return;
  };
  const initTaskData = (task) => {
    setFlag(!flag);
    resetUpdateData();
    setSubTasks({
      ...subTasks,
      projectCode: task.PROJECT_CODE,
      projectName: getProject(task.PROJECT_CODE),
      taskName: task.TASK_NAME,
      description: task.TASK_DESC,
      status: task.COMPLETION_STATUS,
      hours: task.HOURS_SPENT,
      taskType: task.TASK_TYPE,
    });
    //  setState({ ...state, inputDate: task.STATUS_DATE,reportID:task.REPORT_ID,taskID:task.TASK_ID  })
    setState({
      ...state,
      employeeCode: user?.emp_code,
      projectCode: task.PROJECT_CODE,
      inputDate: task.STATUS_DATE,
      dsr: [],
      bcc: user?.mail,
      SubmissionDate: moment(new Date()).format("YYYY-MM-DD"),
      reportID: task.REPORT_ID,
      taskID: task.TASK_ID,
      type: "edit",
      typeType:task.TASK_TYPE
    });
  };

  useEffect(() => {
    if (Object.keys(props.task).length > 0) {
      initTaskData(props.task);
    }
  }, [props.task]);

  useEffect(() => {
    if (
      subTasks?.projectCode?.length > 0 &&
      subTasks?.taskName?.length > 0 &&
      subTasks?.description?.length > 0 &&
      subTasks?.status?.length > 0 &&
      subTasks?.hours > 0
    )
      AddSubTask();
  }, [flag]);

  useEffect(() => {
    initDateRange();
  }, []);

  const taskTypeOptions = [
    { value: "01-Requirement Analysis", label: "Requirement Analysis" },
    { value: "02-Architecture / Design", label: "Architecture / Design" },
    {
      value: "03-Prototype / Wireframe Development",
      label: "Prototype / Wireframe Development",
    },
    {
      value: "04-Coding / Development - New",
      label: "Coding / Development - New",
    },
    {
      value: "05-Coding / Development - Rework",
      label: "Coding / Development - Rework",
    },
    { value: "06-Unit Testing / Validation ", label: "Unit Testing / Validation" },
    {
      value: "07-System Integration Testing",
      label: "System Integration Testing",
    },
    { value: "08-Bug Fixing", label: "Bug Fixing" },
    { value: "09-Deployment", label: "Deployment" },
    {
      value: "10-Learning / Research / Evaluation",
      label: "Learning / Research / Evaluation",
    },
    { value: "11-Documentation", label: "Documentation" },
    { value: "12-Knowledge Transfer", label: "Knowledge Transfer" },
    { value: "13-Production Run.", label: "Production Run" },
  ];
  

  return (
    <div className={classes.container}>
      <div className="row p-2">
        <Card>
          <div className="container p-1">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="col ">
                <strong style={{ fontSize: "18px" }}>My Projects</strong>
              </div>
              <div style={{ marginTop: "7px", marginRight: "15px" }}>
                <label>DSR Date: </label>
              </div>
              <div>
                <input
                  type="date"
                  className="form-control"
                  id="sd"
                  value={state.inputDate}
                  onChange={inputDsrDate}
                  min={minDate}
                  max={maxDate}
                />
              </div>
              <div
                style={{
                  marginLeft: "30%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {flag ? (
                  <CDBBtn
                    color="dark"
                    style={{ height: "80%" }}
                    className="btn btn-dark m-1"
                    flat
                    onClick={() => modalOpen(setModalHeading("Add"))}
                  >
                    <i className="fa-solid fas fa-plus"></i>
                  </CDBBtn>
                ) : (
                  <div></div>
                )}
                <CDBBtn
                  color="dark"
                  style={{ height: "80%" }}
                  className="btn btn-dark m-1"
                  flat
                  onClick={resetTasks}
                >
                  <i className="fa-solid fas fa-trash"></i>
                </CDBBtn>
                <CDBBtn
                  color="dark"
                  className="btn btn-dark m-1"
                  style={{ height: "80%" }}
                  flat
                  onClick={sendDsr}
                >
                  <i className=" fas fa-paper-plane"></i>
                </CDBBtn>
              </div>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Project-Name</th>
                <th scope="col">Task-Name</th>
                <th scope="col">Task-Type</th>
                <th scope="col">Task-Description</th>
                <th scope="col">Task-Status</th>
                <th scope="col">Hours-Spent</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody id="myTable">
              {state.dsr &&
                state.dsr.map((e, index) => {
                  return (
                    <tr className="inner-font" key={index}>
                      <td>{e.projectName}</td>
                      <td>{e.taskName}</td>
                      <td>{e.taskType}</td>
                      <td>
                        <p
                          dangerouslySetInnerHTML={{ __html: e.description }}
                        />
                      </td>
                      <td>{e.status}</td>
                      <td>{e.hours}</td>
                      <td>
                        <span>
                          <Tooltip title="Edit">
                            <i
                              className="fa fa-edit"
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => editSubTasks(e, index)}
                            ></i>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <i
                              className="fa fa-trash"
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => deleteSubTasks(index)}
                            ></i>
                          </Tooltip>
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <span style={{ color: "red", fontSize: "13px" }}>
            Note - Please select date before adding your tasks
          </span>
        </Card>
      </div>
      <Modal
        isOpen={modal}
        className="modal-lg AddTaskModal "
        toggle={() => setModal(!modal)}
        style={{ width: "800px" }}
      >
        <ModalBody>
          <div>
            <h4>{modalHeading} Task</h4>
            <form>
              <div>
                <label>Select Project Name<span className="text-danger ms-1">*</span></label>
                <br />
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "1px solid #ccc",
                      boxShadow: "none",
                      "&:hover": {
                        border: "1px solid #ccc",
                      },
                    }),
                  }}
                  options={PrjListOption}
                  placeholder="Select project"
                  value={selectedOptions2}
                  onChange={projectName}
                  isSearchable={true}
                />
              </div>
              <br />
              <div>
                <div>
                  <label>Select Task Type<span className="text-danger ms-1">*</span></label>
                  <br />
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid #ccc",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #ccc",
                        },
                      }),
                    }}
                    options={taskTypeOptions}
                    placeholder="Select task type"
                    value={taskTypeOptions.find(
                      (option) => option.value === subTasks.taskType
                    )}
                    onChange={handleTaskTypeChange}
                    isSearchable={true}
                  />
                </div>
              </div>
              <br />

              <div>
                <label>Task Details<span className="text-danger ms-1">*</span></label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Task Details"
                  className="projectName"
                  value={subTasks.taskName}
                  onChange={taskDetailsCall}
                  required
                ></input>
              </div>
              <div className="flexDisplay">
                <div>
                  <label>Status<span className="text-danger ms-1">*</span></label>
                  <br />
                  <select
                    className="widthSet"
                    onChange={status}
                    value={subTasks.status}
                    required
                  >
                    <option value="">Please select</option>
                    <option value="In-Progress">In-Progress</option>
                    <option value="Completed">Completed</option>
                  </select>
                </div>
                <div>
                  <label>Hours Spent<span className="text-danger ms-1">*</span></label>
                  <br />
                  <input
                    type="number"
                    placeholder="Hours"
                    className="widthSet"
                    value={subTasks.hours}
                    onChange={hoursCall}
                    required
                    min="1"
                    max="12"
                  ></input>
                </div>
              </div>
              <label>Description<span className="text-danger ms-1">*</span></label>
              <DefaultEditor
                value={subTasks.description}
                onChange={descriptionCall}
                className="TextEditor"
                style={{ height: "120px", overflow: "auto" }}
                required
              />
              <br />

              <div
                className="d-flex justify-content-center"
                type="submit"
                id="AddBtn"
              >
                <CDBBtn color="dark" onClick={AddSubTask}>
                  {modalHeading}
                </CDBBtn>
              </div>
            </form>
            {/* <span modalValidations>All fields are required</span> */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
