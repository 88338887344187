import React, { useState, useEffect } from "react";
import { CDBBtn } from "cdbreact";
import validator from 'validator'
import { useSelector } from "react-redux"
import { selectUser } from 'src/stores/userSlice';
import { Checkbox } from '@mui/material';
import { Modal, ModalBody } from 'reactstrap'
import moment from "moment";
import { toast } from "react-hot-toast";
import axios from "axios";

function UsUpdateUser(props) {
    const initialState = {
        fName: '',
        lName: '',
        fatherName: '',
        phone: '',
        bloodGrp: '',
        dob: '',
        addrLine1: '',
        addrLine2: '',
        city: '',
        empstate: '',
        pincode: '',
        email: '',
        contactNo: '',
        ssn: '',
        passportNo: '',
        expiryDt: '',
        accountNo: '',
        manager: '',
        status: '',
        designation: '',
    };
    const [state, setState] = useState(initialState)
    const [remote, setRemote] = useState("No")
    const [hasPassport, setHasPassport] = useState("No")
    const [flag, setFlag] = useState(true);
    const [checked, setChecked] = useState(false);
    const [editable, setEditable] = useState(false)
    const [modal, setModal] = useState(false)
    const [emailError, setEmailError] = useState('')
    const user = useSelector(selectUser)

    const modalOpen = () => {
        if (checked === false)
            setModal(true)
    }

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
        if (evt.target.name === "email") {
            if (validator.isEmail(value)) {
                setEmailError('Valid')
            } else {
                setEmailError('Enter valid Email!')
            }
        }
        if (evt.target.name === "remote") {
            setRemote(evt.target.value)
        }
        // if(evt.target.name === "payOption"){
        //   setPayOption(evt.target.value)
        // }
        if (evt.target.name === "hasPassport") {
            setHasPassport(evt.target.value)
        }
    }

    useEffect(() => {
        setState(initialState);
        setChecked(false)
        setFlag(false);
        setHasPassport(props.data?.HAS_PASSPORT)
        setRemote(props.data?.REMOTE_EMPLOYEE)
        setState({
            fName: (props.data?.EMP_NAME)?.split(' ')[0],
            lName: (props.data?.EMP_NAME)?.substr(props.data?.EMP_NAME.indexOf(" ") + 1),
            fatherName: props.data?.EMP_FATHERS_NAME,
            phone: props.data?.EMP_MOBILE_NO,
            bloodGrp: props.data?.EMP_BLOOD_GROUP,
            dob: moment(props.data?.EMP_DATE_OF_BIRTH).format("YYYY-MM-DD"),
            doj: moment(props.data?.EMP_DATE_OF_JOIN).format("YYYY-MM-DD"),
            addrLine1: props.data?.EMP_ADDRESS_1,
            addrLine2: props.data?.EMP_ADDRESS_2,
            city: props.data?.EMP_CITY,
            empstate: props.data?.EMP_STATE,
            pincode: props.data?.PINCODE,
            location: props.data?.EMP_LOCATION,
            dataAcesMail: props.data?.EMP_WORK_EMAIL,
            email: props.data?.EMP_PERSONAL_EMAIL,
            contactNo: props.data?.EMERGENCY_CONTACT_NO,
            ssn: props.data?.SSN,
            passportNo: props.data?.PASSPORT_NO,
            expiryDt: moment(props.data?.PASSPORT_EXPIRY_DT).format("YYYY-MM-DD"), 
            accountNo: props.data?.EMP_ACCOUNT_NO,
            status: props.data?.EMP_STATUS,
            designation: props.data?.DESIGNATION,
            manager: props.data?.REPORTING_MANAGER_ID,
        })
        let editFlag =  false
        setEditable(editFlag)
        setChecked(editFlag)
    }, [props.data])

    const updateProfile = async()=>{
        if(state.fName && state.lName && state.fatherName && state.phone && state.bloodGrp && state.dob && state.email && state.contactNo && state.addrLine1 && state.addrLine2 && state.city && state.pincode && state.ssn && state.accountNo && hasPassport && remote){							
            if(checked===true){
                //setIsDisabled(true)					
                try{
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateProfile`,{
                        empcode:props.data.EMP_CODE,
                        empName:state.fName+" "+state.lName,
                        fatherName:state.fatherName,
                        phone:state.phone,
                        bloodGrp:state.bloodGrp,
                        dob:state.dob,
                        email:state.email,
                        contactNo:state.contactNo,
                        addrLine1:state.addrLine1,
                        addrLine2:state.addrLine2,
                        city:state.city,
                        pincode: state.pincode,
                        ssn:state.ssn,
                        hasPassport,
                        passportNo:state.passportNo,
                        // expiryDt:state.expiryDt + "-01",	//For input month type
                        expiryDt:state.expiryDt,
                        remote,
                        accountNo:state.accountNo,
                        lastUpdatedDt:moment(new Date).format("YYYY-MM-DD"),
                        isManager:user?.is_admin,
                    })
                    toast.success("Profile updated successfully")	
                    //setIsDisabled(false)
                    setTimeout(()=>{
                        window.location.reload()
                    },2000)
                }catch(error){				
                    //setIsDisabled(false)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }	
            }else toast.error("Please select the checkbox")	 
        }
        else(toast.error("All fields are mandatory for the profile update"))
    }
    
    return (
        <div>
            <div className="p-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right">Profile Settings</h4>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <label htmlFor="fName">First Name<span className={props.classes.red}>*</span></label>
                        <input type="text" className={`form-control`} placeholder="First name" name="fName" defaultValue={state.fName || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="lName">Last name<span className={props.classes.red}>*</span></label>
                        <input type="text" className={`form-control`} placeholder="Last name" name="lName" defaultValue={state.lName || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="fatherName">Father's name<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Father's name" name="fatherName" defaultValue={state.fatherName || ""} onChange={handleChange} readOnly={editable} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="phone">PhoneNumber<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Phone Number" name="phone" defaultValue={state.phone || ""} onChange={handleChange} readOnly={editable} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="dob">Date of Birth<span className={props.classes.red}>*</span></label>
                        <input type="date" className="form-control" placeholder="DOB" name="dob" defaultValue={state.dob || ""} onChange={handleChange} readOnly={editable} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="bloodGrp">Blood Group<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Blood group" name="bloodGrp" defaultValue={state.bloodGrp || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="email">Personal Email ID<span className={props.classes.red}>*</span></label>{
                            emailError !== "Valid" && <span className={props.classes.errormsg}>{emailError}</span>
                        } <br />
                        <input type="email" className="form-control" placeholder="Enter personal email id" name="email" defaultValue={state.email || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="contactNo">Emergency Contact No.<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" name="contactNo" defaultValue={state.contactNo || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label htmlFor="addrLine1">Address Line 1<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Address line 1" name="addrLine1" defaultValue={state.addrLine1 || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="addrLine2">Address Line 2<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Address line 2" name="addrLine2" defaultValue={state.addrLine2 || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="city">City<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" name="city" defaultValue={state.city || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="empstate">State</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} name="empstate" defaultValue={state.empstate} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="pincode">Pin code<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" name="pincode" defaultValue={state.pincode || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="dataAcesMail">Data Aces Mail</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} name="dataAcesMail" defaultValue={state.dataAcesMail} readOnly />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="ssn">SSN<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="SSN" name="ssn" defaultValue={state.ssn || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-12">
                            <label htmlFor="hasPassport">Do you hold Passport?</label>
                            <span className={props.classes.marginRight}>
                                <input className={props.classes.radio} type="radio" name="hasPassport" value="Yes" checked={hasPassport === "Yes"} onChange={handleChange} /> Yes
                            </span>
                            <span className={props.classes.marginRight}>
                                <input className={props.classes.radio} type="radio" name="hasPassport" value="No" checked={hasPassport === "No"} onChange={handleChange} /> No
                            </span>
                        </div>
                    
                    {
                        hasPassport === "Yes" && <><div className="col-md-6">
                            <label htmlFor="passportNo">Passport Number<span className={props.classes.red}>*</span></label>
                            <input type="text" className="form-control" placeholder="Passport Number" name="passportNo" defaultValue={state.passportNo || ""} onChange={handleChange} readOnly={editable} />
                        </div>
                            <div className="col-md-6">
                                <label htmlFor="expiryDt">Passport valid till<span className={props.classes.red}>*</span></label>
                                <input type="date" className="form-control" placeholder="Expiry Date" name="expiryDt" value={state.expiryDt || ""} onChange={handleChange} readOnly={editable} required />
                            </div></>
                    }
                    {/* Input for Payment option */}
                    {/* <div className="col-md-12">
						<label htmlFor="payOption">Payment Option<span className={props.classes.red}>*</span></label>						
						<span className={props.classes.marginRight}><input className={props.classes.radio} type="radio" name="payOption" value="Pay Card" checked={payOption==="Pay Card"} onChange={handleChange} disabled={editable}/> Pay Card</span>	
						<span className={props.classes.marginRight}><input className={props.classes.radio} type="radio" name="payOption" value="Cheque" checked={payOption==="Cheque"} onChange={handleChange} disabled={editable}/> Cheque</span>					
					</div>	 */}
                    <div className="col-md-12">
                        <label htmlFor="accountNo">Account Number</label>
                        <input type="text" className="form-control" placeholder="Account Number" name="accountNo" defaultValue={state.accountNo || ""} onChange={handleChange} readOnly={editable} required />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="remote">Remote Employee<span className={props.classes.red}>*</span></label>
                        <span className={props.classes.marginRight}><input className={props.classes.radio} type="radio" name="remote" value="Yes" checked={remote === "Yes"} onChange={handleChange} disabled={editable} /> Yes</span>
                        <span className={props.classes.marginRight}><input className={props.classes.radio} type="radio" name="remote" value="No" checked={remote === "No"} onChange={handleChange} disabled={editable} /> No</span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} placeholder="status" name="status" defaultValue={state.status || ""} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="designation">Designation</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} placeholder="Designation" name="designation" defaultValue={state.designation || ""} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="doj">Date of Joining</label>
                        <input type="date" className={`form-control ${props.classes.readOnlyField}`} name="doj" defaultValue={state.doj || ""} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="manager">Reporting Manager</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} name="manager" defaultValue={state.manager || ""} readOnly />
                    </div>
                    <div className="col-md-12">
                        <Checkbox checked={checked} onChange={handleCheckBoxChange} inputProps={{ 'aria-label': 'controlled' }} onClick={modalOpen} disabled={editable} />
                        <label style={{ fontSize: 15 }}>I ensure that all the details are correct to best of my knowledge</label>
                    </div>

                </div>
                <div className="mt-5 text-center">
                    <CDBBtn style={{margin: 'auto'}} className="btn btn-dark rounded" color="dark" onClick={updateProfile} >
                        Save Profile
                    </CDBBtn>
                </div>
            </div>
            <Modal isOpen={modal}>
                <ModalBody>
                    <div>
                        <p className="align-items-center text-center p-2">
                            Profile details can be updated only once.<br /> Make sure that you update correct information
                        </p>
                        <div className="d-flex justify-content-center" type="submit" id="AddBtn">
                            <CDBBtn color="dark" onClick={() => setModal(false)}>
                                OK
                            </CDBBtn>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default UsUpdateUser