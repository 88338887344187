import React, { useEffect, useState } from 'react'
import NameLogo from '../images/NameLogo.png'
import {
    TabContent, TabPane, Nav,
    NavItem, NavLink, 
} from 'reactstrap';
import classnames from 'classnames';
import EmployeeTabSA from './EmployeeTabSA';
import LeaveTabSA from './LeaveTabSA';
import ProjectsTabSA from './ProjectsTabSA';
import TimeSheetSA from './TimeSheetSA';
import ResetPasswordSA from './ResetPasswordSA'
import DSRAbsentSA from './DSRAbsentSA';
import OthersSA from './OthersSA';
import WFHTabSA from './WFHTabSA';
import Footer from './Footer';
import { useNavigate, useParams } from 'react-router-dom';

function SuperAdmin() {
    const navigate = useNavigate();
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const toggle = tab => {
        if (currentActiveTab !== tab) {
            setCurrentActiveTab(tab);
           if(param.component != undefined){
            navigate("/super-admin")
           }
        }
    }
    var param = useParams();
    useEffect(() => {
        if (param.component == "leave") {
           setCurrentActiveTab("2")
        }
        if(param.component == "wfh"){
            setCurrentActiveTab("3")
        }
    }, [param])
    return (
        <div className="d-flex">
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: 'scroll' }}>
                <div style={{ overflowY: "scroll" }}>
                        <div>
                            <Nav tabs style={{ display: "inline-flex", width: "100%", flexDirection: "row" }}>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '1'
                                        })}
                                        onClick={() => { toggle('1'); }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Employee
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '2'
                                        })}
                                        onClick={() => { toggle('2'); }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Leave
                                    </NavLink>
                                </NavItem>
                                 <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '3'
                                        })}
                                        onClick={() => { toggle('3'); }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        WFH
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '4'
                                        })}
                                        onClick={() => { toggle('4'); }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Projects
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '5'
                                        })}
                                        onClick={() => { toggle('5'); }}
                                        style={{ cursor: "pointer", }}
                                    >
                                        TimeSheet
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '6'
                                        })}
                                        onClick={() => { toggle('6'); }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Others
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '7'
                                        })}
                                        onClick={() => { toggle('7'); }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        ResetPassword
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                currentActiveTab === '8'
                                        })}
                                        onClick={() => { toggle('8');}}
                                        style={{cursor:"pointer"}}
                                    >
                                        DSRAbsent
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={currentActiveTab} >
                                <TabPane tabId='1'>
                                    <EmployeeTabSA/>
                                </TabPane>
                                <TabPane tabId='2'>
                                   <LeaveTabSA/>
                                </TabPane>
                                <TabPane tabId='3'>
                                   <WFHTabSA/>
                                </TabPane>
                                <TabPane tabId='4'>
                                    <ProjectsTabSA/>
                                </TabPane>
                                <TabPane tabId='5'>
                                    <TimeSheetSA/>
                                </TabPane>
                                <TabPane tabId='6'>
                                    <OthersSA/>
                                </TabPane>
                                <TabPane tabId='7'>
                                    <ResetPasswordSA/>
                                </TabPane>
                                <TabPane tabId='8'>
                                    <DSRAbsentSA/>
                                </TabPane>
                            </TabContent>
                        </div>
                </div>
                <Footer/>
               
               
                
            </div>
            
        </div>
    )
}

export default SuperAdmin