import React from 'react'
import Card from 'src/components/Card'
import Footer from './Footer';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({

    hrletter: {
        border: " 1px solid black",
        borderCollapse: "collapse",
    },

});


function formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const formattedDate = `${day}  ${months[monthIndex]}, ${year}`;
    return formattedDate;
  }
  const today = new Date();
function ExitLetter(props) {
    const classes = useStyles();

    return (
        <div style={{height: "calc(100% - 64px)", overflowY: 'scroll' }}>
            <div className='container'>
                <Card>
                    <p style={{ float: 'right' }}>{formatDate(today)}</p>
                    <p><strong>To</strong></p>
                    <p><strong>{props?.data?.Name}</strong></p>
                    <p><strong>{props?.data?.id}</strong></p><br />
                    <p style={{ textAlign:'center' }}><strong >Relieving Letter</strong></p><br />

                    <p>Dear {props?.data?.Name},</p>
                    {/* <p>This is with reference to your letter of resignation dated Feb 17th, 2022 requesting to be </p>
                    <p>relieved from your services with us on or before April 01st, 2022. Your resignation has been  </p>
                    <p>accepted and you are relieved from your duties and responsibilities by the close of business </p>
                    <p>hours of -Date-</p><br /> */}
                    <p>This is with reference to your letter of resignation dated {formatDate(new Date(props?.data?.resignation))} requesting to be relieved from your services with us on or before {formatDate(new Date(props?.data?.exit))}. Your resignation has been accepted and you are relieved from your duties and responsibilities by the close of business hours of {formatDate(new Date(props?.data?.exit))}</p>
                    <p>Your service record with the company is as follows:</p><br />
                    <p>Date of Joining&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; :&nbsp;&nbsp;{formatDate(new Date(props?.data?.doj))}</p>
                    <p>Last Designation&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  :&nbsp;&nbsp;{props?.data?.designation}</p>
                    <p>Last Working date&nbsp; &nbsp; &nbsp; &nbsp; :&nbsp;&nbsp;{formatDate(new Date(props?.data?.exit))}</p><br/><br/>
                    <p>Thank you for your contributions and wish you the very best in your future endeavors. </p><br/><br/>
                    <p><strong>Yours sincerely</strong></p>
                    <p>Venkata Rangarajan<br/> Director and India Operations Head</p>
                </Card>
            </div>
        </div>
    )
}

export default ExitLetter