import React, { useEffect, useState } from 'react'
import NameLogo from '../images/NameLogo.png'
import {
    TabContent, TabPane, Nav,
    NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import ProjectsTabSA from './ProjectsTabSA';
import TimeSheetSA from './TimeSheetSA';
import ResetPasswordSA from './ResetPasswordSA';
import DSRAbsentSA from './DSRAbsentSA';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/stores/userSlice';

function AdminPage() {
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const user = useSelector(selectUser);
    const [empCode, setEmpCode] = useState("");
    const Kaitongo = process.env.REACT_APP_KAITONGO_MANAGER.split(',');
    useEffect(() => {
        if (user !== null) {
            if (Kaitongo.includes(user.emp_code)) {
                setEmpCode(user.emp_code);
                setCurrentActiveTab('2')
            } else {
                setEmpCode("");
            }
        }
    }, [user])
    return (
        <div className="d-flex">
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }} >
                <div style={{ height: "100%" }}>
                    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
                        <div>
                            {
                                empCode ? null :

                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        currentActiveTab === '1'
                                                })}
                                                onClick={() => { toggle('1'); }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Projects
                                            </NavLink>

                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        currentActiveTab === '2'
                                                })}
                                                onClick={() => { toggle('2'); }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Time Sheet
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        currentActiveTab === '3'
                                                })}
                                                onClick={() => { toggle('3'); }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Reset Password
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active:
                                                        currentActiveTab === '4'
                                                })}
                                                onClick={() => { toggle('4'); }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                DSR Absent
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                            }

                            <TabContent activeTab={currentActiveTab} >
                                <TabPane tabId='1'>
                                    <ProjectsTabSA />
                                </TabPane>
                                <TabPane tabId='2'>
                                    <TimeSheetSA />
                                </TabPane>
                                <TabPane tabId='3'>
                                    <ResetPasswordSA />
                                </TabPane>
                                <TabPane tabId='4'>
                                    <DSRAbsentSA />
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default AdminPage;