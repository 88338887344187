import React, { useEffect, useState } from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar } from "cdbreact";
import { Link, useNavigate } from "react-router-dom";
// import FullLogo from './images/DAFullLogo.png'
// import OnlyLogo from './images/OnlyLogo.png'
// import NameLogo from './images/NameLogo.png'
import "../src/css/Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "./stores/userSlice";
import { Button, Modal, ModalBody } from "reactstrap";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import {
  fetchNotificationAction,
  selectNotification,
} from "./stores/notificationSlice";
import { Tooltip } from "@mui/material";

const EXPIRE_TIME_MINS = 1000 * 60 * 30;
const useStyles = makeStyles({
  notificationIcon: {
    position: "absolute",
    top: "-2px",
    right: "-2px",
    width: "10px",
    height: "10px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
});
const Navbar = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const notification = useSelector(selectNotification);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [profPic, setProfPic] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const classes = useStyles();
  const logout = () => {
    redirectToLogin();
  };

  const getProfileImage = () => {
    let img_path = user?.img_dir
      ? `${process.env.REACT_APP_PUBLIC_URL}/${user?.img_dir}`
      : `${process.env.REACT_APP_PUBLIC_URL}/public/img/utAvatar.png`;
    setProfPic(img_path);
  };

  const redirectToLogin = () => {
    window.location.href = "/";
    localStorage.removeItem("authToken");
  };

  useEffect(() => {
    setTimeout(() => {
      redirectToLogin();
    }, EXPIRE_TIME_MINS);
  });

  const viewLogout = () => {
    setOpen(true);
    setDescription("Are you sure you want to quit?");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getProfileImage();
    if (user) {
      dispatch(fetchNotificationAction(user.emp_code));
    }
    if (user?.LAST_LOGIN) {
      const date = new Date(user?.LAST_LOGIN);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();
      const hours = String(date.getHours() % 12 || 12); // Get 12-hour format
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const period = date.getHours() >= 12 ? "pm" : "am";

      const formattedDate = `${day}-${month}-${year}, ${hours}:${minutes} ${period}`;
      setLastLogin(formattedDate);
    }
  }, [user]);

  return (
    <Header style={{ background: "#333", color: "#fff" }}>
      <CDBNavbar dark expand="md" scrolling className="spacing">
        <p className="mb-0">Welcome Back - {user?.name}</p>
        <div>
          <div className="ml-auto" style={{ display: "inline-block" }}>
            {lastLogin != "" ? (
              <span className="mb-0 me-3">Login Time : {lastLogin}</span>
            ) : null}
            <Tooltip title="Alerts">
              <Link
                to="/dashboard/alerts"
                active="active"
                style={{ color: "white" }}
              >
                <i
                  className="fas fa-bell navIcons"
                  id="alertbtn"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    position: "relative",
                  }}
                >
                  {notification.Alerts == "Yes" ? (
                    <span className={classes.notificationIcon}></span>
                  ) : null}
                </i>
              </Link>
            </Tooltip>
            <Tooltip title="Events">
              <Link
                to="/team/upcomingEvents"
                active="active"
                style={{ color: "white" }}
                id="msgbtn"
              >
                <i
                  className="fas fa-comment-alt navIcons mx-4"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    position: "relative",
                  }}
                >
                  {notification.Events == "Yes" ? (
                    <span className={classes.notificationIcon}></span>
                  ) : null}
                </i>
              </Link>
            </Tooltip>

            <Tooltip title="Logout">
              <i
                className="fa fa-power-off navIcons"
                style={{ cursor: "pointer" }}
                onClick={() => viewLogout()}
              ></i>
            </Tooltip>
          </div>
          <Tooltip title="Profile">
            <div
              className="align-items-end"
              style={{ display: "inline-block", cursor: "pointer" }}
              onClick={() => {
                navigate("/my-account");
              }}
            >
              {" "}
              <img
                alt={user?.name}
                src={profPic}
                style={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  margin: "0 30px",
                }}
              />
            </div>
          </Tooltip>
        </div>
      </CDBNavbar>
      <Modal
        isOpen={open}
        style={{ marginTop: "200px" }}
        toggle={() => setOpen(!open)}
      >
        <ModalBody
          style={{
            overflow: "auto",
          }}
        >
          <div>
            <strong>Log Out</strong>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              style={{ marginRight: "5px" }}
              onClick={() => logout()}
            >
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={() => setOpen(!open)}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Header>
  );
};

export default Navbar;
