import React from 'react';
import NameLogo from '../images/NameLogo.png'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({      
    container:{
        marginBottom: "5px",
        width:"100px"
    }
});

function Footer() {
    const classes = useStyles();
    return (
       <footer className="mx-auto my-3 text-center">
            <small className="ml-2 mt-1"> <img src={NameLogo} alt="Data Aces" className={classes.container}/>  &copy;  2023. All rights reserved.</small>
        </footer> 
    )
}
    
export default Footer;
