import React, { useState, useEffect} from 'react'
import { CDBBtn } from 'cdbreact'
import { toast } from 'react-toastify';
import ms from "ms";
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import "../css/Dailystatus.css"
import axios from "axios"
import {useSelector} from "react-redux"
import { selectProjects } from 'src/stores/projectSlice';


const useStyles = makeStyles({  
    root:{        
         "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows":{
            fontFamily:'Montserrat'
        },
         "& .MuiDataGrid-columnHeaderTitle":{
            fontFamily:'Montserrat',
            fontWeight:'bold'
        },
    }, 
    alignCenter:{
        textAlign:"center"
    }
});

export default function TimeSheet() {
    const classes = useStyles();
    const initialState = {
        startDate:'',
        endDate:''
    }
    const projects = useSelector(selectProjects)
    const [rows, setRows ]= useState([]);
    const [columns, setColumns ]= useState([]);
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    let timesheetData = [];
    const [state, setState] = useState(initialState)    
    let colNames =[];
    
    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }
    
    const getProjectName = (code) =>{
        const project = projects.find(el => el?.PROJECT_CODE === code)
        return project?.PROJECT_NAME
    }

    const getTimesheet = async () => {
        if(state.startDate && state.endDate){
            if(new Date(state.startDate) <= new Date(state.endDate)){
                setIsDisabled(true)
                try{
                    const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/timesheet/getTimeSheetForEmp`,{
                        startDt:state.startDate,
                        endDt:state.endDate   
                    })
                    let timesheetCol = [];             
                    if(data.length > 0){
                        timesheetCol.push({
                            field: "WORK_DAY",
                            headerName:"Date",
                            sortable: true,
                            width:120,                        
                        });                    
                        let distCol = [];
                        data.forEach((obj) => {
                            if (distCol.indexOf(obj.PROJECT_CODE) === -1)
                            distCol.push(obj.PROJECT_CODE);
                        });                    

                        // Create Table Col and Total footer
                        distCol.forEach((val) => {
                            colNames.push(val);
                            timesheetCol.push({
                                headerName: getProjectName(val),
                                field: val,
                                width:125,
                                align: "center",                         
                                footerFormatter : function (data) {
                                    var total = 0;
                                    for (let i = 0; i < data.length; i++) {
                                    if (data[i][val]) total += data[i][val];
                                    }
                                    return total;
                                },
                            });
                        });
                        
                        let filteredArray = data.reduce((acc,cur)=>{
                            let date = cur.WORK_DAY;
                            let proj = cur.PROJECT_CODE;
                            let found = acc.find(elem => elem.WORK_DAY === date && elem.PROJECT_CODE === proj)
                            if(found)
                                found.WORK_HOURS += cur.WORK_HOURS
                            else
                                acc.push(cur);
                            return acc;
                            },[]); 
                        filteredArray.forEach((obj) => {
                            var tempObj = {};                
                            tempObj["WORK_DAY"] = obj.WORK_DAY;            
                            tempObj[obj["PROJECT_CODE"]] = obj.WORK_HOURS;                        
                            timesheetData = Object.assign([], timesheetData)
                            timesheetData.push(tempObj);
                        });    

                        const groupByDate = array => array.reduce((results, item) => {
                            const current = results.find(i => i.WORK_DAY === item.WORK_DAY);
                            if (current) {
                            for (let property in item) {
                                if (property !== 'WORK_DAY') {
                                current[property] = item[property];                    }
                            }
                            } else {
                            results.push(item);
                            }
                            return results;
                        }, []);  
                        
                        timesheetData = groupByDate(timesheetData);
                        setColumns(timesheetCol)
                        setRows(timesheetData)
                        document.getElementById("getTimesheet-container").style.display = "block";
                        setIsDisabled(false)
                        // setState(initialState)
                    }else {
                        document.getElementById("getTimesheet-container").style.display = "none";
                        setIsDisabled(false)
                        toast.error("No matching records found")
                    }
                }catch(error){
                    console.log(error)
                    setIsDisabled(false)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }   
            }else toast.error("Start Date should be lesser than End Date")                     
        }else toast.error("Start Date & End Date are required")        
    }   

    const initDateRange = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDate)-minsec);
        const max_date = new Date(new Date());
        setMinDate(moment(min_date).format('YYYY-MM-DD'));
        setMaxDate(moment(max_date).format('YYYY-MM-DD'));
    }

    useEffect(()=>{
        initDateRange();        
    },[state.startDate]);

    return (    
        <div className="container" >
            <div className="row">
                <div className="col-3 bg-white m-3 box-shadow rounded" style={{height:"240px"}}>                    
                    <div className='timesheet-content'>
                        <div className="form-group">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="sd">Start Date</label>
                                        <input type="date" className="form-control" name="startDate" value={state.startDate} onChange={handleChange} required/>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="ed">End Date</label>
                                        <input type="date" className="form-control" name="endDate" value={state.endDate} onChange={handleChange} min={minDate} max={maxDate} required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <CDBBtn color="dark" className="btn btn-dark" onClick={getTimesheet} disabled={isDisabled} style={{opacity:isDisabled ? '50%' : '100%'}}>
                                Get Timesheet
                            </CDBBtn>
                        </div>
                    </div>
                </div>
                <div id="getTimesheet-container" className="col-8 p-2 bg-white box-shadow rounded" style={{display:"none",marginTop:"15px",overflow: "auto"}}>
                    <div className="d-flex justify-content-between p-2">     
                    <DataGrid
                        className={classes.root}
                        getRowId={row => row.WORK_DAY}
                        rows={rows}
                        columns={columns}
                        autoHeight 
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        hideFooterSelectedRowCount 
                    />      
                    </div>
                </div>
            </div>
        </div>
    )
}
