import { CDBBtn } from 'cdbreact'
import React from 'react'

function TokenExpired() {
    return (
        <div className="hero404">
            <div className="page-container">
                <section className="page-body">
                    <div className="message404">
                        <h4 className="h2 font-weight-bold">Oops</h4>
                        <h4 className="h5 my-4">Something went wrong</h4>
                        <h4>Please contact HR for further process</h4>

                    </div>
                    <img className="image404" alt="404" src="/img/pages/hero404.png" />
                </section>
            </div>
        </div>
    )
}

export default TokenExpired