import React, {useState, useEffect} from 'react'
import { CDBBtn } from 'cdbreact'
import "../css/OthersSA.css";
import "../css/commonStyles.css"
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux"
import { selectUser } from 'src/stores/userSlice';
import { selectEmployees } from 'src/stores/employeeSlice';
import moment from 'moment';
import ApiClient from "../ApiClient"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from "../components/Card"

const useStyles = makeStyles({  
    root:{        
        "& .MuiTableCell-root":{
            fontFamily:'Montserrat'
        },
         "& .MuiDataGrid-columnHeaderTitle":{
            fontFamily:'Montserrat',
            fontWeight:'bold'
        },
    },
    editBtn :{        
        border:"rgb(99, 102, 241)",
        color:"black",
        backgroundColor: "#e6e6e6",
    },
    container:{
        padding: "0% 2% 2% 2%"
    },
    red:{
        color:"red",
        fontSize:"12px"
    }
});

function OthersSA() {
    const classes = useStyles();
    const user = useSelector(selectUser)
    const employees = useSelector(selectEmployees)    
    const adminRoles = [{ value:"Admin", label:"Super Admin"},{ value:"Manager", label:"Manager"},{ value:"HR", label:"HR"}]
    const userRoles = [{ value:"Employee", label:"Employee"},{ value:"Trainee", label:"Graduate Trainee"},{ value:"Intern", label:"Intern"}]
    const currentYear = moment().format("YYYY");
    const [holidays,setHolidays] = useState([])
    const [columns,setColumns]=useState([])
    const [disableAdmin, setDisableAdmin] = useState(false)
    const [disableManager, setDisableManager] = useState(false)
    const [disableDesignation, setDisableDesignation] = useState(false)
    const [disableHoliday, setDisableHoliday] = useState(false)
    const initialState ={
        empCode:"",
        isAdmin:"",
        role:"",
        empName:"",
        manager:"",
        employee:"",
        designation:"",
        occasion:"",
        date:"",
        location:""
    }
    const [state, setState] = useState(initialState)

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        }); 
    }

    const updateAdminRole = async () =>{ 
        if(state.empCode && state.isAdmin && state.role){
            setDisableAdmin(true)
            try{
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/addOrRemoveAdminRole`,{
                        empCode:state.empCode,  
                        isAdmin:state.isAdmin, 
                        role:state.role
                })
                toast.success("Employee role updated successfully")
                setState(initialState); 
                setDisableAdmin(false)
            }catch(error){
                console.log(error)
                setDisableAdmin(false)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }else toast.error("All fields are mandatory")        
    }

    const updateManager = async () => {
        if(state.empName && state.manager){
            setDisableManager(true)
            try{
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/changeReportManager`,{
                    empCode :state.empName,
                    manager: state.manager
                })
                toast.success("Changes updated successfully")
                setState(initialState);
                setDisableManager(false)
            }catch(error){
                console.log(error)
                setDisableManager(false)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }else toast.error("Both fields are required")
    }

    const updateDesignation = async () => {
        if(state.employee && state.designation){
            setDisableDesignation(true)
            try{
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/changeDesignation`,{
                    employee :state.employee,
                    designation: state.designation
                })
                toast.success("Designation updated successfully")
                setState(initialState);
                setDisableDesignation(false)
            }catch(error){
                console.log(error)
                setDisableDesignation(false)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }else toast.error("Both fields are required")
    }

    const addHoliday = async () =>{
        if(state.occasion && state.date && state.location){
            setDisableHoliday(true)
            try{
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/addOfficeHoliday`,{
                    occasion:state.occasion,
                    date:state.date,
                    location:state.location,
                    day: moment(state.date).format("dddd"),
                    year: moment(state.date).format("YYYY"),
                })
                toast.success("Holiday added successfully")
                setState(initialState);
                setDisableHoliday(false)
                getOfficeHolidays();
            }catch(error){
                console.log(error)
                setDisableHoliday(false)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }else toast.error("All fields are required")
    }

    const getOfficeHolidays = async () =>{
         try{
            const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/officeholiday`,{
                year:currentYear
            })
            data.sort(function (a, b) {
                return new Date(a["DATE"]) - new Date(b["DATE"]);
            });
            let modifiedData = data.map((el,index)=>{
                el.id = index+1;
                return el
            })
            setHolidays(modifiedData)
            let holidayTableCols = [{
                headerName: "Sl No.",
                field: "id",
                sortable: true,
                width:70, 
            },{
                headerName: "Date",
                field: "DATE",
                sortable: true,
                width:150, 
                valueGetter: (params)=>{
                    return moment(params?.row?.DATE).format("DD-MMM-YYYY");
                }
            },{
                headerName: "Day",
                field: "",
                sortable: true,
                width:150, 
                valueGetter: (params)=>{
                    return moment(params?.row?.DATE).format("dddd");
                }
            },{
                headerName: "Occasion",
                field: "OCCASION",
                sortable: true,
                width:300, 
            },{
                headerName: "Location",
                field: "LOCATION",
                sortable: true,
                width:150, 
            },{
                headerName: "Action",
                field: "HOLIDAY_ID",
                sortable: true,
                width:200, 
                 renderCell: (params) => (
                    <button onClick={()=>deleteHoliday(params.row)} className={classes.editBtn}>
                        Delete
                    </button>
                ) 
            }]            
            setColumns(holidayTableCols)
        }catch(error){
            console.log(error)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    const deleteHoliday = async (holiday) =>{
        if (window.confirm("Are you sure to delete the holiday?")) {      
             try{
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/leave/removeHoliday`, {
                    data: { id: holiday.HOLIDAY_ID }
                })
                toast.success("Holiday removed successfully")
                getOfficeHolidays();
            }
            catch(error){
                console.log(error)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }
    }

    useEffect(()=>{
        getOfficeHolidays();
    },[])
    
    return (
        <div className={classes.container}>
            <Card>            
                <h4>Add/Remove Admin</h4><br />
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor='empCode'>Employee Name<span className={classes.red}>*</span></label><br />
                        <select name="empCode" value={state.empCode} className="form-control form-select" onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                employees?.filter((el)=>el.STATUS==="Active")?.map((emp)=>{
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>                                    
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor='isAdmin'>Is Admin<span className={classes.red}>*</span></label><br />
                        <select className="form-control form-select" name="isAdmin" value={state.isAdmin} onChange={handleChange} required>
                            <option disabled hidden></option>
                            <option value="Yes">True</option>
                            <option value="No">False</option>  
                        </select> 
                    </div>
                    <div className="col-md-4">
                        <label htmlFor='role'>Role<span className={classes.red}>*</span></label><br />
                        <select className="form-control form-select" name="role" value={state.role} onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                (state?.isAdmin==="Yes") ? adminRoles?.map((el)=>{
                                    return (<option key={el.value} value={el.value}>{el.label}</option>)
                                }) :  userRoles?.map((el)=>{
                                    return (<option key={el.value} value={el.value}>{el.label}</option>)
                                })
                            }                       
                                
                        </select>
                    </div>
                    <div className="col-md-2">
                        <div style={{float:"right"}}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={updateAdminRole} disabled={disableAdmin} style={{opacity:disableAdmin ? '50%' : '100%'}}>
                                Save Changes
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>                
                <h4>Change Reporting Manager</h4>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor='empName'>Employee Name<span className={classes.red}>*</span></label><br />
                        <select className="form-control form-select" name="empName" value={state.empName} onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                employees?.filter((el)=>el.STATUS==="Active")?.map((emp)=>{
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>                                    
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="manager">Reporting Manager<span className={classes.red}>*</span></label><br />
                        <select className="form-control form-select" name="manager" value={state.manager} onChange={handleChange} required>                           
                            <option disabled hidden></option>
                            <option value="venkata@data-aces.com">Venkata</option>
                            <option value="shanti@data-aces.com">Shanti</option> 
                            <option value="naren@@data-aces.com">Naren</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <div style={{float:"right"}}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={updateManager} disabled={disableManager} style={{opacity:disableManager ? '50%' : '100%'}} >
                                Save Changes
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>                
                <h4>Change Employee Designation</h4>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor='employee'>Employee Name<span className={classes.red}>*</span></label><br />
                        <select name="employee" value={state.employee} className="form-control form-select"  onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                employees?.filter((el)=>el.STATUS==="Active")?.map((emp)=>{
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>                                    
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="designation">Designation<span className={classes.red}>*</span></label><br />
                        <input type='text' className="form-control" name="designation" value={state.designation} placeholder='Designation' onChange={handleChange} required/>                                
                    </div>
                    <div className="col-md-4">
                        <div style={{float:"right"}}>
                             <CDBBtn color="dark" flat className="CDBBtn" onClick={updateDesignation} disabled={disableDesignation} style={{opacity:disableDesignation ? '50%' : '100%'}} >
                                Update Designation
                            </CDBBtn>
                        </div>                       
                    </div>
                </div>
            </Card>
            <Card>
                <h4>Add Office Holiday</h4>
                <div className="row mt-2">
                    <div className="col-md-3">
                        <label htmlFor="occasion">Occasion<span className={classes.red}>*</span></label><br />
                        <input type='text' className="form-control" name="occasion" value={state.occasion} placeholder='Enter Occasion' onChange={handleChange} required/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="date">Date<span className={classes.red}>*</span></label><br />
                        <input className="form-control" type='date' name="date" value={state.date} onChange={handleChange} required/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="location">Location<span className={classes.red}>*</span></label><br />
                        <select name="location" value={state.location} onChange={handleChange} className="form-control form-select" required>
                            <option disabled hidden></option>
                            <option value="India">India</option>
                            <option value="US">US</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <div style={{float:"right"}}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={addHoliday} disabled={disableHoliday} style={{opacity:disableHoliday ? '50%' : '100%'}}>
                                Update Holiday
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>
                <h4>Office Holidays for {currentYear}</h4><br />
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.HOLIDAY_ID}
                    {...holidays}
                    rows={holidays}
                    columns={columns}
                    autoHeight 
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    hideFooterSelectedRowCount 
                /> 
            </Card>
                       
        </div>
    )
}

export default OthersSA