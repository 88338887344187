import React, { useState, useEffect } from 'react'
import { CDBBtn } from 'cdbreact'
import "../css/ResetPasswordSA.css"
import { useSelector } from "react-redux"
import { selectEmployees } from 'src/stores/employeeSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import Card from "../components/Card"
import { makeStyles } from '@mui/styles';
import IndiaUpdateUser from 'src/components/IndiaUpdateUser';
import UsUpdateUser from 'src/components/UsUpdateUser';

const useStyles = makeStyles({
	container: {
		padding: "2%"
	},
	readOnlyField:{
		backgroundColor:"#f1f1f1"
	},
	errormsg:{
		color:"red",
		fontSize:"12px",
		marginLeft:"5px",
	},
	red:{
		color:"red",
		fontSize:"12px"
	},
	radio:{
		width: "2rem"
	},
	marginRight:{
		margin:"2rem"
	}
});


function UpdateUser() {
	const classes = useStyles();
	const initialState = {
		empCode: "",
		empID: ""
	}
	const [state, setState] = useState(initialState)
	const [isDisabled, setIsDisabled] = useState(false)
	const employees = useSelector(selectEmployees)
	const [empCountry, setEmpCountry] = useState("");
	const [empInfo, setEmpInfo] = useState({});
	function handleChange(evt) {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value
		});
	}

	const getEmpProfile = async () => {
		if (state.empCode) {
			try {
				const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getEmpProfile`, {
					empcode: state.empCode
				})
				setEmpInfo(data[0])
				setEmpCountry(data[0].EMP_COUNTRY);
			} catch (err) {
				console.log(err)
			}
		} else toast.error("Please select the employee name")
	}

	return (
		<div className={classes.container}>
			<Card>
				<div className='spacing'>
					<div>
						<h4>Update Employee Profile</h4>
					</div>
					<div>
						<label htmlFor="empCode">Employee Name<span className={classes.red}>*</span></label><br />
						<select className='input1' name="empCode" value={state.empCode} onChange={handleChange} required>
							<option disabled hidden></option>
							{
								employees && employees?.map((emp) => {
									return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>
								})
							}
						</select>
					</div>
					<div >
						<CDBBtn color="dark" flat className="CDBBtn" onClick={getEmpProfile} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
							Get Profile Details
						</CDBBtn>
					</div>
				</div>
			</Card>
			<div id="getTimesheet-container" className="col-8 p-2 bg-white box-shadow rounded" style={{ display: "none", marginTop: "15px" }}>
				<Card>
				</Card>
			</div>
			{empCountry=="" ?<div></div> : empCountry=="India"? <IndiaUpdateUser classes={classes} data={empInfo} /> : <UsUpdateUser classes={classes} data={empInfo} />}
			
		</div>
	)
}

export default UpdateUser