import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem
} from "cdbreact";
import { Link } from "react-router-dom";
import FullLogo from './images/DAFullLogo.png'
import OnlyLogo from './images/OnlyLogo.png'
import { useSelector } from "react-redux"
import { selectUser } from 'src/stores/userSlice';

const darkToast = () =>
  toast("Please submit your Daily status report for the yesterday", {
    duration: "1000",
    style: {
      borderRadius: "12px",
      background: "#90185f",
      color: "#fff",
    },
    position: "bottom-right",
  });

const Sidebar = () => {
  const user = useSelector(selectUser)
  const [empCode, setEmpCode] = useState("");
  const Kaitongo = process.env.REACT_APP_KAITONGO_MANAGER.split(',');
  useEffect(() => {
    if (user !== null) {
      if (Kaitongo.includes(user.emp_code)) {
        setEmpCode(user.emp_code);
      } else {
        setEmpCode("");
      }
    }
  }, [user])
  return (
    <div
      className={`app`}
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#333"
      >
        <CDBSidebarHeader
          prefix={
            <i className="fa fa-bars fa-large"></i>
          }
        >
          <a href="/" className="text-decoration-none" style={{ color: "inherit" }}>
            <img src={FullLogo} alt="Data Aces" height="40px" width="120px" />

          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu >
            <Link
              to="super-admin"
              active="activeClicked"
            >
              {
                user?.role === "Admin" && <CDBSidebarMenuItem >
                  <i className="fa fa-user-secret fa side-icon"></i>
                  Super-Admin
                </CDBSidebarMenuItem>
              }

            </Link>
            <Link
              to="admin"
              active="activeClicked"
            >
              {
                user?.role === "Manager" && <CDBSidebarMenuItem >
                  <i className="fas fa-user-shield fa side-icon"></i>
                  Manager
                </CDBSidebarMenuItem>
              }

            </Link>
            <Link
              to="hr-panel"
              active="activeClicked"
            >
              {
                user?.role === "HR" && <CDBSidebarMenuItem >
                  <i className="fa fa-sitemap fa side-icon"></i>
                  HR-Panel
                </CDBSidebarMenuItem>
              }
            </Link>
            <Link
              to="dashboard"
              active="activeClicked"
            >
              <CDBSidebarMenuItem>
                <i className="fa-th-large fa side-icon"></i>
                Dashboard
              </CDBSidebarMenuItem>
            </Link>
            <Link
              to="daily-status"
              active="activeClicked"
            >
              <CDBSidebarMenuItem>
                <i className="fa-keyboard fa side-icon"></i>
                Daily Status
              </CDBSidebarMenuItem>
            </Link>
            <Link
              to="leave"
              active="activeClicked"
            >
              {empCode == "" &&
                <CDBSidebarMenuItem>
                  <i className="fa-mail-bulk fa side-icon"></i>
                  Leave
                </CDBSidebarMenuItem>
              }
            </Link>
            <Link
              to="team"
              active="activeClicked"
            >
              <CDBSidebarMenuItem>
                <i class=" fa-users fa side-icon"></i>
                Our-Team
              </CDBSidebarMenuItem>
            </Link>
            <Link
              to="queries"
              active="activeClicked"
            >
              <CDBSidebarMenuItem>
                <i class="fa side-icon fa-question"></i>
                Queries
              </CDBSidebarMenuItem>
            </Link>
            <Link
              to="policies"
              active="activeClicked"
            >
              <CDBSidebarMenuItem>
                <i class="fa side-icon fa-clipboard"></i>
                Policies
              </CDBSidebarMenuItem>
            </Link>
          </CDBSidebarMenu>

        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 5px"
            }}
          >

          </div>
          <Link
            to="/"
            active="activeClicked"
          >
            <img src={OnlyLogo} alt="Data Aces" height="30px" width="60px" style={{ marginBottom: "10px" }} />
          </Link>

        </CDBSidebarFooter>
      </CDBSidebar>
      <Toaster
        position="bottom-right" />
    </div>
  );
}

export default Sidebar;
