import React from 'react'
import Card from 'src/components/Card'
import Seal from './../images/seal.png';
import Sign from './../images/sign.png';
import moment from 'moment';
function formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const formattedDate = `${day}  ${months[monthIndex]}, ${year}`;
    return formattedDate;
}
const today = new Date();
function InternshipCompletionLetter(props) {
    return (
        <div style={{ height: "calc(100% - 64px)", overflowY: 'scroll' }}>
            <div className='container'>
                <Card>
                    <p style={{ textAlign: 'center' }}><strong>Academic Internship Certificate</strong></p><br />
                    <p>{formatDate(today)}</p><br />
                    <p style={{ textAlign: 'center' }}>To Whomsoever It May Concern</p>
                    <p>This is to certify that {props?.data?.Name}.  with Roll no: {props?.data.rollNo} pursuing his {props?.data?.degree} from the Department of {props?.data?.depart}, {props?.data?.collegename} has completed his internship in our organization from {moment(new Date(props?.data?.startdate)).format('DD-MMM-YYYY')} to {moment(new Date(props?.data?.enddate)).format('DD-MMM-YYYY')}. He had completed the Internship satisfactorily under my guidance.</p><br />
                    <p>We wish him all success in his future endeavours.</p><br />
                    <p>Yours sincerely,<br />
                        D-Aces India Private Limited
                    </p>
                    <img src={Sign} />
                    <p>Venkata Rangarajan<br />
                        Director & Head of India Operations
                    </p>
                    <img src={Seal}/>


                </Card>
            </div>
        </div>
    )
}

export default InternshipCompletionLetter