import React,{useState, useEffect} from 'react'
import { CDBBtn } from 'cdbreact'
import axios from "axios";
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ms from "ms";
import moment from 'moment';
import {useSelector} from "react-redux"
import { selectEmployees } from 'src/stores/employeeSlice';
import "../css/DSRAbsentSA.css"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const useStyles = makeStyles({  
    root:{        
        "& .MuiTableCell-root":{
            fontFamily:'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle":{
            fontFamily:'Montserrat',
            fontWeight:'bold'
        },
    }, 
    dateContainer:{
        width:"10rem"
    },
    alignCenter:{
        textAlign:"center"
    },
    columnHeader:{
        fontWeight:'bolder',
        fontSize:15,
    },
    cdbBtn:{
        marginTop:'25px'
    },
    red:{
        color:"red",
        fontSize:"12px"
    }
});
function DSRAbsentSA() {
    const classes = useStyles();
    const employees = useSelector(selectEmployees)
    const [state,setState]=useState({
        date:""
    })
    const [isDisabled, setIsDisabled] = useState(false)
    const [dsrAbsent,setDsrAbsent]=useState([])
    const [columns,setColumns]=useState([])
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)

     function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }
    const getDSRAbsentData = async (date) => {
        if(state.date){
            setIsDisabled(true)
            try{
                const {data} = await axios({
                    method:'get',
                    url:`${process.env.REACT_APP_BASE_URL}/admin/getDsrAbsent`,
                    params:{  date }
                })
                let modifiedData = data.map((el,index)=>{
                    el.id=index+1;
                    return el
                })
                setDsrAbsent(modifiedData)
                let dsrAbsentTableCols = [{
                    headerName: "Sl. No.",
                    field: "id",
                    sortable: true,
                    width:50,
                },{
                    headerName: "Employee Name",
                    field: "EMP_NAME",
                    sortable: true,
                    width:300
                },{
                    headerName: "Employee Mail",
                    field: "",
                    sortable: true,
                    width:300,
                    valueGetter: (params) =>{
                         const emp = employees.find(el => el.EMP_CODE === params?.row?.EMP_CODE)
                        return emp?.EMP_WORK_EMAIL
                    }
                }]
                setColumns(dsrAbsentTableCols)
                setIsDisabled(false)
            }catch(error){
                console.log(error)
                setIsDisabled(false)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }else toast.error("Date is required")
    }

    const initDateRange = () => {
        const minsec = ms('30d')
        const min_date = new Date(+new Date()-minsec);
        const max_date = new Date(new Date());
        setMinDate(moment(min_date).format('YYYY-MM-DD'));
        setMaxDate(moment(max_date).format('YYYY-MM-DD'))
    }	
	
	useEffect(()=>{
        initDateRange();        
    },[]);

    return (
        <div className='main__div'>          
            <div className="row">
                <div className='col-5 date-container'> 
                    <div>
                        <h6><b>Select a Date to view records</b></h6>
                    </div>  
                    <div className="row">
                        <div className="col-7">   
                            <label htmlFor="date">Date<span className={classes.red}>*</span></label><br/>                    
                            <input name="date" type="date" className="date-width" value={state.date} onChange={handleChange} min={minDate} max={maxDate} required />
                        </div>
                        <div className="col-5">
                            <div style={{float:"right"}}>
                            <CDBBtn color="dark" flat onClick={()=>getDSRAbsentData(state.date)} className={classes.cdbBtn} disabled={isDisabled} style={{opacity:isDisabled ? '50%' : '100%'}} >
                                Get Data    
                            </CDBBtn>
                            </div>
                        </div>
                    </div>                  
                                        
                </div>
                    {  dsrAbsent.length > 0 && 
                    <div className='col-7 dsr-absent'>
                         <DataGrid
                            className={classes.root}
                            getRowId={row => row.EMP_NAME}
                            {...dsrAbsent}
                            rows={dsrAbsent}
                            columns={columns}
                            autoHeight 
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            hideFooterSelectedRowCount 
                        /> 
                    </div>
                    }
                
            </div>  
        </div>
    )
}

export default DSRAbsentSA