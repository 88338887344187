import React, { useState, useEffect } from 'react'
import NameLogo from '../images/NameLogo.png'
import "../css/Dailystatus.css"
import axios from "axios"
import "../css/commonStyles.css"
import { makeStyles } from '@mui/styles';
import Footer from './Footer'
import { useSelector } from "react-redux"
import { selectProjects } from 'src/stores/projectSlice';
import { selectEmployees } from 'src/stores/employeeSlice';
import { CDBBtn } from 'cdbreact'
import { Modal, ModalBody } from 'reactstrap'
import moment from 'moment'

const useStyles = makeStyles({
    flexContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    cardWrapper: {
        display: "inline-block",
        width: "33%"
    },
    spacing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    modal: {
        marginTop: "200px"
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    },
    updateAndSend: {
        float: "right",
        // marginLeft: "41%",
        border: "none",
        borderRadius: "5px"
    },
});

export default function SentItems(props) {
    const classes = useStyles()
    const [dsr, setDsr] = useState([]);
    const allProjects = useSelector(selectProjects)
    const employees = useSelector(selectEmployees)
    const [open, setOpen] = useState(false)
    const [description, setDescription] = useState('')

    const viewDesc = (desc) => {
        setOpen(true)
        setDescription(desc)
    }

    const getProject = (code) => {
        const project = allProjects.find(item => item.PROJECT_CODE === code)
        return project?.PROJECT_NAME
    }

    const getEmpName = (code) => {
        const emp = employees.find(el => el.EMP_CODE === code)
        return emp?.EMP_NAME
    }

    const getDsrData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/dsr/getAllDsr`)
            setDsr(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDsrData();
    }, [])
    
    return (
        <div className="container">
            <div className={classes.flexContainer} >
                {
                    dsr?.map((status, index, arr) => {
                        var tempArr= dsr.filter((ele)=>ele.REPORT_ID==status.REPORT_ID);
                        var sumHours=tempArr.reduce((sum,ele)=>sum+ele.HOURS_SPENT,0);
                        return (
                            <div className={classes.cardWrapper} key={index}>
                                <div className="rounded m-2 bg-white card-container">
                                    <div className="form-group font-weight-bold m-2 inner-font">
                                        <i className="fa fa-envelope font-weight-bold"></i>
                                        <span>DSR - {getEmpName(status?.EMP_CODE)} - {moment(status?.STATUS_DATE).format("DD-MMM-YYYY")}</span>
                                    </div>
                                    <hr className='border border-dark' />
                                    <div className="form-group text-secondary inner-font">
                                        <span><b>Project name</b> - {getProject(status?.PROJECT_CODE)}</span>
                                    </div>
                                    <div className="form-group text-secondary inner-font">
                                        <span><b>Task name</b> - {status?.TASK_NAME}</span>
                                    </div>
                                    <div className="form-group text-secondary inner-font">
                                        <span><b>Task type</b> - {status?.TASK_TYPE}</span>
                                    </div>
                                    <div className="form-group text-secondary inner-font">
                                        <span><b>Status</b> - {status?.COMPLETION_STATUS}</span>
                                        <span></span>
                                    </div>
                                    <div className="form-group text-secondary inner-font">
                                        <span><b>Hours</b> - {status?.HOURS_SPENT}</span>
                                        <span><button className={classes.updateAndSend} onClick={() => props.updateTask(arr[index],sumHours-status.HOURS_SPENT)}>Update & Send</button></span>
                                    </div>
                                    <hr />
                                    <div>
                                        <CDBBtn onClick={() => viewDesc(status?.TASK_DESC)} color="dark" className="btn btn-dark text-center ml-3" flat >
                                            View Description
                                        </CDBBtn>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Modal isOpen={open} className={classes.modal} toggle={() => setOpen(!open)}>
                <ModalBody className={classes.modalBody}>
                    <div>
                        <strong>Task Updates</strong>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </ModalBody>
            </Modal>
            <Footer />
        </div>
    )
}
