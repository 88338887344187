import { CDBBtn } from 'cdbreact'
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import validator from 'validator'
import { Checkbox } from '@mui/material';
import { Modal, ModalBody } from 'reactstrap'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/stores/userSlice';
import axios from 'axios';
function IndiaUpdateUser(props) {
    const [remote, setRemote] = useState("No");
    const user = useSelector(selectUser)
    const [emailError, setEmailError] = useState('');
    const [checked, setChecked] = useState(false);
    const [modal, setModal] = useState(false)
    function handleChange(evt) {
        const value = evt.target.value;
        setProfile({
            ...profile,
            [evt.target.name]: value
        });
        if (evt.target.name === "email") {
            if (validator.isEmail(value)) {
                setEmailError('Valid')
            } else {
                setEmailError('Enter valid Email!')
            }
        }
        if (evt.target.name === "remote") {
            setRemote(evt.target.value)
        }
    }

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };


    const modalOpen = () => {
        if (checked === false)
            setModal(true)
    }
    const initialValues = {
        firstName: '',
        lastName: '',
        fatherName: '',
        phone: '',
        bloodGrp: '',
        dob: '',
        addrLine1: '',
        addrLine2: '',
        city: '',
        empstate: '',
        pincode: '',
        dataAcesMail: '',
        email: '',
        contactNo: '',
        aadharNo: '',
        panNo: '',
        nameAsBank: '',
        bank: '',
        accountNo: '',
        ifsc: '',
        uanPF: '',
        manager: '',
        status: '',
        designation: '',
        remote: ''
    };
    const [profile, setProfile] = useState(initialValues);
    useEffect(() => {
        setProfile(initialValues);
        setChecked(false);
        setProfile({
            firstName: (props.data?.EMP_NAME)?.split(' ')[0],
            lastName: (props.data?.EMP_NAME)?.substr(props.data?.EMP_NAME.indexOf(" ") + 1),
            fatherName: props.data?.EMP_FATHERS_NAME,
            phone: props.data?.EMP_MOBILE_NO,
            bloodGrp: props.data?.EMP_BLOOD_GROUP,
            dob: moment(props.data?.EMP_DATE_OF_BIRTH).format("YYYY-MM-DD"),
            doj: moment(props.data?.EMP_DATE_OF_JOIN).format("YYYY-MM-DD"),
            addrLine1: props.data?.EMP_ADDRESS_1,
            addrLine2: props.data?.EMP_ADDRESS_2,
            city: props.data?.EMP_CITY,
            empstate: props.data?.EMP_STATE,
            pincode: props.data?.PINCODE,
            location: props.data?.EMP_LOCATION,
            dataAcesMail: props.data?.EMP_WORK_EMAIL,
            email: props.data?.EMP_PERSONAL_EMAIL,
            contactNo: props.data?.EMERGENCY_CONTACT_NO,
            aadharNo: props.data?.EMP_AADHAR_NO,
            panNo: props.data?.EMP_PAN_NO,
            nameAsBank: props.data?.EMP_NAME_AS_IN_BANK,
            bank: props.data?.EMP_BANK_NAME,
            accountNo: props.data?.EMP_ACCOUNT_NO,
            ifsc: props.data?.EMP_IFSC_NO,
            uanPF: props.data?.EMP_PF_NO,
            designation: props.data?.DESIGNATION,
            status: props.data?.EMP_STATUS,
            manager: props.data?.REPORTING_MANAGER_ID,
        })
        setRemote(props.data?.REMOTE_EMPLOYEE)
    }, [props.data])

    const updateProfile = async() => {
        if (profile.firstName && profile.lastName && profile.fatherName && profile.phone && profile.bloodGrp && profile.dob && profile.addrLine1 && profile.addrLine2 && profile.city && profile.pincode && profile.email && profile.contactNo && remote && profile.aadharNo && profile.panNo && profile.nameAsBank && profile.bank && profile.accountNo && profile.ifsc && profile.uanPF) {
            if (checked === true) {
                //setIsDisabled(true)
                try {
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateBio`,{
                        empcode: props.data.EMP_CODE,
                        empName: profile.firstName + " " + profile.lastName,
                        fatherName: profile.fatherName,
                        phone: profile.phone,
                        bloodGrp: profile.bloodGrp,
                        dob: profile.dob,
                        addrLine1: profile.addrLine1,
                        addrLine2: profile.addrLine2,
                        city: profile.city,
                        pincode: profile.pincode,
                        location: (props.data?.EMP_LOCATION),
                        doj: profile.doj,
                        email: profile.email,
                        contactNo: profile.contactNo,
                        aadharNo: profile.aadharNo,
                        panNo: profile.panNo,
                        nameAsBank: profile.nameAsBank,
                        bank: profile.bank,
                        accountNo: profile.accountNo,
                        ifsc: profile.ifsc,
                        uanPF: profile.uanPF,
                        remote,
                        lastUpdatedDt: moment(new Date()).format("YYYY-MM-DD"),
                        isManager: user?.is_admin,
                    })
                    toast.success("Profile updated successfully")
                    //setIsDisabled(false)
                    setTimeout(()=>{
                    	window.location.reload()
                    },2000)
                } catch (error) {
                    //setIsDisabled(false)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }
            } else toast.error("Please select the checkbox")
        }
        else (toast.error("All fields are mandatory for the profile update"))
    }

    return (
        <div>
            <div className="p-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right">Profile Settings</h4>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6 ">
                        <label htmlFor="firstName">First Name<span className={props.classes.red}>*</span></label>
                        <input type="text" className={`form-control`} placeholder="First name" name="firstName" defaultValue={profile?.firstName || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="lastName">Last name<span className={props.classes.red}>*</span></label>
                        <input type="text" className={`form-control`} placeholder="Last name" name="lastName" defaultValue={profile?.lastName || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="fatherName">Father's name<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Father's name" name="fatherName" defaultValue={profile?.fatherName || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="phone">PhoneNumber<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Phone Number" name="phone" defaultValue={profile?.phone || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="dob">Date of Birth<span className={props.classes.red}>*</span></label>
                        <input type="date" className="form-control" placeholder="DOB" name="dob" defaultValue={profile?.dob || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="bloodGrp">Blood Group<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Blood group" name="bloodGrp" defaultValue={profile?.bloodGrp || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="email">Personal Email ID<span className={props.classes.red}>*</span></label>{
                            emailError !== "Valid" && <span className={props.classes.errormsg}>{emailError}</span>
                        } <br />
                        <input type="email" className="form-control" placeholder="Enter personal email id" name="email" defaultValue={profile?.email || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="contactNo">Emergency Contact No.<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" name="contactNo" defaultValue={profile?.contactNo || ""} onChange={handleChange} required />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label htmlFor="addrLine1">Address Line 1<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Address line 1" name="addrLine1" defaultValue={profile?.addrLine1 || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="addrLine2">Address Line 2<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter Address line 2" name="addrLine2" defaultValue={profile?.addrLine2 || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="city">City<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" name="city" defaultValue={profile?.city || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="empstate">State</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} name="empstate" defaultValue={profile?.empstate} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="pincode">Pin code<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" name="pincode" defaultValue={profile?.pincode || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="dataAcesMail">Data Aces Mail</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} name="dataAcesMail" defaultValue={profile?.dataAcesMail} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="remote" style={{ marginRight: "2rem" }}>Remote Employee<span className={props.classes.red}>*</span></label>
                        <span className={props.classes.marginRight}><input className={props.classes.radio} type="radio" name="remote" value="Yes" checked={remote === "Yes"} onChange={handleChange} /> Yes</span>
                        <span className={props.classes.marginRight}><input className={props.classes.radio} type="radio" name="remote" value="No" checked={remote === "No"} onChange={handleChange} /> No</span>
                    </div>
                    <div className='col-md-6'></div>
                    <div className="col-md-6">
                        <label htmlFor="aadharNo">Aadhar Number<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Aadhar Number" name="aadharNo" defaultValue={profile?.aadharNo || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="panNo">PAN Number<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="PAN Number" name="panNo" defaultValue={profile?.panNo || ""} onChange={handleChange} required />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label htmlFor="nameAsBank">Name as in Bank<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Name as in bank" name="nameAsBank" defaultValue={profile?.nameAsBank || ""} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="bank">Bank<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Enter name of the bank" name="bank" defaultValue={profile?.bank || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="accountNo">Account Number<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="Account number" name="accountNo" defaultValue={profile?.accountNo || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="ifsc">IFS Code<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="IFS Code" name="ifsc" defaultValue={profile?.ifsc || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-12">
                        <label htmlFor="uanPF">UAN/PF<span className={props.classes.red}>*</span></label>
                        <input type="text" className="form-control" placeholder="PF Number" name="uanPF" defaultValue={profile?.uanPF || ""} onChange={handleChange} required />

                    </div>
                    <div className="col-md-6">
                        <label htmlFor="designation">Designation</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} placeholder="Designation" name="designation" defaultValue={profile?.designation || ""} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="status">Status</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} placeholder="status" name="status" defaultValue={profile?.status || ""} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="doj">Date of Joining</label>
                        <input type="date" className={`form-control ${props.classes.readOnlyField}`} name="doj" defaultValue={profile?.doj || ""} readOnly />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="manager">Reporting Manager</label>
                        <input type="text" className={`form-control ${props.classes.readOnlyField}`} name="manager" defaultValue={profile?.manager || ""} readOnly />
                    </div>
                    <div className="col-md-12">
                        <Checkbox checked={checked} onChange={handleCheckBoxChange} inputProps={{ 'aria-label': 'controlled' }} onClick={modalOpen} />
                        <label style={{ fontSize: 15 }}>I ensure that all the details are correct to best of my knowledge</label>
                    </div>

                </div>
                <div className="mt-5 text-center">
                    <CDBBtn style={{ margin: 'auto' }} className="btn btn-dark rounded" color="dark" onClick={updateProfile}  >
                        Save Profile
                    </CDBBtn>
                </div>
            </div>
            <Modal isOpen={modal}>
                <ModalBody>
                    <div>
                        <p className="align-items-center text-center p-2">
                            Profile details can be updated only once.<br /> Make sure that you update correct information
                        </p>
                        <div className="d-flex justify-content-center" type="submit" id="AddBtn">
                            <CDBBtn color="dark" onClick={() => setModal(false)}>
                                OK
                            </CDBBtn>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default IndiaUpdateUser