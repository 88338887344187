import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useState } from "react"
import Logo from './../images/Logo.webp';
import * as Yup from 'yup';
import '../css/login.css'
import { CDBBtn } from 'cdbreact'
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles({      
	backBtn:{
		color:"#515151"
	},
	red:{
		color:"red",
		fontSize:"12px"
	}
});

function ForgotPassword (){
    const classes = useStyles();
    const navigate = useNavigate();
    const [mail,setMail] = useState("");
    const [mailFlag, setMailFlag] = useState(false);
    const initialValues = {
        email:''
    };
    const validationSchema =Yup.object().shape({
        email: Yup.string()
            .email("*Field should contain a valid e-mail")
            .required("*E-mail is required"),        
    });

    const validatUser = async (values, { setSubmitting }) => {    
        setSubmitting(true);  
        if (values.email.indexOf("@data-aces") !== -1) {
               try{
                    const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/resetRequest`, {
                        email: values.email
                })
                setMail(values.email)
                setSubmitting(false); 
                setMailFlag(true)           
            }catch(error){
                console.log(error)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
                setSubmitting(false);
            }
        } else{
            setSubmitting(false);
            alert("Error! Please enter valid email");
        }                        
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={validatUser}
            >
            {({ isSubmitting }) => {
                return(
                    <div id="loginform">
                        <div className="login-container password-container-width">
                            {
                                mailFlag ? (<div>
                                    <div className="image-wrapper">
                                        <img src={Logo} height="60" alt="dataAces Logo"/>
                                        <p className="dsr-login" style={{color:"#961a1d",fontWeight:"bold"}}>Request sent successfully</p>
                                        <p className="dsr-subheader maroon-text">We have sent a confirmation email to <br/><b>{mail}</b>. Please check your mailbox.</p>                                
                                    </div>
                                     <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <CDBBtn color="dark" flat className={classes.backBtn} onClick={() => navigate('/login')}>
                                            Back to Home
                                        </CDBBtn>  
                                    </div> 
                                </div>) : ( <div>
                                    <div className="image-wrapper">
                                        <img src={Logo} height="60" alt="dataAces Logo"/>
                                        <p className="dsr-login" style={{color:"#961a1d",fontWeight:"bold"}}>Forgot your password?</p>
                                        <p className="dsr-subheader maroon-text">Please enter the email address associated with your account.<br/>We will email you a link to reset your password.</p>                                        
                                        <hr align="center" width="120" style={{margin:"3% 35%", border:'1px solid #a5a5a5'}}/>
                                    </div>
                                    <Form style={{width:'100%'}}>
                                        <div className="text-wrapper">
                                            <label style={{fontSize:14}}>
                                            Email<span className={classes.red}>*</span> <Field type="text" name="email" className={"form-control input-box text-focus"}/>
                                            <ErrorMessage name="email" component="div" className="text-danger"/>
                                            </label>
                                        </div>
                                        <div className='spacing'>
                                            <div>
                                                <CDBBtn color="light" flat className={classes.backBtn} onClick={() => navigate('/login')} style={{color:"#515151"}}>
                                                    Back
                                                </CDBBtn>  
                                            </div>
                                            <div>
                                                <CDBBtn type="submit" color="dark" flat disabled={isSubmitting} style={{opacity:isSubmitting ? '50%' : '100%'}}>
                                                    Submit
                                                </CDBBtn> 
                                            </div>  
                                        </div>
                                    </Form>
                                </div>)
                            } 
                        </div>
                    </div>
                    );
                }}
    </Formik>
  )
};
export default ForgotPassword;