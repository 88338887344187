import React, { useState, useEffect } from 'react'
import { CDBBtn } from 'cdbreact'
import "../css/ApplyLeave.css"
import "../css/commonStyles.css"
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import moment from 'moment';
import ms from "ms";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from "react-redux"
import { selectUser } from "../stores/userSlice";
import { toast } from 'react-toastify';
import LeaveCard from 'src/components/LeaveCard';

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    dateContainer: {
        width: "13rem"
    },
    green: {
        color: '#00ab55',
        fontWeight: 'bold'
    },
    red: {
        color: '#fe6383',
        fontWeight: 'bold'
    },
    container: {
        display: "flex",
        flexDirection: "row"
    },
    mandate: {
        color: "red",
        fontSize: "12px"
    }
});

function ApplyWFH() {
    const user = useSelector(selectUser)
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [officeHolidays, setOfficeHolidays] = useState([]);
    const [columns, setColumns] = useState([]);
    const [minDate, setMinDate] = useState(null)
    const initialState = {
        startDt: '',
        endDt: '',
        reason: ''
    }
    const [totalDays, setTotalDays] = useState(4)
    const [isDisabled, setIsDisabled] = useState(false)
    const [state, setState] = useState(initialState)
    const currentYear = moment().format("YYYY");
    const availableWFHDays = 2;

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const initDateRange = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDt) - minsec);
        setMinDate(moment(min_date).format('YYYY-MM-DD'));
    }

    const getOfficeHolidays = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/officeholiday`, {
                year: currentYear
            })
            let result = data.filter((el) => {
                if (el.LOCATION === user?.country)
                    return el;
            }).map((el) => moment(el.DATE).format("YYYY-MM-DD"));
            setOfficeHolidays(result)
        } catch (error) {
            console.log(error)
        }
    }

    const isInArray = (value) => {
        return (officeHolidays.find(item => { return item === value }) || []).length > 0;
    }

    const findNoOfDays = (sDate, eDate) => {
        const startDate = new Date(sDate)
        const endDate = new Date(eDate);
        endDate.setDate(endDate.getDate() + 1);
        const daysOfWeek = [];
        let i = 0;
        while (startDate < endDate) {
            if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
                if (!isInArray(moment(startDate).format("YYYY-MM-DD")))
                    daysOfWeek.push(startDate.getDay());
            }
            startDate.setDate(startDate.getDate() + 1);
            i++;
        }
        return daysOfWeek.length;
    }

    const sendWfhRequest = async () => {
        let noOfDays = findNoOfDays(state.startDt, state.endDt)
        if (state.startDt && state.endDt && state.reason) {
            if (new Date(state.startDt) <= new Date(state.endDt)) {
                setIsDisabled(true)
                try {
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/applyWfh`, {
                        code: user.emp_code,
                        name: user.name,
                        startDt: state.startDt,
                        endDt: state.endDt,
                        noOfDays: noOfDays,
                        reason: state.reason,
                        status: (totalDays === 0 || totalDays < noOfDays) ? "Pending" : "Approved"
                    })
                    toast.success("WFH Request sent successfully")
                    setState(initialState)
                    setIsDisabled(false)
                    wfhRequests();
                    getOfficeHolidays();
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000);
                } catch (error) {
                    console.log(error)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                    setIsDisabled(false)
                }
            } else toast.error("Start Date should be lesser than End Date")

        } else toast.error("All fields are required")
    }

    const wfhRequests = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/prevWFHRequests`, {
                empcode: user?.emp_code
            })
            let modifiedData = data.filter((el) => {
                return el.STATUS === "Approved"
            })
            let currentDate = new Date();
            let pending = modifiedData.filter(ele=>new Date(ele.START_DATE).getMonth() ==currentDate.getMonth()).map(days => days?.NO_OF_DAYS).reduce((acc, days) => acc + days, 0)
            let total = availableWFHDays - pending;
            (total > 0) ? setTotalDays(total) : setTotalDays(0)
        } catch (error) {
            console.log(error)
        }
    }

    const upcomimgwfhReuests = async()=>{
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/upcomingWfh`, {
                empcode: user?.emp_code
            })
            setRows(data)
            let wfhTableCols = [{
                headerName: "Employee",
                field: "EMP_NAME",
                sortable: true,
                width: 220
             }, {
                headerName: "Start Date",
                field: "START_DATE",
                sortable: true,
                width: 120,
                valueGetter: (params) => {
                    return moment(params.row?.START_DATE).format("DD-MMM-YYYY");
                }
            }, {
                headerName: "End Date",
                field: "END_DATE",
                sortable: true,
                width: 120,
                valueGetter: (params) => {
                    return moment(params.row?.END_DATE).format("DD-MMM-YYYY");
                }
            }, {
                headerName: "#Days",
                field: "NO_OF_DAYS",
                sortable: true,
                width: 80
            }, {
                headerName: "Status",
                field: "STATUS",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span className={params?.row?.STATUS === "Approved" ? classes.green : classes.red}>{params?.row?.STATUS || "Pending"}</span>
                )
            }]
            setColumns(wfhTableCols)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        getOfficeHolidays();
        wfhRequests();
        upcomimgwfhReuests();
    },[])
    useEffect(() => {
        initDateRange();
    }, [state.startDt]);

    return (
        <div className={classes.container}>
            <LeaveCard>
                <h4>Apply WFH</h4>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <label htmlFor='startDt'>Start Date<span className={classes.mandate}>*</span></label><br />
                        <input name='startDt' type='date' className="form-control" value={state.startDt} onChange={handleChange} required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor='endDt'>End Date<span className={classes.mandate}>*</span></label><br />
                        <input name='endDt' type='date' className="form-control" value={state.endDt} onChange={handleChange} min={minDate} required />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="reason">Reason<span className={classes.mandate}>*</span></label>
                        <textarea name="reason" value={state.reason} onChange={handleChange} required></textarea>
                    </div>
                    <div className="col-md-5">
                        <CDBBtn color="dark" flat className="CDBBtn" onClick={sendWfhRequest} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                            Send Request
                        </CDBBtn>
                    </div>
                </div>

                <div>
                    <p style={{ color: "red", marginTop: '20px' }}>No of days left this month : <span >{totalDays}</span></p>
                </div>
            </LeaveCard>
            <LeaveCard>
                <h4> Upcoming WFH</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.EMP_CODE + row.START_DATE + row.END_DATE}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    hideFooterSelectedRowCount
                />
            </LeaveCard>

        </div>
    )
}

export default ApplyWFH