import React, { useEffect, useState } from "react";
// import { CDBBtn, CDBIframe, CDBView } from "cdbreact";
import NameLogo from "../images/NameLogo.png";
import "../css/Profile.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ApplyLeave from "./ApplyLeave";
import RequestStatus from "./RequestStatus";
import OfficeHoliday from "./OfficeHoliday";
// import TrackMyLeave from "./TrackMyLeave";
import ApplyWFH from "./ApplyWFH";
import Footer from "./Footer";
import { selectUser } from "../stores/userSlice";
import { useSelector } from "react-redux";
export const LeavePage = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [leave, setLeave] = useState({});
  const user = useSelector(selectUser);
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const editLeaveReq = (data) => {
    setCurrentActiveTab("1");
    setLeave(data);
  };

  useEffect(() => {
	if(user?.role_type == "GT"){
		setCurrentActiveTab("4");
	}
  }, [user])
  

  return (
    <div className="d-flex profile">
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <div style={{ height: "100%", overflowY: "scroll" }}>
          <div style={{ boxShadow: "0 0 5px 2px #aaafb3" }}>
            <Nav tabs>
              {user?.role_type != "GT" && (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Apply Leave
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Apply WFH
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "3",
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Request Status
                    </NavLink>
                  </NavItem>
                </>
              )}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "4",
                  })}
                  onClick={() => {
                    toggle("4");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Office Holiday
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <ApplyLeave leave={leave} />
              </TabPane>
              <TabPane tabId="2">
                <ApplyWFH />
              </TabPane>
              <TabPane tabId="3">
                <RequestStatus editLeaveReq={editLeaveReq} />
              </TabPane>
              <TabPane tabId="4">
                <OfficeHoliday />
              </TabPane>
            </TabContent>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};
