import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import ApiClient from "../ApiClient"

export const notificationSlice = createSlice({
    name:"notification",
    initialState:{       
        notification:[],
    },
    reducers:{
        setNotification: (state,action) =>{
            state.notification = action.payload;
        }  
    }
});

export const fetchNotificationAction = (emp_code) => async dispatch => {
    try{
        const alertsAndEvents = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getAlertsAndEvents`);
        var Events = alertsAndEvents?.data?.filter((ele) => ele.TYPE == "Event");
        var unReadEvents = Events.filter((ele) => !ele?.IS_READ?.includes(emp_code));
        if(unReadEvents.length > 0){
            unReadEvents="Yes"
        }else{
            unReadEvents="No"
        }
        var Alerts = alertsAndEvents?.data?.filter((ele) => ele.TYPE == "Alert");
        var unReadAlerts = Alerts.filter((ele) => !ele?.IS_READ?.includes(emp_code))
        if(unReadAlerts.length > 0){
            unReadAlerts="Yes"
        }else{
            unReadAlerts="No"
        }
        dispatch(notificationSlice.actions.setNotification({Alerts:unReadAlerts,Events:unReadEvents}))
    }
    catch(error){
        dispatch(notificationSlice.actions.setNotification({Alerts:"No",Events:"No"}))
    }
}

export const EditNotificationAction = (data) => async dispatch => {
    dispatch(notificationSlice.actions.setNotification(data))
}

export const {setNotification,editNotification} = notificationSlice.actions;
export const selectNotification = (state) => state.notification.notification;
export default notificationSlice.reducer;