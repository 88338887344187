import React, { useEffect, useState } from 'react'
import Card from "../components/Card"
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux"
import moment from 'moment';
import axios from "axios"
import { toast } from 'react-toastify';
import { selectUser } from 'src/stores/userSlice';
import { CDBBtn } from 'cdbreact'
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    }
});

function HrNotifications() {
    const [isDisabled, setIsDisabled] = useState(false)
    const user = useSelector(selectUser)
    const classes = useStyles();
    const initialState = {
        type: "",
        message: ""
    }
    const [state, setState] = useState(initialState)
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])
    function handleChangeType(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            type: value
        });
    }
    function handleChangeMessage(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            message: value
        });
    }
    const createNotifications = async () => {


        if (state.type.length > 2 && state.message.length > 2) {
            const data = {
                'raised_by': user.name,
                'raised_date': moment(new Date).format("YYYY-MM-DD"),
                'description': state.message,
                'type': state.type
            }
            setIsDisabled(true)
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/addNotifications`, data)
                toast.success(`${state.type} added successfully`)
                setState(initialState)
                setIsDisabled(false)
                getAlertAndEvent();
            } catch (error) {
                console.log(error)
                setIsDisabled(false)
                toast.error("Something went wrong, please try again later")
            }
        } else {
            toast.error("All fields are required")
        }


    }

    const handleDelete=async(row)=>{
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/deleteAlertOrEvent`, { data:  { REQUEST_ID: row.REQUEST_ID, }  })
            toast.success(`${row?.TYPE} Deleted Successfully`);
            getAlertAndEvent();
        } catch (error) {
            toast.error("Something went wrong. Please try again later")
        }
    }
    

    const getAlertAndEvent = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/dashboard/getAlertsAndEvents`)
            setRows(data)
            let leaveTableCols = [{
                headerName: "Type",
                field: "TYPE",
                sortable: true,
                width: 100,
                renderCell: (params) => (
                    <span>{params?.row?.TYPE}</span>
                )
            }, {
                headerName: "Raised Date",
                field: "RAISED_DATE",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span>{moment(params?.row?.RAISED_DATE).format("DD-MMM-YYYY")}</span>
                )
            }, {
                headerName: "Description",
                field: "DESCRIPTION",
                sortable: true,
                width: 450,
                renderCell: (params) => (
                    <span>{params?.row?.DESCRIPTION}</span>
                )
            }, {
                headerName: "Action",
                field: "Action",
                sortable: true,
                width: 100,
                renderCell: (params) => {
                    return <>

                        {
                            <Tooltip title="Delete">
                                <i className="fa fa-trash " aria-hidden="true" style={{ color: "rgb(79 159 207)", cursor: "pointer" } } onClick={()=>handleDelete(params?.row)} ></i>
                            </Tooltip>
                        }

                    </>
                }
            }, {
                headerName: "Raised By",
                field: "REQUEST_RAISED_BY",
                sortable: true,
                width: 200,
                renderCell: (params) => (
                    <span>{params?.row?.RAISED_BY}</span>
                )
            }]
            setColumns(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getAlertAndEvent();
    }, [])


    return (
        <div className={classes.container}>
            <Card>
                <h4>Add Events/Alerts for Employees </h4><br />
                <div className='row mt-2'>
                    <div className="col-md-3">
                        <label htmlFor="empCode">Select Type<span className={classes.red}>*</span></label><br />
                        <select className="form-control form-select" name="empCode" value={state.type} required onChange={handleChangeType}>
                            <option value=''>Select type</option>
                            <option value='Event'>Event</option>
                            <option value='Alert'>Alert</option>

                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="password">Message<span className={classes.red}>*</span></label><br />
                        <textarea rows={4} className="form-control" placeholder="Enter message" value={state.message} onChange={handleChangeMessage} required></textarea>
                    </div>
                    <div className="col-md-3 mt-5 pt-4">
                        <div style={{ float: "right" }}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={createNotifications} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                                Submit
                            </CDBBtn>
                        </div>
                    </div>
                    <span style={{ color: 'red', marginTop: "20px" }}>Note*-Message should not exceed 30 words</span>
                </div>
            </Card>
            <Card>
                <h4>Upcoming Events/Alerts</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.REQUEST_ID}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    // pageSizeOptions={[5, 10]}
                    hideFooterSelectedRowCount
                />
            </Card>
        </div>
    )
}

export default HrNotifications