import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../stores/userSlice'
import employeeReducer from '../stores/employeeSlice'
import projectReducer from '../stores/projectSlice'
import notificationReducer from '../stores/notificationSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    employees: employeeReducer,
    projects: projectReducer,
    notification:notificationReducer
  },
})