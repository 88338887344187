import { useEffect } from 'react';
import routing from './Routes';
import { BrowserRouter } from 'react-router-dom';
import '../src/css/App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {	
    useEffect(()=>{
      document.title = "Data Aces - Employee App";    
    },[])
    
    return (
    <BrowserRouter>
       <ToastContainer position="top-center" />
    	  {routing()}
    </BrowserRouter>
  );
}

export default App;
