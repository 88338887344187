import React, { useState, useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Logo from './../images/Logo.webp';
import '../css/login.css'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function PasswordReset() {
    const navigate = useNavigate();
    const [key,setKey] = useState("")
    const initialValues = {       
        password: '',
        confirmPassword:''
    };
    const validationSchema =Yup.object().shape({    
        password: Yup.string()
            .min(8, 'Password must be 8 characters long')
            // .matches(/[0-9]/, 'Password requires a number')
            // .matches(/[a-z]/, 'Password requires a lowercase letter')
            // .matches(/[A-Z]/, 'Password requires an uppercase letter')
            // .matches(/[^\w]/, 'Password requires a symbol')
            .required('*Password is required'),
        confirmPassword: Yup.string()
            .required('*Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    });

    const resetPassword = async (values, { setSubmitting }) => { 
        setSubmitting(true);
            try{
                await axios.post(`${process.env.REACT_APP_BASE_URL}/reset`, {                    
                    password: values.confirmPassword,
                    key: key
                })
                setSubmitting(false);    
                toast.success("Password updated successfully")
                setTimeout(()=>{
                    navigate("/login")
                },2000)                
            }catch(error){
                setSubmitting(false);
                console.log(error)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }          
    }
    useEffect(()=>{
        let requestKey = new URLSearchParams(document.location.search).get("key");
        setKey(requestKey)
    },[])

     return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={resetPassword}
            >
            {({ isSubmitting }) => {
                return (
                    <div >
                        <div id="loginform">
                            <div className="login-container login-width">
                                <div className="image-wrapper">
                                    <img src={Logo} height="60" alt="dataAces Logo" />
                                    <p className="dsr-login">Reset Password</p>
                                    <hr align="center" width="120" style={{margin:"7% 30%", border:'1px solid #e5e5e7', }} />
                                </div>
                                 <Form style={{width:'100%'}}>
                                    <div className="text-wrapper">
                                        <label style={{fontSize:14}}>
                                        Password <Field type="password" name="password" className={"form-control input-box text-focus"} />
                                        <ErrorMessage name="password" component="div" className="text-danger"/>
                                        </label>
                                    </div>
                                    <div className="text-wrapper">
                                        <label style={{fontSize:14}}>
                                        Confirm Password
                                        <Field type="password" name="confirmPassword" className={"form-control input-box"}/>
                                        <ErrorMessage name="confirmPassword" component="div" className="text-danger"/>
                                        </label>
                                    </div>
                                    <div className="d-flex justify-content-sm-center">
                                        <button type="submit" id="sign-in" disabled={isSubmitting} style={{opacity:isSubmitting ? '50%' : '100%'}} >
                                            Reset
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                )
            }}
    </Formik>
  )   
}

export default PasswordReset
