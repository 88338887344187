import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import DashBoardLayout from "./layouts/DashBoardLayout";
import { Dashboard } from "./js/Dashboard";
import { DailyStatus } from "./js/DailyStatus";
import { Hero404 } from "./js/Hero404";
import { LeavePage } from "./js/LeavePage";
import { MyAccount } from "./js/MyAccount";
import { OurTeam } from "./js/OurTeam";
import AdminPage from "./js/AdminPage";
import SuperAdmin from "./js/SuperAdmin";
import Login from "./js/Login";
import HrPanel from "./js/HrPanel";
import PasswordReset from "./js/PasswordReset";
import ForgotPassword from "./js/ForgotPassword";
import OurTeamCard from "./js/OurTeamCard";
import QueriesAndPolicies from "./js/QueriesAndPolicies";
import OnBoarding from "./js/OnBoarding";
import QueriesPage from "./js/QueriesPage";
import Policies from "./js/Policies";
import OfferLetter from "./js/OfferLetter";
import CandidateInfo from "./js/CandidateInfo";
import UsOnBoarding from "./js/UsOnBoarding";
import UsCandidateInfo from "./js/UsCandidateInfo";

const Routers = () => {
  const loggedin = Boolean(localStorage.getItem("authToken"));
  // if(loading) return <>Loading....</>

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="onboarding/:token" element={<OnBoarding />} />
      <Route path="us-onboarding/:token" element={<UsOnBoarding />} />
      <Route path="reset-password" element={<PasswordReset />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      {loggedin ? (
        <Route path="/" element={<DashBoardLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/:component" element={<Dashboard />} />
          <Route path="daily-status" element={<DailyStatus />} />
          <Route path="leave" element={<LeavePage />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="our-team" element={<OurTeam />} />
          <Route path="team" element={<OurTeamCard />} />
          <Route path="team/:component" element={<OurTeamCard />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="super-admin" element={<SuperAdmin />} />
          <Route path="super-admin/:component" element={<SuperAdmin />} />
          <Route path="hr-panel" element={<HrPanel />} />
          {/* <Route path="queries-and-policies" element={<QueriesAndPolicies/>} /> */}
          <Route path="queries" element={<QueriesPage />} />
          <Route path="policies" element={<Policies />} />
          <Route path="candidate-info/:token" element={<CandidateInfo />} />
          <Route
            path="us-candidate-info/:token"
            element={<UsCandidateInfo />}
          />
        </Route>
      ) : (
        <Route path="/*" element={<Navigate replace to="/login" />} />
      )}
      <Route path="*" element={<Hero404 />} />
    </Routes>
  );
};

export default Routers;
