import React from 'react'
import FullLogo from '../images/DAFullLogo.png'

function OnboardSuccess() {

    return (
        <div>
            <nav class="navbar navbar-expand-md navbar-dark bg-dark p-3">
                <a className="text-decoration-none" style={{ color: "inherit" }}>
                    <img src={FullLogo} alt="Data Aces" className='ms-5' height="40px" width="120px" />
                </a>
                <div class="collapse navbar-collapse" id="navbarsExample02" className='text-center ms-5 d-none  d-sm-block' style={{ "width": "65%" }}>
                    <div >
                        <h5 className='text-light h4 fw-1 mb-0'>D-Aces Onboarding</h5>
                        <p className='text-light mb-0'>Welcome To Data-Aces  </p>
                    </div>
                </div>
            </nav>

            <div className='p-lg-5 p-2 mt-5'>
                <div style={{
                    padding: "2%",
                    boxShadow: "0 0 5px 2px #aaafb3",
                    borderRadius: "3px",
                    marginBottom: "2%",
                    height: '60vh'
                }}>
                    <div className='container h-100'>
                        <div className="d-flex justify-content-center h-100 align-items-center">
                            <div>
                                <span>
                                    <i class="far fa-check-circle" style={{ marginRight: '20px',fontSize: "52px",color: "green" }}></i>
                                </span>
                            </div>
                            <div>
                                <h1>Thanks For Submitting</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardSuccess