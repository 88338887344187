import moment from 'moment';
import React from 'react'
import Card from 'src/components/Card'

function formatDate(date) {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const formattedDate = `${day}  ${months[monthIndex]}, ${year}`;
  return formattedDate;
}
const today = new Date();
function InternOfferLetter(props) {
  return (
    <div style={{height: "calc(100% - 64px)", overflowY: 'scroll' }}>
        <div className='container'>
            <Card>
                <p>{formatDate(today)}</p><br/>
                <p><strong>{props?.data?.Name}</strong></p>
                <p>{props?.data?.collegename}</p><br/>
                <p>Dear {props?.data?.Name}</p><br/>
                <p>Welcome to Data Aces!</p><br/>
                <p>Data Aces is excited to extend an offer for the position of Academic Intern at our India Offices. This position will be located in Chennai, Tamil Nadu. The position is for an Academic Internship.</p><br/>
                <p>This position is scheduled to begin {moment(new Date(props?.data?.startdate)).format('MMM DD,YYYY')}, and will be for a period of four months. You will be evaluated for your performance during the internship period. In this role, you will report directly to Manager, Internships Program. Please be sure to bring your academic credentials and the letter from the institute, permitting you for the internship.</p><br/>
                <p>During your internship with Data Aces, you may have access to trade secrets and confidential or proprietary business information belonging to Data Aces. By accepting this offer, you acknowledge that this information must remain confidential and agree to refrain from using it for your own purposes or disclosing it to anyone outside of Data Aces. Also, you agree that upon completion of your internship, you will promptly return all company-issued assets including any proprietary information or document, equipment belonging to the company including deleting / destroying of all copies of digital documents that you have come into possession of, during your internship program. By accepting this offer, you acknowledge that you understand participation in this program is not an offer of employment, and successful completion of the program does not entitle you to an employment offer from Data Aces.</p><br/>
                <p>This offer letter represents the full extent of the internship offer and supersedes any prior conversations about the position. Changes to this agreement may only be made in writing. If you have any questions about this offer please contact the undersigned or our HR Associate. Please review this letter in full, and sign and return it via email to confirm your acceptance of the position no later than close of business on {moment(new Date(props?.data?.valid)).format('MMM DD,YYYY')}. We look forward to having you at our offices and wish you a successful internship. </p><br/>
                <p>Best Regards,</p><br/>
                <p><strong>Yours Sincerely,<br/></strong>Venkata Rangarajan<br/>Director & Head of India Operations</p><br/>
                <p><strong>ACKNOWLEDGEMENT</strong></p><br/>
                <p>I, _______________________________________________, accept the above offer and will begin the internship position on ___/___/2023</p><br/>
                <p>Signature: _______________<br/>Date:___/___/ 2023</p>


            </Card>
        </div>
    </div>
  )
}

export default InternOfferLetter