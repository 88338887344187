import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "../css/Dashboard.css";
import TeamPic from "../images/team.jpg";
import Footer from "./Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectProjects } from "src/stores/projectSlice";
import { useNavigate, useParams, Link } from "react-router-dom";
import moment from "moment";
import ms from "ms";
import { selectUser } from "src/stores/userSlice";
import {
  EditNotificationAction,
  selectNotification,
} from "src/stores/notificationSlice";
import { makeStyles } from "@mui/styles";
// import { colors } from "react-select/dist/declarations/src/theme";

const useStyles = makeStyles({
  markasread: {
    border: "none",
    borderRadius: "5px",
  },
});
export const Dashboard = () => {
  const [newLeave, setNewLeaves] = useState([]);
  const [newWFH, setNewWFH] = useState([]);
  const [dsrAbsentDate, setDsrAbsentDate] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const projects = useSelector(selectProjects);
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({});
  const [monthlyHours, setMonthlyHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [daysOff, setDaysOff] = useState(0);
  const [dsrAbsent, setDsrAbsent] = useState(0);
  const user = useSelector(selectUser);
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();
  const classes = useStyles();

  // const buildWorkHours = (categories, series, id) => {
  // 	var options = {
  // 		series,
  // 		colors: [
  // 			"#fe6383",
  // 			"#36a2eb",
  // 			"#cc65fe",
  // 			"#ffce56",
  // 			"#4cc0c0",
  // 			"#cacbcf",
  // 		],
  // 		//  colors: ['#2E93fA', '#E91E63', '#FF9800','#66DA26', '#546E7A', '#E91E63', '#FF9800'],
  // 		chart: {
  // 			toolbar: {
  // 				show: false,
  // 			},
  // 			height: 300,
  // 			width: "100%",
  // 			type: "area",
  // 			zoom: {
  // 				autoScaleYaxis: true,
  // 			},
  // 		},
  // 		dataLabels: {
  // 			enabled: false,
  // 		},
  // 		toolbar: {
  // 			show: false,
  // 		},
  // 		stroke: {
  // 			curve: "smooth",
  // 			width: 3,
  // 		},
  // 		fill: {
  // 			type: "gradient",
  // 			gradient: {
  // 				shadeIntensity: 1,
  // 				opacityFrom: 0.7,
  // 				opacityTo: 0.9,
  // 				stops: [0, 90, 100],
  // 			},
  // 		},
  // 		grid: {
  // 			show: true,
  // 			borderColor: "#d2d6db",
  // 			strokeDashArray: 2,
  // 			position: "back",
  // 		},
  // 		legend: {
  // 			show: true,
  // 			position: "top",
  // 			horizontalAlign: "right",
  // 		},
  // 		xaxis: {
  // 			type: "datetime",
  // 			// tickAmount: 6,
  // 			min: new Date("01 Apr 2023").getTime(),
  // 			max: new Date().getTime(),
  // 			labels: {
  // 				datetimeFormatter: {
  // 					year: "yyyy",
  // 					month: "MMM 'yy",
  // 					day: "dd MMM",
  // 					hour: "HH:mm",
  // 				},
  // 			},
  // 		},
  // 		yaxis: {
  // 			min: 0,
  // 		},
  // 		itemMargin: {
  // 			horizontal: 0,
  // 			vertical: 0,
  // 		},
  // 		tooltip: {
  // 			//shared: true,
  // 			x: {
  // 				format: "dd MMM yyyy",
  // 			},
  // 		},
  // 	};
  // 	const chart = new ApexCharts(document.querySelector(`#${id}`), options);
  // 	chart.render();
  // }

  const buildWorkHours2 = (categories, series, id) => {
    var projectName = series?.map((ele) => ele.name);
    var ProjectHours = [];
    series?.map((ele) => {
      ProjectHours.push(
        ele?.data
          ?.map((ele) => parseInt(ele.y))
          ?.reduce((sum = 0, ele) => sum + ele)
      );
    });
    var options = {
      series: [
        {
          name: "Total Hours",
          data: ProjectHours,
        },
      ],
      legend: {
        show: false,
      },
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },

      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
          horizontal: true,
        },
      },

      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: projectName,
      },
      fill: {
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
        ],
      },
    };
    const chart = new ApexCharts(document.querySelector(`#${id}`), options);
    chart.render();
  };

  // const buildWorkedProjects = (labels = [], series = [], id) => {
  // 	var pieOptions = {
  // 		series: series,
  // 		chart: {
  // 			width: "100%",
  // 			height: 320,
  // 			type: "pie",
  // 		},
  // 		colors: [
  // 			"#fe6383",
  // 			"#36a2eb",
  // 			"#cc65fe",
  // 			"#ffce56",
  // 			"#4cc0c0",
  // 			"#cacbcf",
  // 		],
  // 		xaxis: {
  // 			type: "category",
  // 		},
  // 		labels,
  // 		legend: {
  // 			position: "bottom",
  // 			horizontalAlign: "center",
  // 		},
  // 		plotOptions: {
  // 			pie: {
  // 				dataLabels: {
  // 					offset: -10,
  // 					minAngleToShowLabel: 0,
  // 				},
  // 			},
  // 		},
  // 		responsive: [{
  // 			breakpoint: undefined,
  // 		}],
  // 	};
  // 	var pieChart = new ApexCharts(
  // 		document.querySelector(`#${id}`),
  // 		pieOptions
  // 	);
  // 	pieChart.render();
  // }

  const buildWorkedProjects2 = (labels = [], series = [], id) => {
    var sum = series?.reduce((sum, ele) => sum + ele, 0);
    var newseries = series?.map((ele) => (ele / sum) * 100);
    var pieOptions = {
      series: newseries,
      chart: {
        height: 365,
        width: "100%",
        type: "radialBar",
      },
      fill: {
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
        ],
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
              formatter: function (val) {
                return `${Math.round((val * sum) / 100)} Hours`;
              },
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return `${sum} Hours`;
              },
            },
          },
        },
      },
      labels,
    };
    var pieChart = new ApexCharts(document.querySelector(`#${id}`), pieOptions);
    pieChart.render();
  };

  const getProjectName = (code) => {
    const project = projects.find((el) => el?.PROJECT_CODE === code);
    return project?.PROJECT_NAME;
  };

  const renderCharts = (data) => {
    let tempseries = {};
    let areaseries = {};
    let series = [];
    let series1 = [];
    if (Object.keys(data).length > 0) {
      data?.hoursSeries?.forEach((item) => {
        if (!tempseries[item.projectName]) {
          areaseries[item.projectName] = [];
          tempseries[item.projectName] = [];
        }
        areaseries[item.projectName].push(item.hours);
        let time = new Date(item.date).getTime();
        tempseries[item.projectName].push({ x: time, y: item.hours });
      });
      const labels = Object.keys(tempseries);
      for (const [key, value] of Object.entries(tempseries)) {
        series.push({
          name: key,
          data: value,
        });
      }
      for (const [key, value] of Object.entries(areaseries)) {
        const sum = value.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue),
          0
        );
        series1.push(sum);
      }
      //buildWorkHours([], series, "hours-worked");
      buildWorkHours2([], series, "hours-worked");
      // buildWorkedProjects(labels, series1, "worked-by-project");
      buildWorkedProjects2(labels, series1, "worked-by-project");
    }
  };

  const statistics = () => {
    let modifiedHours = [];
    if (dashboardData?.hoursSeries?.length > 0) {
      modifiedHours = dashboardData.hoursSeries.map((el) => {
        el.projectName = getProjectName(el.project);
        return el;
      });
      dashboardData.hoursSeries = modifiedHours;
    }
    if (dashboardData?.sumOfDayOff?.length > 0) {
      let dayoff = dashboardData?.sumOfDayOff[0]?.count;
      setDaysOff(dayoff ? dayoff : 0);
    }
    if (dashboardData?.sumOfDsrAbsent?.length > 0) {
      let dsrAbsentdays = dashboardData?.sumOfDsrAbsent[0]?.t_absent;
      setDsrAbsent(dsrAbsentdays);
    }
    if (dashboardData?.sumOfHoursWorked?.length > 0) {
      let m_hours = dashboardData?.sumOfHoursWorked[0]?.cm_hours;
      let t_hours = dashboardData?.sumOfHoursWorked[0]?.t_hours;
      setMonthlyHours(m_hours);
      setTotalHours(t_hours);
    }
    renderCharts(dashboardData);
  };

  const getDashboardData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/EmployeeStats`
      );
      setDashboardData(data);
    } catch (err) {
      console.log(err);
    }
  };
  const getDsrTaskAbsent = async () => {
    const alertsAndEvents = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dashboard/getAlertsAndEvents`
    );
    var Alerts = alertsAndEvents?.data?.filter((ele) => ele.TYPE == "Alert");
    setAlerts(Alerts);
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/dsr/getAbsentDsr`
    );
    var absentdate = data.data;
    const data2 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/leave/requestStatus`
    );
    var dates = data2.data;
    const result = [];
    dates.map((ele) => {
      const currentDate = new Date(ele.START_DATE);
      for (let i = 0; i < ele.NO_OF_DAYS; i++) {
        const formattedDate = currentDate.toISOString().slice(0, 10); // Convert to "yyyy-mm-dd" format
        result.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1); // Increment the date by 1 day
      }
    });
    var absentFinal = absentdate.filter(
      (ele) => !result.includes(moment(ele.DATE).format("YYYY-MM-DD"))
    );
    setDsrAbsentDate(absentFinal);
  };

  const fetchNewLeaves = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/newLeaveRequest`,
        { params: { mail: user?.mail } }
      );
      setNewLeaves(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNewRequests = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/newWFHRequest`,
        {
          params: {
            mail: user?.mail,
            date: moment(new Date() - ms("15d")).format("YYYY-MM-DD"),
          },
        }
      );
      let tempData = data?.filter((ele) => ele?.STATUS == "Pending" || ele?.DELETE_STATUS =="Request");
      setNewWFH(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
    getDsrTaskAbsent();
  }, []);

  useEffect(() => {
    if (user?.is_admin == "Yes") {
      fetchNewLeaves();
      fetchNewRequests();
    }
  }, [user]);

  useEffect(() => {
    statistics();
  }, [dashboardData]);

  const handleAlertcontainers = () => {
    setTimeout(() => {
      document
        .getElementById("alertCartToShow")
        .scrollIntoView({ block: "center", behavior: "smooth" });
      document.getElementById("alertCartToShow").style.scrollMarginTop =
        "100px";
      document.getElementById("alertCartToShow").style.boxShadow =
        "rgb(170, 175, 179) 0px 0px 7px 7px";
      setTimeout(() => {
        document.getElementById("alertCartToShow").style.boxShadow =
          "0 0  5px 2px #aaafb3";
      }, 1000);
    }, 100);
  };
  var param = useParams();
  useEffect(() => {
    if (param.component == "alerts") {
      handleAlertcontainers();
      if (alerts.length > 0 && user) {
        var unread = alerts.filter(
          (ele) => !ele?.IS_READ?.includes(user.emp_code)
        );
        if (unread.length > 0) {
          markAsRead();
        }
      }
    }
  }, [param, alerts, user]);

  const markAsRead = async () => {
    var unReadAlerts = alerts.filter(
      (ele) => !ele?.IS_READ?.includes(user.emp_code)
    );
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/dashboard/readAlertsOrEvents`,
        { Unread: unReadAlerts, empcode: user.emp_code }
      );
      var data = { ...notification, ["Alerts"]: "No" };
      dispatch(EditNotificationAction(data));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
      <div className="container-fluid">
        <div
          className={`row ml-5 ${
            user?.role == "Admin" ? "d-flex flex-row-reverse" : ""
          }`}
        >
          <div className="col-6">
            <div
              className={`m-3 p-4  dashboard_card  ${
                user?.role == "Admin" ? "ms-1" : "me-1"
              }`}
              style={{ boxShadow: "0 0 5px 2px #aaafb3", height: "95%" }}
            >
              <div className="">
                <div className="">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="font-weight-bold text-dark">My Stats</h4>
                    <div className="p-1 bg-grey rounded-circle">
                      <i className="fas fa-sticky-note"></i>
                    </div>
                  </div>
                  <br />
                  <div className="">
                    <p>
                      <strong>Days Off</strong> - <b>{daysOff}</b> Days
                    </p>
                    {dashboardData?.dsrException?.[0]?.DSR_EXCEPTION !==
                    "Yes" ? (
                      <>
                        <p>
                          <strong>Hours Worked </strong>(MTD) -{" "}
                          <b>{monthlyHours}</b> Hours
                        </p>

                        <p>
                          <strong>Hours Worked </strong>(T) -{" "}
                          <b>{totalHours}</b> Hours
                        </p>
                        <p>
                          <strong>DSR Absent</strong> - <b>{dsrAbsent}</b> Days
                        </p>
                      </>
                    ) : null}

                    {dashboardData?.leave?.[0] && user?.role_type != "GT" ? (
                      <>
                        <p>
                          <strong>Current Year Leave Balance </strong> -{" "}
                          <b>
                            {
                              dashboardData?.leave?.[0]
                                ?.CURRENT_YEAR_LEAVE_BALANCE
                            }
                          </b>{" "}
                          Days
                        </p>
                        <p>
                          <strong>Last Year Leave Balance </strong> -{" "}
                          <b>
                            {dashboardData?.leave?.[0]?.TOTAL_LEAVE_BALANCE}
                          </b>{" "}
                          Days
                        </p>
                      </>
                    ) : null}

                    {/* <p><strong>Leave Accumulated</strong><strong> - ## </strong> Days</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className={`m-3 p-4 dashboard_card  ${
                user?.role == "Admin" ? "me-1" : "ms-1"
              }`}
              id="alertCartToShow"
              style={{ boxShadow: "0 0 5px 2px #aaafb3", height: "95%" }}
            >
              <div id="alertcontainer">
                <div className="">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="font-weight-bold text-dark">Alerts</h4>
                    <div className=" ml-auto rounded-circle bg-grey  px-4">
                      <i className="fas fa-bell"></i>
                    </div>
                  </div>

                  <div style={{ height: "250px", overflowY: "scroll" }}>
                    {user?.role == "Admin" ? (
                      <>
                        {newLeave?.length ? (
                          <p
                            style={{ cursor: "pointer",width: "fit-content" }}
                            onClick={() => {
                              navigate("/super-admin/leave");
                            }}
                          >
                            <strong className="animate-charcter">Leave Requests for Approval</strong> -{" "}
                            <b>{newLeave?.length}</b>
                          </p>
                        ) : null}
                        {newWFH?.length ? (
                          <p
                            style={{ cursor: "pointer",width: "fit-content" }}
                            onClick={() => {
                              navigate("/super-admin/wfh");
                            }}
                          >
                            <strong className="animate-charcter">WFH Requests for Approval</strong> -{" "}
                            <b>{newWFH?.length}</b>
                          </p>
                        ) : null}
                      </>
                    ) : null}
                    <ul>
                      {alerts?.length > 0
                        ? alerts?.map((ele) => (
                            <>
                              <li>
                                <span>{ele.DESCRIPTION}</span>
                              </li>
                              <br></br>
                            </>
                          ))
                        : null}
                      {dsrAbsentDate?.length > 0
                        ? dsrAbsentDate?.map((ele) => (
                            <>
                              <li>
                                <span>
                                  <strong>Daily Status Report </strong>is
                                  missing on{" "}
                                  <strong>
                                    {moment(ele.DATE).format("DD-MM-YYYY")}
                                  </strong>{" "}
                                  Please Update soon
                                </span>
                              </li>
                              <br></br>
                            </>
                          ))
                        : null}
                      {alerts?.length == 0 && dsrAbsentDate?.length == 0
                        ? user?.role == "Admin"
                          ? newWFH.length == 0 && newLeave.length == 0
                            ? "No Alerts"
                            : null
                          : "No Alerts"
                        : null}
                    </ul>
                  </div>
                  <div>
                    {notification.Alerts == "Yes" ? (
                      <div class="d-flex justify-content-end">
                        <span>
                          <button
                            onClick={() => {
                              markAsRead();
                            }}
                            class={classes.markasread}
                          >
                            Mark As Read
                          </button>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {dashboardData?.dsrException?.[0]?.DSR_EXCEPTION !== "Yes" ? (
          <div className="row  ml-5">
            <div className="col-6">
              <div
                className="p-4 pb-1 m-3 me-1 dashboard_card"
                style={{ boxShadow: "0 0  5px 2px #aaafb3", height: "95%" }}
              >
                <div className="">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="font-weight-bold text-dark">Hours Worked</h4>
                    <div className="py-1 px-2 bg-grey rounded-circle">
                      <i className="fas fa-suitcase"></i>
                    </div>
                  </div>
                  <br />
                  <div id="chart-container">
                    <section id="hours-worked"></section>
                  </div>
                  {/* <Line data={lineChart} options={{ responsive: true }} /><br /> */}
                  <p style={{ textAlign: "center" }}>Last 3 Months Data</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className=" p-4 pb-1 m-3 ms-1 dashboard_card"
                style={{ boxShadow: "0 0  5px 2px #aaafb3", height: "95%" }}
              >
                <div className="">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="font-weight-bold text-dark">
                      Hours Worked By Projects
                    </h4>
                    <div className="px-2 py-1 bg-grey rounded-circle">
                      <i className="fas fa-chart-line"></i>
                    </div>
                  </div>
                  <br></br>
                  <div id="chart-container">
                    <section id="worked-by-project"></section>
                  </div>
                  <br></br>
                  <p style={{ textAlign: "center" }}>
                    Project-wise Time spent so Far
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* <footer className="footer">
			<div className="d-flex align-items-center" style={{ marginLeft: "15%" }}>
				<span className="footer-rem" style={{ fontSize: "3em", margin: "-2rem 0px -1.5rem 1rem", color: "#C4C4C4" }}>&#8226;</span>
				<small className="ml-2 mt-1">&copy; Data-Aces 2022. All rights reserved.</small>
			</div>

		</footer> */}
      <Footer></Footer>
    </div>
  );
};
