
import React,{useState} from 'react'
import { CDBBtn } from 'cdbreact'
import "../css/ResetPasswordSA.css"
import {useSelector} from "react-redux"
import { selectEmployees } from 'src/stores/employeeSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import Card from "../components/Card"
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({    
    container:{
        padding: "2%"
    },
    red:{
        color:"red",
        fontSize:"12px"
    }
});

function ResetPasswordSA() {
    const classes=useStyles();
    const initialState = {
        empCode:"",
        password:""
    }
    const [state,setState]=useState(initialState)
    const [isDisabled, setIsDisabled] = useState(false)
    const employees = useSelector(selectEmployees)  
    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        }); 
    }
    const resetPassword = async () =>{
        if(state.empCode && state.password){
            setIsDisabled(true)
            try{
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/resetPassword`,{
                    empcode:state.empCode,
                    password:state.password
                })
                toast.success("Password updated successfully")
                setState(initialState)
                setIsDisabled(false)
            }catch(error){
                console.log(error)
                setIsDisabled(false)
                // let errmsg = (error?.response?.data?.message) ? (error?.response?.data?.message) : (`${error?.response?.status} - ${error?.response?.statusText}`)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        }else toast.error("All fields are required")
    }
    return (
        <div className={classes.container}>          
            <Card>
                <h4>Reset Password</h4><br />
                <div className='row mt-2'>
                    <div className="col-md-5">
                        <label htmlFor="empCode">Employee Name<span className={classes.red}>*</span></label><br />
                        <select className="form-control form-select" name="empCode" value={state.empCode} onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                employees && employees?.filter((el)=>el.STATUS==="Active").map((emp)=>{
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>
                                })
                            }
                            
                        </select>                        
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="password">Password<span className={classes.red}>*</span></label><br />
                        <input type='text' name="password" value={state.password} className="form-control" placeholder="Enter new password" onChange={handleChange} required/>
                    </div>
                    <div className="col-md-3">
                        <div style={{float:"right"}}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={resetPassword} disabled={isDisabled} style={{opacity:isDisabled ? '50%' : '100%'}}>
                                Reset Password
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>           
        </div>
    )
}

export default ResetPasswordSA