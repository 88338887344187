import React, { useEffect, useState } from 'react'
import NameLogo from '../images/NameLogo.png'
import {
    TabContent, TabPane, Nav,
    NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import EmployeeTabSA from './EmployeeTabSA';
import LeaveTabSA from './LeaveTabSA';
import TimeSheetSA from './TimeSheetSA';
import ResetPasswordSA from './ResetPasswordSA';
import DSRAbsentSA from './DSRAbsentSA';
import OthersSA from './OthersSA';
import WFHTabSA from './WFHTabSA';
import UpdateUser from './UpdateUser';
import Footer from './Footer';
import HrNotifications from './HrNotifications';
import QueriesHR from './QueriesHR';
import InternHr from './InternHr';
import LetterGeneration from './LetterGeneration';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

const useStyles = makeStyles({
    notificationIcon: { position: "absolute", top: "10px", right: "5px", width: "5px", height: "5px", backgroundColor: "red", borderRadius: "50%", display: "inline-block" }
});
function HrPanel() {
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [notification, setNotification] = useState(false);
    const classes = useStyles();
    const toggle = tab => {
        if (currentActiveTab !== tab) {
            setCurrentActiveTab(tab);
        }
    }

    const checkQueries = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getQueries`)
            var panding = await data?.filter((ele) => ele.STATUS == "Pending")
            if (panding.length > 0) {
                setNotification(true)
            } else {
                setNotification(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        checkQueries();
    }, [])

    return (
        <div className="d-flex">
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: 'scroll' }}>
                <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
                    <div>
                        <Nav tabs style={{ display: "inline-flex", width: "100%", flexDirection: "row" }}>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '1'
                                    })}
                                    onClick={() => { toggle('1'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Employee
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '2'
                                    })}
                                    onClick={() => { toggle('2'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Leave
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '3'
                                    })}
                                    onClick={() => { toggle('3'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    WFH
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '4'
                                    })}
                                    onClick={() => { toggle('4'); }}
                                    style={{ cursor: "pointer", }}
                                >
                                    TimeSheet
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '5'
                                    })}
                                    onClick={() => { toggle('5'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Others
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '6'
                                    })}
                                    onClick={() => { toggle('6'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    ResetPassword
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '7'
                                    })}
                                    onClick={() => { toggle('7'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Notifications
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '8'
                                    })}
                                    onClick={() => { toggle('8'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Notifications
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '8'
                                    })}
                                    onClick={() => { toggle('8'); }}
                                    style={{ cursor: "pointer", position: "relative" }}
                                >
                                    Queries
                                    {
                                        notification ?
                                            <span className={classes.notificationIcon}></span>
                                            : null
                                    }
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '9'
                                    })}
                                    onClick={() => { toggle('9'); }}
                                    style={{ cursor: "pointer" }}
                                >
                                    Candidate
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '11'
                                    })}
                                    onClick={() => { toggle('11');}}
                                    style={{cursor:"pointer"}}
                                >
                                    Letters
                                </NavLink>
                            </NavItem> */}
                        </Nav>

                        <TabContent activeTab={currentActiveTab} >
                            <TabPane tabId='1'>
                                <EmployeeTabSA />
                            </TabPane>
                            <TabPane tabId='2'>
                                <LeaveTabSA />
                            </TabPane>
                            <TabPane tabId='3'>
                                <WFHTabSA />
                            </TabPane>
                            <TabPane tabId='4'>
                                <TimeSheetSA />
                            </TabPane>
                            <TabPane tabId='5'>
                                <UpdateUser />
                                <OthersSA />
                            </TabPane>
                            <TabPane tabId='6'>
                                <ResetPasswordSA />
                            </TabPane>
                            <TabPane tabId='7'>
                                <HrNotifications />
                            </TabPane>
                            <TabPane tabId='8'>
                                <QueriesHR checkQueries={checkQueries}/>
                            </TabPane>
                            <TabPane tabId='9'>
                                <InternHr />
                            </TabPane>
                            <TabPane tabId='10'>
                                <LetterGeneration />
                            </TabPane>

                        </TabContent>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default HrPanel