import React, { useEffect, useState } from 'react'
import {
    TabContent, TabPane, Nav,
    NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import moment from 'moment';
import ApiClient from "../ApiClient"
import { DataGrid } from '@mui/x-data-grid';
import Card from "../components/Card"
import Footer from './Footer';

const useStyles = makeStyles({  
    root:{        
        "& .MuiTableCell-root":{
            fontFamily:'Montserrat'
        },
         "& .MuiDataGrid-columnHeaderTitle":{
            fontFamily:'Montserrat',
            fontWeight:'bold'
        },
    }, 
   container:{
        padding: '2%'
    }
});


function OfficeHoliday() {
    const classes = useStyles();
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);    }
    const [indiaHolidays, setIndiaHolidays] = useState([])
    const [usHolidays, setUsHolidays] = useState([])
    const [columns,setColumns]=useState([])
    const currentYear = moment().format("YYYY");

    const getOfficeHolidays = async () =>{
        let indiaData = [];
        let usData = [];
        try{
            const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/officeholiday`,{
                year:currentYear
            })
            let sortedData = data.sort(function (a, b) {
                return new Date(a["DATE"]) - new Date(b["DATE"]);
            });

            sortedData?.forEach((el)=>{
                if(el.LOCATION==="India")
                    indiaData.push(el)
                else usData.push(el)
            })
            setIndiaHolidays(indiaData)
            setUsHolidays(usData)
            let holidayTableCols =[{
                    headerName: "Date",
                    field: "DATE",
                    sortable: true,
                    width:200, 
                    valueGetter: (params)=>{
                        return moment(params.row?.DATE).format("DD-MMM-YYYY");
                    }
                },{
                    headerName: "Day",
                    field: "DAY",
                    sortable: true,
                    width:250
                },{
                    headerName: "Occasion",
                    field: "OCCASION",
                    sortable: true,
                    width:350
                }]
                setColumns(holidayTableCols)
        }catch(error){
            console.log(error)
        }        
    }

    useEffect(() => {
        getOfficeHolidays()
    }, [])

    return (
        <div className={classes.container}>
            <Card>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h4>Office-Holidays</h4>             
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active:
                                    currentActiveTab === '1'
                            })}
                            onClick={() => { toggle('1'); }}
                            style={{ cursor: "pointer" }}
                        >
                            India-Holidays
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active:
                                    currentActiveTab === '2'
                            })}
                            onClick={() => { toggle('2'); }}
                            style={{ cursor: "pointer" }}
                        >
                            US-Holidays
                        </NavLink>
                    </NavItem>

                </Nav>

                <TabContent activeTab={currentActiveTab} >
                    <TabPane tabId='1'>
                        <DataGrid
                            className={classes.root}
                            getRowId={row => row.DATE+row.LOCATION}
                            rows={indiaHolidays}
                            columns={columns}
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            hideFooterSelectedRowCount 
                        /> 
                    </TabPane>
                    <TabPane tabId='2'>
                        <DataGrid
                            className={classes.root}
                            getRowId={row => row.DATE+row.LOCATION}
                            rows={usHolidays}
                            columns={columns}
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            hideFooterSelectedRowCount 
                        /> 
                    </TabPane>

                </TabContent>
            </Card>
            <Footer/>
        </div>
    )
}
export default OfficeHoliday