import React, { useState, useEffect } from "react";
import { CDBBtn } from "cdbreact";
import "../css/TimeSheetSA.css";
import "../css/commonStyles.css";
import { useSelector } from "react-redux";
import { selectEmployees } from "src/stores/employeeSlice";
import { selectProjects } from "src/stores/projectSlice";
import { toast } from "react-toastify";
import axios from "axios";
import ms from "ms";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import Card from "../components/Card";
import { selectUser } from "../stores/userSlice";
import DsrArchieve from "./DsrArchieve";
const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontFamily: "Montserrat",
      fontWeight: "bold",
    },
  },
  container: {
    padding: "2%",
  },
  red: {
    color: "red",
    fontSize: "12px",
  },
});

function TimeSheetSA() {
  const Kaitongo = process.env.REACT_APP_KAITONGO_MANAGER.split(",");
  const classes = useStyles();
  const initialState = {
    timesheetFilter: "",
    startDate: "",
    endDate: "",
    timesheetFor: "",
  };
  const user = useSelector(selectUser);
  const [empCode, setEmpCode] = useState("");
  const [state, setState] = useState(initialState);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  let timesheetData = [];
  let colNames = [];
  const employees = useSelector(selectEmployees);
  const [KaitongoEmp, setKaitongoEmp] = useState([]);
  const projects = useSelector(selectProjects);
  const sortedProjects = projects
    .slice()
    .sort((a, b) =>
      a.PROJECT_NAME > b.PROJECT_NAME
        ? 1
        : b.PROJECT_NAME > a.PROJECT_NAME
        ? -1
        : 0
    )
    .filter((el) => el.PROJECT_STATUS === "Open");

  useEffect(() => {
    if (user !== null) {
      if (Kaitongo.includes(user.emp_code)) {
        setEmpCode(user.emp_code);
      } else {
        setEmpCode("");
      }
    }
  }, [user]);

  useEffect(async () => {
    if (empCode) {
      setKaitongoEmp(
        employees
          .filter((ele) => ele.EMP_WORK_EMAIL.indexOf("@kaitongo.com") !== -1)
          ?.map((ele) => ele.EMP_CODE)
      );
    }
  }, [empCode, employees]);

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    if (evt.target.name == "timesheetFilter") {
      setState({ ...state, ["timesheetFor"]: "", [evt.target.name]: value });
    }
  }

  const getProjectName = (params) => {
    let code = `${params.row.PROJECT_CODE}`;
    const project = projects?.find((el) => el?.PROJECT_CODE === code);
    return project?.PROJECT_NAME;
  };

  const getEmpName = (params) => {
    let code = `${params.row.CODE}`;
    const emp = employees?.find((el) => el?.EMP_CODE === code);
    return emp?.EMP_NAME;
  };

  const htmlToRender = (Description) => {
    var regex = /<[^>]*>/g;

    // Replace HTML tags with empty string
    var extractedText = Description.replace(regex, "");

    // Replace multiple spaces with a single space
    extractedText = extractedText.replace(/\s+/g, " ");
    return extractedText;
  };
  const getTimesheet = async () => {
    let errorMsg;
    if (!state.timesheetFilter) errorMsg = "Filter type is required";
    else if (!state.startDate && !state.endDate)
      errorMsg = "Start & End Dates are required";
    else if (state.timesheetFilter !== "All" && !state.timesheetFor)
      errorMsg = `${state.timesheetFilter} name is required`;
    if (!errorMsg) {
      if (new Date(state.startDate) <= new Date(state.endDate)) {
        setIsDisabled(true);
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/admin/adminTimeSheet`,
            {
              startDt: state.startDate,
              endDt: state.endDate,
              type: state.timesheetFilter,
              timeSheetFor: state.timesheetFor,
            }
          );
          if (data.length === 0) {
            toast.error("No matching records found");
            document.getElementById("timesheet-table").style.display = "none";
            setIsDisabled(false);
          } else {
            let timesheetCols = [];
            if (state.timesheetFilter === "All") {
              timesheetCols = [
                {
                  headerName: "Employee",
                  field: "EMP_NAME",
                  sortable: true,
                  width: 250,
                },
                {
                  headerName: "Project",
                  field: "PROJECT_CODE",
                  sortable: true,
                  width: 250,
                  valueGetter: getProjectName,
                },
                {
                  headerName: "Date (YYYY-MM-DD)",
                  field: "WORK_DAY",
                  sortable: true,
                  width: 150,
                },
                {
                  title: "Hours",
                  field: "WORK_HOURS",
                  sortable: true,
                  width: 100,
                },
                {
                  title: "Task",
                  field: "TASK",
                  sortable: true,
                  width: 400,
                },
                {
                  headerName: "Task Type",
                  title: "Task Type",
                  field: "Task_Type",
                  sortable: true,
                  width: 400,
                },
                {
                  title: "Location",
                  field: "Location",
                  sortable: false,
                  width: 100,
                },
                {
                  title: "Description",
                  field: "Description",
                  sortable: false,
                  width: 400,
                },
              ];
              let tempData = data.map(
                (ele) => (ele.Description = htmlToRender(ele.Description))
              );
              let filteredArray = data.reduce((acc, cur) => {
                let date = cur.WORK_DAY;
                let proj = cur.PROJECT_CODE;
                let empName = cur.EMP_NAME;
                let found = acc.find(
                  (elem) =>
                    elem.EMP_NAME === empName &&
                    elem.WORK_DAY === date &&
                    elem.PROJECT_CODE === proj
                );
                if (found) {
                  found.TASK = found.TASK + "," + cur.TASK;
                  found.WORK_HOURS += cur.WORK_HOURS;
                } else acc.push(cur);
                return acc;
              }, []);
              timesheetData = filteredArray;
              setRows(timesheetData);
              setColumns(timesheetCols);
            } else {
              timesheetCols = [
                {
                  headerName: "Employee",
                  field: "CODE",
                  sortable: true,
                  width: 250,
                  valueGetter: getEmpName,
                },
                {
                  headerName: "Project",
                  field: "PROJECT_CODE",
                  sortable: true,
                  width: 250,
                  valueGetter: getProjectName,
                },
                {
                  headerName: "Date (YYYY-MM-DD)",
                  field: "WORK_DAY",
                  sortable: true,
                  width: 150,
                },
                {
                  title: "Hours",
                  field: "WORK_HOURS",
                  sortable: true,
                  width: 100,
                },
                {
                  title: "Task",
                  field: "TASK",
                  sortable: true,
                  width: 400,
                },
                {
                  headerName: "Task Type",
                  title: "Task Type",
                  field: "Task_Type",
                  sortable: true,
                  width: 400,
                },
                {
                  title: "Location",
                  field: "Location",
                  sortable: false,
                  width: 100,
                },
                {
                  title: "Description",
                  field: "Description",
                  sortable: false,
                  width: 400,
                },
              ];
              if (state.timesheetFilter === "Employee") {
                let tempData = data.map(
                  (ele) => (ele.Description = htmlToRender(ele.Description))
                );
                let filteredArray = data.reduce((acc, cur) => {
                  let date = cur.WORK_DAY;
                  let proj = cur.PROJECT_CODE;
                  let empName = cur.EMP_NAME;
                  let found = acc.find(
                    (elem) =>
                      elem.EMP_NAME === empName &&
                      elem.WORK_DAY === date &&
                      elem.PROJECT_CODE === proj
                  );
                  if (found) {
                    found.TASK = found.TASK + "," + cur.TASK;
                    found.WORK_HOURS += cur.WORK_HOURS;
                  } else acc.push(cur);
                  return acc;
                }, []);
                timesheetData = filteredArray;
              } else {
                let tempData = data.map(
                  (ele) => (ele.Description = htmlToRender(ele.Description))
                );
                let filteredArray = data.reduce((acc, cur) => {
                  let date = cur.WORK_DAY;
                  let proj = cur.PROJECT_CODE;
                  let emp = cur.CODE;
                  let found = acc.find(
                    (elem) =>
                      elem.CODE === emp &&
                      elem.WORK_DAY === date &&
                      elem.PROJECT_CODE === proj
                  );
                  if (found) {
                    found.TASK = found.TASK + "," + cur.TASK;
                    found.WORK_HOURS += cur.WORK_HOURS;
                  } else acc.push(cur);
                  return acc;
                }, []);
                timesheetData = filteredArray;
              }
              setRows(timesheetData);
              setColumns(timesheetCols);
              if (empCode) {
                var kaitongoData = timesheetData?.filter((ele) =>
                  KaitongoEmp.includes(ele.CODE)
                );
                setRows(kaitongoData);
              }
            }
            document.getElementById("timesheet-table").style.display = "block";
            setIsDisabled(false);
            // setState(initialState)
          }
        } catch (error) {
          console.log(error);
          setIsDisabled(false);
          let errmsg = error?.response
            ? error?.response?.data?.message
              ? error?.response?.data?.message
              : `${error?.response?.status} - ${error?.response?.statusText}`
            : "Something went wrong. Please try again later";
          toast.error(errmsg);
        }
      } else toast.error("Start Date should be lesser than End Date");
    } else toast.error(`${errorMsg}`);
  };

  const initDateRange = () => {
    const minsec = ms("0d");
    const min_date = new Date(new Date(state.startDate) - minsec);
    const max_date = new Date(new Date());
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
    setMaxDate(moment(max_date).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    initDateRange();
  }, [state.startDate]);

  return (
    <div className={classes.container}>
      {empCode == "" ? (
        <Card>
          <h4>Time Sheet</h4>
          <form>
            <div className="row mt-2">
              <div
                className={
                  state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                }
              >
                <label htmlFor="timesheetFilter">
                  Get Data by<span className={classes.red}>*</span>
                </label>
                <br />
                <select
                  name="timesheetFilter"
                  value={state.timesheetFilter}
                  className="form-control form-select"
                  onChange={handleChange}
                  required
                >
                  <option disabled hidden></option>
                  <option value="All">All</option>
                  <option value="Employee">Employee</option>
                  <option value="Project">Project</option>
                </select>
              </div>
              <div
                className={
                  state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                }
              >
                <label htmlFor="startDate">
                  Start Date<span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="startDate"
                  type="date"
                  value={state.startDate}
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className={
                  state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                }
              >
                <label htmlFor="endDate">
                  End Date<span className={classes.red}>*</span>
                </label>
                <br />
                <input
                  name="endDate"
                  type="date"
                  value={state.endDate}
                  className="form-control"
                  onChange={handleChange}
                  min={minDate}
                  max={maxDate}
                  required
                />
              </div>
              {state.timesheetFilter !== "All" && (
                <div className="col-md-4">
                  <label htmlFor="timesheetFor">
                    Select {state.timesheetFilter}
                    <span className={classes.red}>*</span>
                  </label>
                  <br />
                  <select
                    name="timesheetFor"
                    className="form-control form-select"
                    value={state.timesheetFor}
                    onChange={handleChange}
                    required
                  >
                    <option disabled selected value="">
                      Select {state.timesheetFilter}
                    </option>
                    {state.timesheetFilter === "Employee"
                      ? employees
                          ?.filter((el) => el.STATUS === "Active")
                          ?.map((el) => {
                            return (
                              <option key={el.EMP_CODE} value={el.EMP_CODE}>
                                {el.EMP_NAME}
                              </option>
                            );
                          })
                      : sortedProjects?.map((el) => {
                          return (
                            <option
                              key={el.PROJECT_CODE}
                              value={el.PROJECT_CODE}
                            >
                              {el.PROJECT_NAME}
                            </option>
                          );
                        })}
                  </select>
                </div>
              )}
              <div
                className={
                  state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                }
              >
                <div style={{ float: "right" }}>
                  <CDBBtn
                    color="dark"
                    flat
                    className="CDBBtn"
                    style={{ opacity: isDisabled ? "50%" : "100%" }}
                    onClick={getTimesheet}
                    disabled={isDisabled}
                  >
                    Get Timesheet
                  </CDBBtn>
                </div>
              </div>
            </div>
          </form>
          <div
            id="timesheet-table"
            style={{ display: "none", overflow: "auto" }}
          >
            <DataGrid
              className={classes.root}
              getRowId={(row) =>
                row.CODE +
                row.WORK_DAY +
                row.EMP_NAME +
                row.PROJECT_CODE +
                row.WORK_HOURS
              }
              rows={rows}
              columns={columns}
              autoHeight
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              hideFooterSelectedRowCount
            />
          </div>
        </Card>
      ) : (
        <>
          <Card>
            <h4>Time Sheet</h4>
            <form>
              <div className="row mt-2">
                <div
                  className={
                    state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                  }
                >
                  <label htmlFor="timesheetFilter">
                    Get Data by<span className={classes.red}>*</span>
                  </label>
                  <br />
                  <select
                    name="timesheetFilter"
                    value={state.timesheetFilter}
                    className="form-control form-select"
                    onChange={handleChange}
                    required
                  >
                    <option disabled hidden></option>
                    <option value="Employee">Employee</option>
                    <option value="Project">Project</option>
                  </select>
                </div>
                <div
                  className={
                    state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                  }
                >
                  <label htmlFor="startDate">
                    Start Date<span className={classes.red}>*</span>
                  </label>
                  <br />
                  <input
                    name="startDate"
                    type="date"
                    value={state.startDate}
                    className="form-control"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  className={
                    state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                  }
                >
                  <label htmlFor="endDate">
                    End Date<span className={classes.red}>*</span>
                  </label>
                  <br />
                  <input
                    name="endDate"
                    type="date"
                    value={state.endDate}
                    className="form-control"
                    onChange={handleChange}
                    min={minDate}
                    max={maxDate}
                    required
                  />
                </div>
                {state.timesheetFilter !== "All" && (
                  <div className="col-md-4">
                    <label htmlFor="timesheetFor">
                      Select {state.timesheetFilter}
                      <span className={classes.red}>*</span>
                    </label>
                    <br />
                    <select
                      name="timesheetFor"
                      className="form-control form-select"
                      value={state.timesheetFor}
                      onChange={handleChange}
                      required
                    >
                      <option disabled value="">
                        Select {state.timesheetFilter}
                      </option>
                      {state.timesheetFilter === "Employee"
                        ? employees
                            ?.filter(
                              (el) =>
                                el.STATUS === "Active" &&
                                el.EMP_WORK_EMAIL.indexOf("@kaitongo.com") !==
                                  -1
                            )
                            ?.map((el, index) => {
                              return (
                                <option key={index} value={el.EMP_CODE}>
                                  {el.EMP_NAME}
                                </option>
                              );
                            })
                        : sortedProjects
                            ?.filter((ele) => ele.PROJECT_CODE == "PR000082")
                            ?.map((el, index) => {
                              return (
                                <option key={index} value={el.PROJECT_CODE}>
                                  {el.PROJECT_NAME}
                                </option>
                              );
                            })}
                    </select>
                  </div>
                )}
                <div
                  className={
                    state.timesheetFilter === "All" ? "col-md-3" : "col-md-2"
                  }
                >
                  <div style={{ float: "right" }}>
                    <CDBBtn
                      color="dark"
                      flat
                      className="CDBBtn"
                      style={{ opacity: isDisabled ? "50%" : "100%" }}
                      onClick={getTimesheet}
                      disabled={isDisabled}
                    >
                      Get Timesheet
                    </CDBBtn>
                  </div>
                </div>
              </div>
            </form>
            <div
              id="timesheet-table"
              style={{ display: "none", overflow: "auto" }}
            >
              <DataGrid
                className={classes.root}
                getRowId={(row) =>
                  row.CODE +
                  row.WORK_DAY +
                  row.EMP_NAME +
                  row.PROJECT_CODE +
                  row.WORK_HOURS
                }
                rows={rows}
                columns={columns}
                autoHeight
                slots={{
                  toolbar: GridToolbar,
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                hideFooterSelectedRowCount
              />
            </div>
          </Card>
          <DsrArchieve></DsrArchieve>
        </>
      )}
    </div>
  );
}

export default TimeSheetSA;
