import React, { useEffect, useState, useRef } from 'react'
import "../css/ProjectsTabSA.css"
import "../css/commonStyles.css"
import { CDBBtn } from 'cdbreact'
import Select from "react-select"
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody } from 'reactstrap';
import moment from 'moment'
import ms from "ms";
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { selectUser } from "src/stores/userSlice"
import { selectProjects ,fetchProjects } from 'src/stores/projectSlice';
import { selectEmployees } from 'src/stores/employeeSlice';
import ApiClient from "../ApiClient"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import validator from 'validator'
import Card from "../components/Card"
import Footer from './Footer'

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows": {
            fontFamily: 'Montserrat'
        },
        "& #teamWidth": {
            width: 50
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
        "& .edit-btn": {
            border: "rgb(99, 102, 241)",
            borderRadius: "5px",
            backgroundColor: "rgb(99, 102, 241)"
        },
    },
    editBtn: {
        border: "rgb(99, 102, 241)",
        color: "black",
        backgroundColor: "#e6e6e6",
    },
    modalInput: {
        width: '13.5rem'
    },
    mandate: {
        color: "red"
    },
    errormsg: {
        color: "red",
        fontSize: '12px',
        marginLeft: '10px'
    },
    newClient: {
        marginTop: '25px',
        // backgroundColor:"#e6e6e6",
        // color:"black"
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    }
});

function ProjectsTabSA() {
    const dispatch = useDispatch()
    let selectRef = useRef(null);
    let selectRef2 = useRef(null);
    const [modal, setModal] = useState(false)
    const [client, setClient] = useState('');
    const [updatedProj, setUpdatedProj] = useState({});
    const user = useSelector(selectUser)
    const projects = useSelector(selectProjects)
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [minDate1, setMinDate1] = useState(null)
    const [minDate2, setMinDate2] = useState(null)
    const [editTab, setEditTab] = useState(false)
    const employees = useSelector(selectEmployees);
    const [isDisabled, setIsDisabled] = useState(false)
    const classes = useStyles();
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [emailError, setEmailError] = useState('')
    const [tags, setTags] = useState([]);
    const [filterText, setFilterText] = useState('')
    const toggle = tab => {
        if (currentActiveTab !== tab) {
            setCurrentActiveTab(tab);
        }
    }
    const [selectedOptions, setSelectedOptions] = useState([]);
    const selectHandleChange = (empOptions) => {
        setSelectedOptions(empOptions);
    }
    const initialState = {
        clientName: "",
        projectID: "",
        projectName: "",
        startDt: "",
        endDt: "",
        poc: "",
        projDesc: "",
        client: "",
        projectCode: "",
        updatedID: "",
        updatedName: "",
        startDate: "",
        endDate: "",
        updatedPOC: "",
        updatedDesc: "",
        status: "",
        clientID: '',
        newClientName: '',
        startdate: '',
        clientGeo: '',
        clientContact: '',
        clientMail: '',
        clientPhone: '',
        clientParent: '',
        clientStatus: '',
    }
    const [state, setState] = useState(initialState)
    const activeEmployees = employees.filter((el) => {
        return el.STATUS === "Active"
    })
    const empOptions = activeEmployees?.map(person => ({ value: person.EMP_WORK_EMAIL, label: person.EMP_NAME }))

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
        if (evt.target.name === "clientMail") {
            if (validator.isEmail(value)) {
                setEmailError('Valid')
            } else {
                setEmailError('Enter valid Email!')
            }
        }
    }

    const editProjectDetails = (project) => {
        setEditTab(true)
        toggle('2');
        let data = {};
        let modifiedData = Object.assign(data, project);
        modifiedData.START_DATE = moment(modifiedData.START_DATE).format("YYYY-MM-DD")
        modifiedData.END_DATE = moment(modifiedData.END_DATE).format("YYYY-MM-DD")
        setUpdatedProj(modifiedData)
        setState({
            clientID:modifiedData.CLIENT_CODE,
            client: modifiedData.CLIENT_NAME,
            updatedID: modifiedData.PROJECT_ID,
            updatedName: modifiedData.PROJECT_NAME,
            startDate: modifiedData.START_DATE,
            endDate: modifiedData.END_DATE || undefined,
            updatedPOC: modifiedData.PROJECT_POC,
            updatedDesc: modifiedData.PROJECT_DESC,
            projectCode: modifiedData.PROJECT_CODE,
            status: modifiedData.PROJECT_STATUS
        });
        let team = modifiedData.PROJECT_TEAM.split(",")
        let result = team?.map((el) => {
            return empOptions.find(item => item.value === el)
        })
        setTags(result)
        setTimeout(() => {
            let element = document.getElementById("editProjectContainer");
            element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
    }

    const updateProjectDetails = async () => {
        let projTeam = getProjAssignees();
        if (state.clientID && state.updatedID && state.updatedName && state.status && state.startDate && state.updatedPOC && projTeam && state.updatedDesc) {
            if (state.status === "Closed" && state.endDate === "Invalid date")
                toast.error("End Date is mandatory for project closure")
            else {
                let endDate = (state.endDate == "Invalid date") ? undefined : state.endDate
                setIsDisabled(true)
                try {
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/editProject`, {
                        projID: state.updatedID,
                        projName: state.updatedName,
                        clientCode: state.clientID,
                        startDate: state.startDate,
                        endDate,
                        poc: state.updatedPOC,
                        status: state.status,
                        projTeam,
                        projDesc: state.updatedDesc,
                        projectCode: state.projectCode
                    })
                    toast.success("Project updated successfully")
                    dispatch(fetchProjects())
                    setState(initialState);
                    setTags([]);
                    setIsDisabled(false)
                } catch (error) {
                    console.log(error)
                    setIsDisabled(false)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }
            }
        } else {
            toast.error("All fields are mandatory")
        }
    }

    const getProjAssignees = () => {
        let projTeam = [];
        let rawData = (selectedOptions.length > 0) ? selectedOptions : tags;
        projTeam = rawData?.map((emp) => {
            return emp?.value
        }).join()
        return projTeam;
    }

    const getClient = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getClient`)
            setClient(data)
        }
        catch (error) {
            console.log(error)
            // let errmsg = (error?.response?.data?.message) ? (error?.response?.data?.message) : (`${error?.response?.status} - ${error?.response?.statusText}`)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    const addNewProject = async () => {
        let projTeam = getProjAssignees();
        if (state.clientName && state.projectID && state.projectName && state.startDt && state.poc && projTeam && state.projDesc) {
            setIsDisabled(true)
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/addNewProject`, {
                    projID: state.projectID,
                    projName: state.projectName,
                    clientCode: state.clientName,
                    startDate: state.startDt,
                    endDate: state.endDt || undefined,
                    poc: state.poc,
                    projTeam,
                    projDesc: state.projDesc,
                    createdBy: user?.name
                })
                setState(initialState)
                setSelectedOptions([])
                toast.success("Project added successfully")
                setIsDisabled(false)
                dispatch(fetchProjects())
                // console.log(useSelector(selectProjects));
            } catch (error) {
                setIsDisabled(false)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        } else {
            toast.error("All fields are mandatory except End Date")
        }
    }

    const getProjectDetails = () => {
        let projectTableCols = [{
            headerName: "Action",
            field: "CLIENT_CODE",
            sortable: true,
            width: 70,
            renderCell: (params) => (
                <button onClick={() => editProjectDetails(params.row)} className={classes.editBtn}>
                    Edit
                </button>
            )
        }, {
            headerName: "Client Name",
            field: "CLIENT_NAME",
            sortable: true,
            width: 120,
        }, {
            headerName: "Project ID",
            field: "PROJECT_ID",
            sortable: true,
            width: 100,
        }, {
            headerName: "Project Name",
            field: "PROJECT_NAME",
            sortable: true,
            width: 200,

        }, {
            headerName: "Start Date",
            field: "START_DATE",
            sortable: true,
            width: 100,
            valueGetter: (params) => {
                return moment(params.row?.START_DATE).format("DD-MMM-YYYY");
            }
        }, {
            headerName: "End Date",
            field: "END_DATE",
            sortable: true,
            width: 100,
            valueGetter: (params) => {
                return (params.row?.END_DATE) ? moment(params.row?.END_DATE).format("DD-MMM-YYYY") : "-";
            }
        }, {
            headerName: "Description",
            field: "PROJECT_DESC",
            sortable: true,
            width: 200,
            valueGetter: (params) => {
                return (params.row?.PROJECT_DESC && params.row?.PROJECT_DESC !== "NULL") ? params.row?.PROJECT_DESC : "-";
            }
        }, {
            headerName: "Status",
            field: "PROJECT_STATUS",
            sortable: true,
            width: 80,
        }, {
            headerName: "POC",
            field: "PROJECT_POC",
            sortable: true,
            width: 250,
        }, {
            headerName: "Team Members",
            field: "PROJECT_TEAM",
            sortable: true,
            width: 750,
            headerAlign: 'center',
        }]
        setColumns(projectTableCols)
        setRows(projects)
    }

    const clearData = () => {
        setState(initialState);
        selectRef.clearValue();
        selectRef2.clearValue();
    }

    const addNewClient = async () => {
        if (state.clientID && state.newClientName && state.startdate && state.clientMail && state.clientStatus) {
            if (!client?.filter((ele) => ele.CLIENT_STATUS === "Active")?.some((obj) => obj.CLIENT_ID.toLowerCase() === state.clientID.toLowerCase() && obj.CLIENT_NAME.toLowerCase() === state.newClientName.toLowerCase())) {
                setIsDisabled(true)
                try {
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/addNewClient`, {
                        id: state.clientID,
                        name: state.newClientName,
                        date: state.startdate,
                        geo: state.clientGeo,
                        contact: state.clientContact,
                        email: state.clientMail,
                        phone: state.clientPhone,
                        parent: state.clientParent,
                        status: state.clientStatus
                    })
                    setState(initialState)
                    setModal(false)
                    toast.success(`Client ${state.newClientName} added successfully`)
                    setIsDisabled(false)
                    getClient();
                } catch (error) {
                    console.log(error)
                    setIsDisabled(false)
                    // let errmsg = (error?.response?.data?.message) ? (error?.response?.data?.message) : (`${error?.response?.status} - ${error?.response?.statusText}`)                
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }
            } else toast.error("Client Id and Client Name combination already exists")
        } else toast.error("Please enter input for mandatory fields")
    }

    const initDateRangeForAdd = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDt) - minsec);
        setMinDate1(moment(min_date).format('YYYY-MM-DD'));
    }

    const searchTable = (event) => {
        // let input = document.getElementById('searchInput').value
        setFilterText(event.target.value)
    }
    var filteredRows = rows.filter(
        (row) =>
            row?.PROJECT_NAME?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.CLIENT_NAME?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.PROJECT_DESC?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
            row?.PROJECT_ID?.toLowerCase()?.includes(filterText?.toLowerCase())
    );
    filteredRows = filteredRows.sort((a, b) => a.PROJECT_NAME.localeCompare(b.PROJECT_NAME));

    const initDateRangeForEdit = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDate) - minsec);
        setMinDate2(moment(min_date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        initDateRangeForAdd();
    }, [state.startDt]);

    useEffect(() => {
      getProjectDetails()
    }, [projects])
    

    useEffect(() => {
        initDateRangeForEdit();
    }, [state.startDate]);

    useEffect(() => {
        getClient();
        getProjectDetails();
    }, [])

    // useEffect(()=>{
    //     setSortModel(initialSort)
    // },[sortModel])

    return (
        <div className={classes.container}>
            <Card>
                <Nav tabs className="Navstyle1">
                    <NavItem>
                        <NavLink className={classnames({
                            active:
                                currentActiveTab === '1'
                        })}
                            onClick={() => { toggle('1'); }}
                            style={{ cursor: "pointer" }}>
                            Add New Project
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({
                            active:
                                currentActiveTab === '2'
                        })}
                            onClick={() => { toggle('2'); }}
                            style={{ cursor: "pointer", display: editTab ? "block" : "none" }}>
                            Edit Project
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab} >
                    <TabPane tabId="1">
                        <Card>
                            <h4 className='my-1'>Add New Project</h4>
                            <form>
                                <div className="row mt-2">
                                    {
                                        user?.role === "Admin" ? (<>
                                            <div className="col-md-4" >
                                                <label htmlFor="clientName">Client<span className={classes.red}>*</span></label><br />
                                                <select className="form-control form-select" name="clientName" value={state.clientName} onChange={handleChange} required>
                                                    <option disabled hidden></option>
                                                    {
                                                        client && client.map((el) => {
                                                            return <option key={el.CLIENT_CODE} value={el.CLIENT_CODE}>{el.CLIENT_NAME}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-2">
                                                <div style={{ float: "right" }}>
                                                    <CDBBtn flat className={classes.newClient} onClick={() => setModal(true)}>
                                                        Add Client
                                                    </CDBBtn>
                                                </div>
                                            </div>
                                        </>
                                        ) : (<div className="col-md-6">
                                            <label htmlFor="clientName">Client<span className={classes.red}>*</span></label><br />
                                            <select className="form-control form-select" name="clientName" value={state.clientName} onChange={handleChange} required>
                                                <option disabled hidden></option>
                                                {
                                                    client && client.map((el) => {
                                                        return <option key={el.CLIENT_CODE} value={el.CLIENT_CODE}>{el.CLIENT_NAME}</option>
                                                    })
                                                }
                                            </select>
                                        </div>)
                                    }
                                    <div className="col-md-3">
                                        <label htmlFor="projectID" >Project ID<span className={classes.red}>*</span></label><br />
                                        <input type="text" name="projectID" placeholder='Enter Project ID' className="form-control" value={state.projectID} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="projectName" >Project Name<span className={classes.red}>*</span></label><br />
                                        <input type="text" name="projectName" placeholder='Enter Project Name' className="form-control" value={state.projectName} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="startDt">Start Date<span className={classes.red}>*</span></label><br />
                                        <input type='date' name="startDt" className="form-control" value={state.startDt} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="endDt">End Date<span className={classes.red}>*</span></label><br />
                                        <input type='date' name="endDt" className="form-control" value={state.endDt} onChange={handleChange} min={minDate1} required />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="poc">Point Of Contact<span className={classes.red}>*</span></label><br />
                                        <select name="poc" className="form-control form-select" value={state.poc} onChange={handleChange} required >
                                            <option disabled hidden></option>
                                            {
                                                employees && employees.filter((el) => el.STATUS === "Active").map((emp) => {
                                                    return <option key={emp.EMP_CODE} value={emp.EMP_WORK_EMAIL}>{emp.EMP_NAME}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                               
                                    <div className='col-6'>
                                        <label htmlFor="projTeam">Team Members<span className={classes.red}>*</span></label><br />
                                        <Select
                                            ref={(ref) => {
                                                selectRef = ref;
                                            }}
                                            name="projTeam"
                                            isMulti='true'
                                            options={empOptions}
                                            value={selectedOptions}
                                            required
                                            onChange={selectHandleChange}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label htmlFor="projDesc">Description<span className={classes.red}>*</span></label><br />
                                        <textarea name="projDesc" className="textarea1" placeholder="Enter Project Description" value={state.projDesc} onChange={handleChange} required></textarea><br />
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: "inline-block", marginRight: "10px" }}>
                                        <CDBBtn color="dark" flat className="CDBBtn" onClick={addNewProject} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                                            Add Project
                                        </CDBBtn>
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        <CDBBtn color="dark" flat className="CDBBtn" onClick={clearData}>
                                            Reset
                                        </CDBBtn>
                                    </div>
                                </div>
                            </form>
                        </Card>
                    </TabPane>
                    <TabPane tabId="2">
                        <div id="editProjectContainer">
                            <Card>
                                <h4 className='my-1'>Edit Project</h4>
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="client">Client</label><br />
                                            <input type="text" name="client" className="form-control read-only" value={state.client} readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="updatedID" >Project ID</label><br />
                                            <input type="text" name="updatedID" className="form-control read-only" value={state.updatedID} onChange={handleChange} readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="updatedName" >Project Name</label><br />
                                            <input type="text" name="updatedName" className="form-control read-only" value={state.updatedName} onChange={handleChange} readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="startDate">Start Date</label><br />
                                            <input type='date' name="startDate" className="form-control read-only" value={state.startDate} onChange={handleChange} readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="endDate">End Date<span className={classes.red}>*</span></label><br />
                                            <input type='date' name="endDate" className="form-control" value={state.endDate} onChange={handleChange} min={minDate2} required />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="status" >Project status<span className={classes.red}>*</span></label><br />
                                            <select name="status" className="form-control form-select" value={state.status} onChange={handleChange} required>
                                                <option disabled hidden></option>
                                                <option value="Open">Open</option>
                                                <option value="Closed">Closed</option>
                                            </select>
                                        </div>
                                       
                                        <div className="col-md-6">
                                            <label htmlFor="updatedPOC">Point Of Contact<span className={classes.red}>*</span></label><br />
                                            <select name="updatedPOC" value={state.updatedPOC} onChange={handleChange} required className="form-control form-select" >
                                                <option disabled hidden></option>
                                                {
                                                    employees?.filter((el) => el.STATUS === "Active").map((emp) => {
                                                        return <option key={emp.EMP_CODE} value={emp.EMP_WORK_EMAIL}>{emp.EMP_NAME}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="projTeam">Team Members<span className={classes.red}>*</span></label><br />
                                            <Select
                                                ref={(ref) => {
                                                    selectRef2 = ref;
                                                }}
                                                name="projTeam"
                                                isMulti='true'
                                                options={empOptions}
                                                getOptionLabel={(empOptions) => empOptions.label}
                                                getOptionValue={(empOptions) => empOptions.value}
                                                required
                                                value={tags}
                                                onChange={(e) => setTags(e)}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="updatedDesc">Description<span className={classes.red}>*</span>  </label><br />
                                            <textarea name="updatedDesc" className="textarea1" value={state.updatedDesc} onChange={handleChange} required></textarea>
                                        </div>
                                        <br />
                                        <div>
                                            <div style={{ display: "inline-block", marginRight: "10px" }}>
                                                <CDBBtn color="dark" flat className="CDBBtn" onClick={updateProjectDetails} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                                                    Update Project
                                                </CDBBtn>
                                            </div>
                                            <div style={{ display: "inline-block" }}>
                                                <CDBBtn color="dark" flat className="CDBBtn" onClick={clearData}>
                                                    Reset
                                                </CDBBtn>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </Card>
                        </div>
                    </TabPane>
                </TabContent>
            </Card>
            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalBody>
                    <div>
                        <h4>Add New Client</h4>
                        <form>
                            <div className='spacing' style={{ marginTop: 20 }}>
                                <div>
                                    <label htmlFor="clientID">Client ID<span className={classes.mandate}>*</span></label><br />
                                    <input type="text" name="clientID" placeholder='Enter Client ID' className={classes.modalInput} value={state.clientID} onChange={handleChange} required />
                                </div>
                                <div>
                                    <label htmlFor="newClientName" >Client Name<span className={classes.mandate}>*</span></label><br />
                                    <input type="text" name="newClientName" placeholder='Enter Client Name' className={classes.modalInput} value={state.newClientName} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className='spacing'>
                                <div>
                                    <label htmlFor="startdate" >Start Date<span className={classes.mandate}>*</span></label><br />
                                    <input type="date" name="startdate" className={classes.modalInput} value={state.startdate} onChange={handleChange} required />
                                </div>
                                <div>
                                    <label htmlFor="clientGeo">Geo</label><br />
                                    <input type="text" name="clientGeo" placeholder='Client Geo' className={classes.modalInput} value={state.clientGeo} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className='spacing'>
                                <div>
                                    <label htmlFor="clientContact">POC</label><br />
                                    <input type="text" name="clientContact" placeholder='Enter POC' className={classes.modalInput} value={state.clientContact} onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="clientPhone" >Phone</label><br />
                                    <input type="text" name="clientPhone" placeholder='Enter Phone' className={classes.modalInput} value={state.clientPhone} onChange={handleChange} />
                                </div>
                            </div>
                            <div className='spacing'>
                                <div>
                                    <label htmlFor="clientMail" >Email<span className={classes.mandate}>*</span></label>{
                                        emailError !== "Valid" && <span className={classes.errormsg}>{emailError}</span>
                                    } <br />
                                    <input type="email" name="clientMail" placeholder='Enter email' value={state.clientMail} onChange={handleChange} style={{ width: "29rem" }} required />
                                </div>
                            </div>
                            <div className='spacing'>
                                <div>
                                    <label htmlFor="clientParent">Client Parent</label><br />
                                    <input type="text" name="clientParent" placeholder='Client Parent' className={classes.modalInput} value={state.clientParent} onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="clientStatus" >Status<span className={classes.mandate}>*</span></label><br />
                                    <select name="clientStatus" value={state.clientStatus} className={classes.modalInput} onChange={handleChange} required>
                                        <option disabled hidden></option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <CDBBtn color="dark" className="btn btn-dark text-center ml-3" flat style={{ marginRight: "10px", opacity: isDisabled ? '50%' : '100%' }} onClick={addNewClient} disabled={isDisabled}>
                                    Add Client
                                </CDBBtn>
                                <CDBBtn color="dark" className="btn btn-dark text-center ml-3" flat onClick={() => setState(initialState)}>
                                    Reset
                                </CDBBtn>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
            <Card>
                <h4>Projects</h4>
                <input id='searchInput' placeholder='Start typing here ' type='text' className='form-control ' value={filterText} style={{ width: "50%" }} onInput={searchTable} />
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.PROJECT_CODE}
                    rows={filteredRows}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    autoHeight
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    hideFooterSelectedRowCount
                />
            </Card>
            <Footer />
        </div>
    )
}

export default ProjectsTabSA