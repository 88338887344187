import React, { useState, useEffect } from 'react'
import "../css/RequestStatus.css"
import "../css/commonStyles.css"
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import Card from "../components/Card"
import { toast } from 'react-toastify';
import { Modal } from 'reactstrap';
import { selectUser } from "../stores/userSlice";
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    root: {
        "& .modal-content": {
            backgroundColor: "#ffffff05",
            border: "none"
        },
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        }
    },
    green: {
        color: '#00ab55',
        fontWeight: 'bold'
    },
    red: {
        color: '#fe6383',
        fontWeight: 'bold'
    },
    container: {
        padding: '2%'
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    },
    modalContent: {
        position: 'absolute',
        width: 400,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    modal: {
        marginTop: "45vh"
    },
});

function RequestStatus(props) {
    const user = useSelector(selectUser)
    const classes = useStyles();
    const [rows, setRows] = useState([])
    const [wfhRows, setWfhRows] = useState([]);
    const [columns, setColumns] = useState([])
    const [wfhColumns, setWfhColumns] = useState([])
    const [loading, setLoading] = useState(false);

    const getRequestStatus = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/leave/requestStatus`)
            // To add serial number
            // let modifiedData = data.map((el,index)=>{
            //     el.id=index+1;
            //     return el;
            // })
            // console.log(data,"--------------");
            
            // var requestData = data.filter((ele, index) => index < 5);
            setRows(data)
            let leaveTableCols = [{
                headerName: "Leave Type",
                field: "LEAVE_TYPE",
                sortable: true,
                width: 180
            }, {
                headerName: "Start Date",
                field: "START_DATE",
                sortable: true,
                width: 120,
                valueGetter: (params) => {
                    return moment(params.row?.START_DATE).format("DD-MMM-YYYY");
                }
            }, {
                headerName: "End Date",
                field: "END_DATE",
                sortable: true,
                width: 120,
                valueGetter: (params) => {
                    return moment(params.row?.END_DATE).format("DD-MMM-YYYY");
                }
            }, {
                headerName: "#Days",
                field: "NO_OF_DAYS",
                sortable: true,
                width: 80
            }, {
                headerName: "Reason",
                field: "LEAVE_REASON",
                sortable: true,
                width: 200
            }, {
                headerName: "Status",
                field: "LEAVE_STATUS",
                sortable: true,
                width: 100,
                renderCell: (params) => (
                    <span className={params?.row?.LEAVE_STATUS === "Accepted" ? classes.green : classes.red}>{params?.row?.LEAVE_STATUS || "Pending"}</span>
                )
            }, {
                headerName: "Action",
                field: "Action",
                sortable: false,
                width: 100,
                filterable: false,
                renderCell: (params) => {
                    return <>

                        {
                            params?.row?.LEAVE_STATUS !== "Accepted" && <Tooltip title="Edit">
                                <i className="fa fa-edit" aria-hidden="true" style={{ color: "#fe6383", marginRight: '10px', cursor: "pointer" }} onClick={() => props.editLeaveReq(params.row)}></i>
                            </Tooltip>
                        }
                        <span >
                            {params?.row?.REQUEST_TYPE !== 1 ?
                                params?.row?.DELETE_STATUS == "Undo" || params?.row?.DELETE_STATUS == "Reject" ?
                                    <i class="fa fa-ban" aria-hidden="true"></i> :
                                    <Tooltip title="Delete">
                                        <i className="fa fa-trash " aria-hidden="true" style={{ color: "rgb(79 159 207)", cursor: "pointer" }} onClick={() => handleLeaveDelete(params.row)} ></i>
                                    </Tooltip>
                                : <Tooltip title="Undo">
                                    <i className="fa fa-undo " aria-hidden="true" style={{ color: "rgb(79 159 207)", cursor: "pointer" }} onClick={() => handelDeleteLeaveUndo(params.row)}  ></i>
                                </Tooltip>
                            }

                        </span>
                    </>
                }
            }, {
                headerName: "Raised By",
                field: "REQUEST_RAISED_BY",
                sortable: true,
                width: 150,
                renderCell: (params) => (
                    <span>{params?.row?.REQUEST_RAISED_BY || 'Self'}</span>
                )
            }]
            setColumns(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }

    const wfhRequests = async () => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/leave/prevWFHRequests`, {
                empcode: user?.emp_code
            })
            let modifiedData = data.filter((el) => {
                return el.STATUS === "Approved"
            })
            let currentDate = new Date();
            let pending = modifiedData.filter(ele=>new Date(ele.START_DATE).getMonth() ==currentDate.getMonth()).map(days => days?.NO_OF_DAYS).reduce((acc, days) => acc + days, 0)
            setWfhRows(data)
            let wfhTableCols = [{
                headerName: "Start Date",
                field: "START_DATE",
                sortable: true,
                width: 180,
                valueGetter: (params) => {
                    return moment(params.row?.START_DATE).format("DD-MMM-YYYY");
                }
            }, {
                headerName: "End Date",
                field: "END_DATE",
                sortable: true,
                width: 180,
                valueGetter: (params) => {
                    return moment(params.row?.END_DATE).format("DD-MMM-YYYY");
                }
            }, {
                headerName: "#Days",
                field: "NO_OF_DAYS",
                sortable: true,
                width: 120
            },{
                headerName: "Reason",
                field: "REASON",
                sortable: true,
                width: 350
            }, {
                headerName: "Status",
                field: "STATUS",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span className={params?.row?.STATUS === "Approved" ? classes.green : classes.red}>{params?.row?.STATUS || "Pending"}</span>
                )
            },{
                headerName: "Action",
                field: "Action",
                sortable: false,
                width: 100,
                filterable: false,
                renderCell: (params) => {
                    return <>
                        <span >
                            {params?.row?.REQUEST_TYPE !== 1 ?
                                params?.row?.DELETE_STATUS == "Undo" || params?.row?.DELETE_STATUS == "Reject" ?
                                    <i class="fa fa-ban" aria-hidden="true"></i> :
                                    <Tooltip title="Delete">
                                        <i className="fa fa-trash " aria-hidden="true" style={{ color: "rgb(79 159 207)", cursor: "pointer" }} onClick={() => handleWFHDelete(params.row)} ></i>
                                    </Tooltip>
                                : <Tooltip title="Undo">
                                    <i className="fa fa-undo " aria-hidden="true" style={{ color: "rgb(79 159 207)", cursor: "pointer" }} onClick={() => handelDeleteWFHUndo(params.row)}  ></i>
                                </Tooltip>
                            }

                        </span>
                    </>
                }
            }]
            setWfhColumns(wfhTableCols)
        } catch (error) {
            console.log(error)
        }
    }
    const handleLeaveDelete = async (row) => {
        setLoading(true);
        if (new Date(row.START_DATE) > new Date()) {
            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/leave/deleteLeave`, { data: { data: row } })
                toast.success("Leave Deleted Successfully");
                getRequestStatus();
            } catch (error) {
                toast.error("Something went wrong. Please try again later")
            }
        } else {
            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/leave/rejectDeleteLeave`, { REQUEST_ID: row.REQUEST_ID, STATUS: "Request" })
                toast.success("Submitted For Manager Approval");
                getRequestStatus();
            } catch (error) {
                toast.error("Something went wrong. Please try again later")
            }
        }
        setLoading(false);
    }

    const handleWFHDelete = async (row) => {
        setLoading(true);
        if (new Date(row.START_DATE) > new Date()) {
            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/leave/deleteWfh`, { data: { data: row } })
                toast.success("WFH Deleted Successfully");
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            } catch (error) {
                toast.error("Something went wrong. Please try again later")
            }
        } else {
            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/leave/rejectDeleteWfh`, { REQUEST_ID: row.REQUEST_ID, STATUS: "Request" })
                toast.success("Submitted For Manager Approval");
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            } catch (error) {
                toast.error("Something went wrong. Please try again later")
            }
        }
        setLoading(false);
    }

    const handelDeleteLeaveUndo = async (row) => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/leave/rejectDeleteLeave`, { REQUEST_ID: row.REQUEST_ID, STATUS: "Undo" })
            toast.success("Leave cancellation request revoked");
            getRequestStatus();
        } catch (error) {
            toast.error("Something went wrong. Please try again later")
        }
    }

    const handelDeleteWFHUndo = async (row) => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/leave/rejectDeleteWfh`, { REQUEST_ID: row.REQUEST_ID, STATUS: "Undo" })
            toast.success("WFH cancellation request revoked");
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        } catch (error) {
            toast.error("Something went wrong. Please try again later")
        }
    }

    useEffect(() => {
        getRequestStatus();
        wfhRequests();
    }, [])

    return (
        <div className={classes.container}>
            <Card>
                <h4>Leave Request Status</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.START_DATE + row.END_DATE}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    // pageSizeOptions={[5, 10]}
                    hideFooterSelectedRowCount
                />
            </Card>
            <Card>
                <h4>Working From Home Status</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={wfhRow => wfhRow.EMP_CODE + wfhRow.START_DATE + wfhRow.END_DATE}
                    rows={wfhRows}
                    columns={wfhColumns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    // pageSizeOptions={[5, 10]}
                    hideFooterSelectedRowCount
                />
            </Card>
            
            <Modal isOpen={loading} className={classes.modal}>
                {/* <ModalBody className={classes.modalBody}> */}
                <div className={classes.modalContent}>
                    <div className='d-flex justify-content-center h-100 align-items-center'>
                        <div className="spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                {/* </ModalBody> */}
            </Modal>
        </div>

    )
}

export default RequestStatus