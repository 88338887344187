import React, { useState } from "react";
import NameLogo from '../images/NameLogo.png'
import '../css/Dailystatus.css'
import {
  TabContent, TabPane, Nav,
  NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import Compose from "./Compose";
import SentItems from "./SentItems";
import DsrArchieve from "./DsrArchieve";
import TimeSheet from "./TimeSheet";
import Footer from "./Footer";

export const DailyStatus = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const [task, setTask] = useState({})
  const [otherTaskHours,setOtherTaskHours]=useState(0);
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  const updateTask = (data,otherHours) => {
    toggle('1');
    setTask(data)
    setOtherTaskHours(otherHours);
  }

  return (
    <div className="d-flex">
      <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
        <div style={{ height: "100%" }}>
          <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '1'
                    })}
                    onClick={() => { toggle('1'); }}
                    style={{ cursor: "pointer" }}
                  >
                    Compose
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '2'
                    })}
                    onClick={() => { toggle('2'); }}
                    style={{ cursor: "pointer" }}
                  >
                    Sent items
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '3'
                    })}
                    onClick={() => { toggle('3'); }}
                    style={{ cursor: "pointer" }}
                  >
                    Dsr Archive
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        currentActiveTab === '4'
                    })}
                    onClick={() => { toggle('4'); }}
                    style={{ cursor: "pointer" }}
                  >
                    My Time sheet
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab} >
                <TabPane tabId='1'>
                  <Compose task={task} otherTaskHours={otherTaskHours}/>
                </TabPane>
                <TabPane tabId='2'>
                  <SentItems updateTask={updateTask}/>
                </TabPane>
                <TabPane tabId='3'>
                   <DsrArchieve />
                </TabPane>
                <TabPane tabId='4'>                 
                  <TimeSheet />
                </TabPane>
              </TabContent>
            </div>
            <Footer></Footer>
        </div> 
        
        <Footer></Footer>
      </div>
      
    </div>
    
  );
};


