import React, { useState, useEffect } from 'react'
import Card from "../components/Card"
import { CDBBtn } from 'cdbreact'
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import img from '../../src/images/DAFullLogo.png';
import footerimg from '../../src/images/NameLogo.png'
import OfferLetter from './OfferLetter';
import { toast } from 'react-toastify';
import InternshipCompletionLetter from './InternshipCompletionLetter';
import InternOfferLetter from './InternOfferLetter';
import AddressProofLetter from './AddressProofLetter';
import ExitLetter from './ExitLetter';
const printHandler = (content,pdfName) => {
    const printElement = ReactDOMServer.renderToString(content);
    // const printElement = pdfJSX();
    const opt = {
        margin: [30, 10, 30, 10],
        filename: `${pdfName}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        html2canvas: { dpi: 75, scale: 2, letterRendering: true },
        // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        pagebreak: { before: '.pagebreakneed', avoid: ['img', 'table' ,'p','tr','th'] }
    };
    const pageBreakElements = document.getElementsByClassName('page-break');
    for (let i = 0; i < pageBreakElements.length; i++) {
        pageBreakElements[i].style.pageBreakBefore = 'always';
    }
    html2pdf().from(printElement).set(opt).toPdf().get('pdf').then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            // set footer to every page
            pdf.setPage(i);
            // set footer font
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
            pdf.text(78,
                pdf.internal.pageSize.getHeight() - 20, 'D-ACES INDIA PRIVATE LIMITED');
            pdf.text(23,
                pdf.internal.pageSize.getHeight() - 15, 'Gateway Office Parks - Block B6 - First Floor | 16 GST Road | Perungalathur Chennai 600063 | INDIA');
            // you can add the line separator as an image, consult the docs below to properly set the place of the image
            pdf.addImage(img, 'png', 80, 5, 45, 13)
            pdf.addImage(footerimg, 'png', 90, pdf.internal.pageSize.getHeight() - 30, 32, 5)
        }
    }).save();
}

const initialoffer = {
    Name: "",
    valid: "",
    designation: "",
    ctc: "",
    address1: "",
    address2: "",
    address3: "",
    mail: "",
    number: "",
    basic: "",
    hra: "",
    conveyance: "",
    doj: "",
    medical: "",
    sp: "",
    pf: "",
    graduity: "",
    bonus: "",
    total: ""
}

const initialIntern = {
    Name: "",
    rollNo: "",
    degree: "",
    depart: "",
    collegename: "",
    startdate: "",
    enddate: "",
}

const initialInternOff = {
    Name: "",
    valid:"",
    collegename: "",
    startdate: "",
    enddate: "",
}

const initialaddress = {
    Name: "",
    id:"",
    designation: "",
    doj: "",
    address1: "",
    address2: "",
    address3: "",
}

const initialExit = {
    Name: "",
    id:"",
    designation: "",
    doj: "",
    exit:"",
    last:""
}
function LetterGeneration() {
    const [isDisabled, setIsDisabled] = useState(false)
    const [letterType, setLetterType] = useState('')
    const letterTypeSet = (e) => {
        e.preventDefault()
        setLetterType(e.target.value)
    }
    const [offer, setOffer] = useState(initialoffer)
    function handleChangeOffer(evt) {
        const value = evt.target.value;
        setOffer({
            ...offer,
            [evt.target.name]: value
        });
    }

    const [Intern, setIntern] = useState(initialIntern)
    function handleChangeIntern(evt) {
        const value = evt.target.value;
        setIntern({
            ...Intern,
            [evt.target.name]: value
        });
    }

    const [InternOff, setInternOff] = useState(initialInternOff)
    function handleChangeInternOff(evt) {
        const value = evt.target.value;
        setInternOff({
            ...InternOff,
            [evt.target.name]: value
        });
    }

    const [address, setAddress] = useState(initialaddress)
    function handleChangeAddress(evt) {
        const value = evt.target.value;
        setAddress({
            ...address,
            [evt.target.name]: value
        });
    }

    const [exit, setExit] = useState(initialExit)
    function handleChangeExit(evt) {
        const value = evt.target.value;
        setExit({
            ...exit,
            [evt.target.name]: value
        });
    }
    function generateOffer() {
        if (offer.Name && offer.valid && offer.designation && offer.ctc && offer.address1 && offer.address2 && offer.address3 && offer.mail && offer.number && offer.basic && offer.hra && offer.conveyance && offer.medical && offer.sp && offer.pf && offer.graduity && offer.bonus && offer.total) {
            printHandler(<OfferLetter data={offer} />,"Offer-Letter")
            cleanup();
        } else toast.error("All fields are mandatory to create letter")
        setIsDisabled(false)
    }


    function generateIntern() {
        if (Intern.Name && Intern.rollNo && Intern.degree && Intern.depart && Intern.collegename && Intern.startdate && Intern.enddate) {
            printHandler(<InternshipCompletionLetter data={Intern} />,"Internship-Comletion-Letter")
            cleanup();
        } else toast.error("All fields are mandatory to create letter")

        setIsDisabled(false)
        
    }

    function generateInternOff() {
        if (InternOff.Name  && InternOff.collegename && InternOff.startdate && InternOff.enddate) {
            printHandler(<InternOfferLetter data={InternOff} />,"Intern-Offer-Letter")
            cleanup();
        } else toast.error("All fields are mandatory to create letter")

        setIsDisabled(false)
        
    }

    function generateAddress() {
        if (address.Name && address.id && address.designation && address.doj && address.address1 && address.address3 &&address.address3) {
            printHandler(<AddressProofLetter data={address} />,"Address-proof-Letter")
            cleanup();
        } else toast.error("All fields are mandatory to create letter")

        setIsDisabled(false)
        
    }

    function generateExit() {
        if (exit.Name && exit.id && exit.designation && exit.doj && exit.exit && exit.last) {
            printHandler(<ExitLetter data={exit} />,"Exit-Letter")
            cleanup();
        } else toast.error("All fields are mandatory to create letter")
     
        setIsDisabled(false)
    }
    function handleGenerate() {
        setIsDisabled(true)
        if (letterType == "offerLetter") {
            generateOffer()
        }else if(letterType == "internLetter"){
            generateIntern()
        }else if(letterType == "internOfferLetter"){
            generateInternOff();
        }else if(letterType == "addressProofLetter"){
            generateAddress()
        }else if(letterType == "exitLetter"){
            generateExit()
        }
    }

    function cleanup(){
        setOffer(initialoffer);
        setIntern(initialIntern);
        setInternOff(initialInternOff);
        setAddress(initialaddress);
        setExit(initialExit);
    }
    return (
        <div className="container" >
            <Card>
                <h4>Letter Generation</h4>
                <form>
                    <div className="mt-2">
                        <div className='col-sm-4'>
                            <label htmlFor='timesheetFilter'>Select Letter Type<span style={{ color: 'red' }}>*</span></label><br />
                            <select name='timesheetFilter' className="form-control form-select" onChange={letterTypeSet} required>
                                <option value='' >Please select</option>
                                <option value="offerLetter">Offer Letter</option>
                                <option value="exitLetter">Exit Letter</option>
                                <option value="internLetter">Internship Completion Letter</option>
                                <option value="internOfferLetter">Internship Offer Letter</option>
                                <option value="addressProofLetter">Address Proof Letter</option>
                            </select>
                        </div><br />


                        {letterType == 'offerLetter'
                            ? <div>
                                <div className='row'>
                                    <div className='col-3'>
                                        <label>Employee Name<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.Name} onChange={handleChangeOffer} name='Name' type='text'className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Offer Valid Till<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.valid} onChange={handleChangeOffer} name='valid' type='date' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Date Of Joining<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.doj} onChange={handleChangeOffer} name='doj' type='date' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Designation<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.designation} onChange={handleChangeOffer} name='designation' type='text' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Offered CTC<span style={{ color: 'red' }}>*</span></label><br />
                                        <input type='text' onChange={handleChangeOffer} value={offer.ctc} name='ctc' className="form-control" />
                                    </div>
                               
                                    <div className='col-3'>
                                        <label>Address Line 1<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.address1} name='address1' onChange={handleChangeOffer} type='text' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Address Line 2<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.address2} name='address2' onChange={handleChangeOffer} type='text' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Address Line 3<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.address3} name='address3' onChange={handleChangeOffer} type='text' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Mail ID<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.mail} name='mail' onChange={handleChangeOffer} type='mail'className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Phone Number<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.number} name='number' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                              
                                    <div className='col-3'>
                                        <label>Basic<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.basic} name='basic' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>HRA<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.hra} name='hra' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'> 
                                        <label>Conveyance<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.conveyance} name='conveyance' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Medical<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.medical} name='medical' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Special Allowance<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.sp} name='sp' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>PF<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.pf} name='pf' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Graduity<span style={{ color: 'red' }}>*</span></label><br />
                                        <input value={offer.graduity} name='graduity' onChange={handleChangeOffer} type='number' className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Performance Bonus<span style={{ color: 'red' }}>*</span></label><br />
                                        <input type='number' value={offer.bonus} name='bonus' onChange={handleChangeOffer} className="form-control" />
                                    </div>
                                    <div className='col-3'>
                                        <label>Total<span style={{ color: 'red' }}>*</span></label><br />
                                        <input type='number' value={offer.total} name='total' onChange={handleChangeOffer} className="form-control" />
                                    </div>
                                </div>

                            </div>
                            : letterType == 'internLetter'
                                ? <div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label>Intern Name<span style={{ color: 'red' }}>*</span></label><br />
                                            <input value={Intern.Name} onChange={handleChangeIntern} name='Name' type='text'className="form-control" />
                                        </div>
                                        <div  className='col-4'>
                                            <label>Roll No<span style={{ color: 'red' }}>*</span></label><br />
                                            <input type='number' value={Intern.rollNo} onChange={handleChangeIntern} name='rollNo' className="form-control" />
                                        </div>
                                        <div  className='col-4'>
                                            <label>Degree<span style={{ color: 'red' }}>*</span></label><br />
                                            <select className="form-control" value={Intern.degree} onChange={handleChangeIntern} name='degree'>
                                                <option value=''>Please select</option>
                                                <option value='B.Sc'>B.Sc</option>
                                                <option value='B.E'>B.E</option>
                                                <option value='B.Tech'>B.Tech</option>
                                            </select>
                                        </div>
                                        <div  className='col-4'>
                                            <label>Department<span style={{ color: 'red' }}>*</span></label><br />
                                            <input value={Intern.depart} onChange={handleChangeIntern} name='depart' type='text' className="form-control" />
                                        </div>
                        
                                        <div  className='col-4'>
                                            <label>College Name<span style={{ color: 'red' }}>*</span></label><br />
                                            <input value={Intern.collegename} onChange={handleChangeIntern} name='collegename' type='text'className="form-control" />
                                        </div>
                                        <div  className='col-4'>
                                            <label>Start Date<span style={{ color: 'red' }}>*</span></label><br />
                                            <input value={Intern.startdate} onChange={handleChangeIntern} name='startdate' type='date' className="form-control" />
                                        </div>
                                        <div  className='col-4'>
                                            <label>End Date<span style={{ color: 'red' }}>*</span></label><br />
                                            <input type='date' value={Intern.enddate} onChange={handleChangeIntern} name='enddate' className="form-control" />
                                        </div>

                                    </div>
                                </div>
                                : letterType == 'exitLetter'
                                    ? <div>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <label>Employee Name<span style={{ color: 'red' }}>*</span></label><br />
                                                <input type='text' value={exit.Name} onChange={handleChangeExit} name='Name'className="form-control" />
                                            </div>
                                            <div className='col-4'>
                                                <label>Employee ID<span style={{ color: 'red' }}>*</span></label><br />
                                                <input type='text' value={exit.id} onChange={handleChangeExit} name='id' className="form-control" />
                                            </div>
                                            <div className='col-4'>
                                                <label>Designation<span style={{ color: 'red' }}>*</span></label><br />
                                                <input  value={exit.designation} onChange={handleChangeExit} name='designation' type='text'className="form-control" />
                                            </div>
                                            <div className='col-4'>
                                                <label>Date of Joining<span style={{ color: 'red' }}>*</span></label><br />
                                                <input  value={exit.doj} onChange={handleChangeExit} name='doj' type='date' className="form-control" />
                                            </div>
                                            <div className='col-4'>
                                                <label>Resignation Date<span style={{ color: 'red' }}>*</span></label><br />
                                                <input  value={exit.exit} onChange={handleChangeExit} name='exit' type='date' className="form-control" />
                                            </div>
                                            <div className='col-4'>
                                                <label>Last Working Date<span style={{ color: 'red' }}>*</span></label><br />
                                                <input  value={exit.last} onChange={handleChangeExit} name='last' type='date' className="form-control" />
                                            </div>

                                        </div>
                                    </div>
                                    : letterType == 'addressProofLetter'
                                        ? <div>
                                            <div className='row'>
                                                <div className='col-3'>
                                                    <label>Employee Name<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input value={address.Name} onChange={handleChangeAddress} name='Name' type='text'className="form-control" />
                                                </div>
                                                <div className='col-3'>
                                                    <label>Employee ID<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input  value={address.id} onChange={handleChangeAddress} name='id' type='text' className="form-control" />
                                                </div>
                                                <div className='col-3'>
                                                    <label>Designation<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input  value={address.designation} onChange={handleChangeAddress} name='designation' type='text'className="form-control" />
                                                </div>
                                                <div className='col-3'> 
                                                    <label>Date of Joining<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input type='date'  value={address.doj} onChange={handleChangeAddress} name='doj' className="form-control" />
                                                </div>
                                                <div className='col-3'>
                                                    <label>Address Line 1<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input type='text'  value={address.address1} onChange={handleChangeAddress} name='address1'className="form-control" />
                                                </div>
                                                <div className='col-3'>
                                                    <label>Address Line 2<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input type='text'  value={address.address2} onChange={handleChangeAddress} name='address2'className="form-control" />
                                                </div>
                                                <div className='col-3'>
                                                    <label>Address Line 3<span style={{ color: 'red' }}>*</span></label><br />
                                                    <input type='text'  value={address.address3} onChange={handleChangeAddress} name='address3'className="form-control" />
                                                </div>


                                            </div>
                                        </div>
                                        : letterType == 'internOfferLetter'
                                            ? <div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label>Intern Name<span style={{ color: 'red' }}>*</span></label><br />
                                                        <input value={InternOff.Name} onChange={handleChangeInternOff} name='Name' type='text'className="form-control" />
                                                    </div>
                                                    <div  className='col-4'>
                                                        <label>Internship Start Date<span style={{ color: 'red' }}>*</span></label><br />
                                                        <input type='date' value={InternOff.startdate} onChange={handleChangeInternOff} name='startdate' className="form-control" />
                                                    </div>
                                                    <div  className='col-4'>
                                                        <label>Internship End Date<span style={{ color: 'red' }}>*</span></label><br />
                                                        <input type='date' value={InternOff.enddate} onChange={handleChangeInternOff} name='enddate' className="form-control" />
                                                    </div>
                                                    {/* <div >
                                                        <label>Offer Valid Till<span style={{ color: 'red' }}>*</span></label><br />
                                                        <input type='date' value={InternOff.valid} onChange={handleChangeInternOff} name='valid' className="form-control" />
                                                    </div> */}

                                                    <div  className='col-4'>
                                                        <label>College Name<span style={{ color: 'red' }}>*</span></label><br />
                                                        <input type='text' value={InternOff.collegename} onChange={handleChangeInternOff} name='collegename'className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            : <div></div>
                        }
                        <div>
                            <div style={{ float: "right" }} className='mt-4'>
                                <CDBBtn color="dark" flat className="CDBBtn" onClick={() => handleGenerate()} style={{ opacity: isDisabled ? '50%' : '100%' }} disabled={isDisabled}>
                                    Generate Letter
                                </CDBBtn>
                            </div>
                        </div>


                    </div>

                </form>


            </Card>

        </div>
    )
}

export default LetterGeneration