import React, { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar"
import Sidebar from "../Sidebar"
import { fetchUserAction } from "src/stores/userSlice";
import { fetchAllEmployees } from "src/stores/employeeSlice";
import { fetchProjects } from "src/stores/projectSlice";
import { useDispatch } from "react-redux"
export default function DashboardLayout() {
    const nav = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUserAction())
        dispatch(fetchAllEmployees())
        dispatch(fetchProjects())
    }, [dispatch])
    const location = useLocation();
    const [styleNav, setStyleNav] = useState({ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh" })
    useEffect(() => {
        if (location.pathname == "/super-admin" || location.pathname == "/admin" || location.pathname == "/hr-panel") {
            setStyleNav({...styleNav,overflowY:"hidden"});
        }else{
            setStyleNav({...styleNav,overflowY:"clip"});
        }

        if(location.pathname =="/"){
            nav("/dashboard")
        }
    }, [location.pathname])
    return (
        <div className="d-flex">
            <div>
                <Sidebar />
            </div>
            <div style={styleNav}>
                <Navbar />
                <div style={{ height: "100%" }}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
