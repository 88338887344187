import React from 'react'
import Card from 'src/components/Card'
import Sign from './../images/sign.png';


function formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const formattedDate = `${day}  ${months[monthIndex]}, ${year}`;
    return formattedDate;
  }
  const today = new Date();
function AddressProofLetter(props) {
    return (
        <div style={{ height: "calc(100% - 64px)", overflowY: 'scroll' }}>
            <div className='container'>
                <Card>
                    <p style={{ float: 'right' }}>{formatDate(today)}</p><br /><br />
                    <p style={{ textAlign: 'center' }}>TO WHOMSOEVER IT MAY CONCERN</p><br /><br />
                    <p>This is to certify that {props?.data?.Name}. Emp ID {props?.data?.id} is working with D-Aces India Private Limited Since {formatDate(new Date(props?.data?.doj))}.</p><br />
                    <p>Designation&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;{props?.data?.designation} </p><br />
                    <p>As per our records, his current address is given below.</p><br />
                    <p>Current address:</p>
                    <p>{props?.data?.address1}<br />{props?.data?.address2}<br />{props?.data?.address3}</p><br /><br />
                    <p><strong>Yours Faithfully</strong><br/>
                        For D-Aces India Private Limited
                    </p>
                    <img src={Sign}/>
                    <p>Venkata Rangarajan <br/> Director</p>
                </Card>
            </div>
        </div>
    )
}

export default AddressProofLetter