import React, {useState, useEffect} from "react";
import { CDBView } from "cdbreact";
import axios from "axios"
import NameLogo from '../images/NameLogo.png'
import team1 from '../images/team1.jpeg'
import team2 from '../images/team2.jpeg'
import team3 from '../images/team3.jpeg'
import team4 from '../images/team4.jpeg'
import team5 from '../images/team5.jpeg'
import team6 from '../images/team6.png'
import "../css/OurTeam.css"
import { CDBCarousel, CDBCarouselItem, CDBCarouselInner, CDBContainer } from 'cdbreact';


export const OurTeam = () => {
	const [team, setTeam] = useState([])

	const getTeamMembers = async () => {
		try{
			const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/ourTeam`)
			setTeam(data)
		}catch(error){			
			let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
		}
	}

	useEffect(()=>{
		getTeamMembers();
	},[])

	return (
		<div className="d-flex profile">			
			<div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>				
				<div style={{ height: "100%" }}>
					<div style={{ height: "calc(100% - 64px)", padding: "20px 4%", overflowY: "scroll" }}>
						<h2>OurTeam</h2>
						<div style={{ margin: "auto" }}>
							<div className="container" style={{ padding: "10px 0.1px 10px 0.1px ", boxShadow: "0 0 5px 2px #aaafb3", height: "415px" }}>
								<CDBContainer>
									<CDBContainer>
										<CDBCarousel
											activeItem={1}
											length={6}
											showControls={true}
											showIndicators={true}
											className="z-depth-1"
											slide
											interval={"2000"}
										>
											<CDBCarouselInner>
												<CDBCarouselItem itemId="1">
													<CDBView>
														<img className="d-block w-100" height="380px" style={{ objectFit: "cover" }} src={team1} alt="First slide" />
													</CDBView>
												</CDBCarouselItem>
												<CDBCarouselItem itemId="2">
													<CDBView>
														<img className="d-block w-100" height="380px"  src={team2} alt="Second slide" />
													</CDBView>
												</CDBCarouselItem>
												<CDBCarouselItem itemId="3">
													<CDBView>
														<img className="d-block w-100" height="380px" style={{ objectFit: "cover" }} src={team3} alt="Second slide" />
													</CDBView>
												</CDBCarouselItem>
												<CDBCarouselItem itemId="4">
													<CDBView>
														<img className="d-block w-100" height="380px" style={{ objectFit: "cover" }} src={team4} alt="Second slide" />
													</CDBView>
												</CDBCarouselItem>
												<CDBCarouselItem itemId="5">
													<CDBView>
														<img className="d-block w-100" height="380px" style={{ objectFit: "cover" }} src={team5} alt="Third slide" />
													</CDBView>
												</CDBCarouselItem>
												<CDBCarouselItem itemId="6">
													<CDBView>
														<img className="d-block w-100" height="380px" style={{ objectFit: "cover" }} src={team6} alt="Third slide" />
													</CDBView>
												</CDBCarouselItem>
											</CDBCarouselInner>
										</CDBCarousel>
									</CDBContainer>
								</CDBContainer>
							</div>
							<div className="container rounded" style={{ marginTop: "20px", boxShadow: "0 0 5px 2px #aaafb3" }}>
								<h4 style={{ padding: "20px 10px 10px 10px" }}>Team Members</h4>
								<div className="row" style={{ padding: "15px" }}>
									{
										team?.map((el)=>{
											return (
											<div className="col-3 rounded" key={el.name} style={{height:"20.5rem"}}>
												<div className="card cssanimation fadeInBottom roinded" > <a href="#" target="_blank" />
													<img 
														src={el?.img_dir ? `${process.env.REACT_APP_PUBLIC_URL}/${el?.img_dir}` :  `${process.env.REACT_APP_PUBLIC_URL}/public/img/utAvatar.png`} 
														className="card-img-top" alt="" width="200px" height="200px" />
													<div className="card-body " >
														<p className="card-title text-center" style={{fontSize:"15px",fontWeight:"600"}}>{el.name}</p>
														<p className="card-title text-center" style={{fontSize:"14px"}}>{el.designation}</p>
													</div>
												</div>
											</div>)
										})
									}
									
								</div>
							</div>
						</div>
					</div>

				</div>
				<footer className="mx-auto my-3 text-center">
					<small className="ml-2 mt-1"> <img src={NameLogo} width="100px" style={{ marginBottom: "5px" }} /> &copy; D-Aces India Private Limited, 2022. All rights reserved.</small>
				</footer>
			</div>
		</div>

	);
}