import React, {useState, useEffect} from "react";
import { CDBBtn } from "cdbreact";
import NameLogo from '../images/NameLogo.png'
import axios from "axios";
import { makeStyles } from '@mui/styles';
import validator from 'validator'
import moment from "moment"
import { toast } from 'react-toastify';
import {useSelector} from "react-redux"
import { selectUser } from 'src/stores/userSlice';
import { Checkbox } from '@mui/material';
import { Modal, ModalBody } from 'reactstrap'
// import { CDBInput, CDBContainer, CDBBox, CDBFileUploader } from 'cdbreact';

const useStyles = makeStyles({      
	readOnlyField:{
		backgroundColor:"#f1f1f1"
	},
	errormsg:{
		color:"red",
		fontSize:"12px",
		marginLeft:"5px",
	},
	red:{
		color:"red",
		fontSize:"12px"
	},
	radio:{
		width: "2rem"
	},
});

export const MyProfile = () => {
	const classes = useStyles();
	const [profile, setProfile] = useState({})
	const [flag, setFlag] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false)
	const [checked, setChecked] = useState(false);
	const [editable, setEditable] = useState(false)
	const [modal, setModal] = useState(false)
	const [emailError, setEmailError] = useState('')
	const user = useSelector(selectUser)
	const [remote, setRemote] = useState("No")
	const initialState= {
		firstName:'',
		lastName:'',
		fatherName:'',
		phone:'',
		bloodGrp:'',
		dob:'',
		addrLine1:'',
		addrLine2:'',
		city:'',
		empstate:'',
		pincode:'',
		email:'',
		contactNo:'',
		aadharNo:'',
		panNo:'',	
		nameAsBank:'',
		bank:'',
		accountNo:'',
		ifsc:'',
		uanPF:'',
		manager:'',
		status:'',
		designation:''
	}
	const [state, setState] = useState(initialState)

	const updateProfile = async  () => {
		if(profile?.ALLOW_EDIT==="No"){
			toast.error("Please reach out to manager to update details")
		}else {		
		if(state.firstName && state.lastName && state.fatherName && state.phone && state.bloodGrp && state.dob && state.addrLine1 && state.addrLine2 && state.city && state.pincode && state.email && state.contactNo && remote && state.aadharNo && state.panNo && state.nameAsBank && state.bank && state.accountNo && state.ifsc && state.uanPF){
			if(checked===true){
				setIsDisabled(true)
				try{
					await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateBio`,{
						empcode:user?.emp_code,
						empName:state.firstName+" "+state.lastName,
						fatherName:state.fatherName,
						phone:state.phone,
						bloodGrp:state.bloodGrp,
						dob:state.dob,
						addrLine1:state.addrLine1,
						addrLine2:state.addrLine2,
						city:state.city,
						pincode: state.pincode,
						location: (profile?.EMP_LOCATION),
						doj:state.doj,
						email:state.email,
						contactNo:state.contactNo,
						aadharNo:state.aadharNo,
						panNo:state.panNo,
						nameAsBank:state.nameAsBank,
						bank:state.bank,
						accountNo:state.accountNo,
						ifsc:state.ifsc,
						uanPF:state.uanPF,
						remote,
						lastUpdatedDt:moment(new Date()).format("YYYY-MM-DD"),
						isManager:user?.is_admin,
					})
					toast.success("Profile updated successfully")	
					setIsDisabled(false)
					setTimeout(()=>{
						window.location.reload()
					},2000)
				}catch(error){				
					setIsDisabled(false)
					let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
					toast.error(errmsg)
				}	
			}else toast.error("Please select the checkbox")	 
		}
		else(toast.error("All fields are mandatory for the profile update"))
		}				
	}

	const modalOpen = () => {
		if(checked===false)
		setModal(true)
	}

	const getMyDetails = async () =>{
		try{
			const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/profile`)
			setProfile(data[0])
			setFlag(false)			
			setRemote(profile?.REMOTE_EMPLOYEE)
			setState({
				firstName:(profile?.EMP_NAME)?.split(' ')[0],
				lastName:(profile?.EMP_NAME)?.substr(profile?.EMP_NAME.indexOf(" ") + 1),
				fatherName:profile?.EMP_FATHERS_NAME,
				phone:profile?.EMP_MOBILE_NO,
				bloodGrp:profile?.EMP_BLOOD_GROUP,
				dob:moment(profile?.EMP_DATE_OF_BIRTH).format("YYYY-MM-DD"),
				doj:moment(profile?.EMP_DATE_OF_JOIN).format("YYYY-MM-DD"),
				addrLine1:profile?.EMP_ADDRESS_1,
				addrLine2:profile?.EMP_ADDRESS_2,
				city:profile?.EMP_CITY,
				empstate:profile?.EMP_STATE,
				pincode:profile?.PINCODE,
				location:profile?.EMP_LOCATION,
				dataAcesMail:profile?.EMP_WORK_EMAIL,
				email:profile?.EMP_PERSONAL_EMAIL,
				contactNo:profile?.EMERGENCY_CONTACT_NO,
				aadharNo:profile?.EMP_AADHAR_NO,
				panNo:profile?.EMP_PAN_NO,	
				nameAsBank:profile?.EMP_NAME_AS_IN_BANK,
				bank:profile?.EMP_BANK_NAME,
				accountNo:profile?.EMP_ACCOUNT_NO,
				ifsc:profile?.EMP_IFSC_NO,
				uanPF:profile?.EMP_PF_NO,
				designation: profile?.DESIGNATION,
				status:profile?.EMP_STATUS,
				manager:profile?.REPORTING_MANAGER_ID,				
			})			
			let editFlag = (profile?.ALLOW_EDIT === "No") ? true : false
			setEditable(editFlag)
			setChecked(editFlag)
		}catch(error){
			console.log(error)
		}
	}

	const handleCheckBoxChange = (event) => {
		setChecked(event.target.checked);
	};

	function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
		if(evt.target.name === "email"){
            if (validator.isEmail(value)) {            
                setEmailError('Valid')
            } else {
                setEmailError('Enter valid Email!')
            }
        } 
		if(evt.target.name === "remote"){
          setRemote(evt.target.value)
        }
    }

	useEffect(()=>{
		getMyDetails();
	},[flag])

	return (
		<div>
			<div className="p-4">
				<div className="d-flex justify-content-between align-items-center mb-3">
					<h4 className="text-right">Profile Settings</h4>
				</div>
				<div className="row mt-2">
					<div className="col-md-6 ">
						<label htmlFor="firstName">First Name<span className={classes.red}>*</span></label>
						<input type="text" className={`form-control`} placeholder="First name" name="firstName" defaultValue={state.firstName||""} onChange={handleChange} readOnly={editable} required/>
					</div>
					<div className="col-md-6">
						<label htmlFor="lastName">Last name<span className={classes.red}>*</span></label>
						<input type="text" className={`form-control`} placeholder="Last name" name="lastName" defaultValue={state.lastName||""} onChange={handleChange} readOnly={editable} required />
					</div>
					<div className="col-md-6">
						<label htmlFor="fatherName">Father's name<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Father's name" name="fatherName" defaultValue={state.fatherName||""} onChange={handleChange} readOnly={editable} required/>
						
					</div>
					<div className="col-md-6">
						<label htmlFor="phone">PhoneNumber<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Phone Number" name="phone"  defaultValue={state.phone||""} onChange={handleChange} readOnly={editable} required/>
					
					</div>											
					<div className="col-md-6">
						<label htmlFor="dob">Date of Birth<span className={classes.red}>*</span></label>
						<input type="date" className="form-control" placeholder="DOB" name="dob"  defaultValue={state.dob||""} onChange={handleChange} readOnly={editable} required  />
						
					</div>
					<div className="col-md-6">
						<label htmlFor="bloodGrp">Blood Group<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Enter Blood group" name="bloodGrp"  defaultValue={state.bloodGrp||""} onChange={handleChange} readOnly={editable} required />						
					</div>															
					<div className="col-md-7">
						<label htmlFor="email">Personal Email ID<span className={classes.red}>*</span></label>{
							emailError!=="Valid" && <span className={classes.errormsg}>{emailError}</span> 
						} <br />
						<input type="email" className="form-control" placeholder="Enter personal email id" name="email" defaultValue={state.email||""} onChange={handleChange} readOnly={editable} required  />						
					</div>	
					<div className="col-md-5">
						<label htmlFor="contactNo">Emergency Contact No.<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" name="contactNo"  defaultValue={state.contactNo||""} onChange={handleChange} readOnly={editable} required />
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<label htmlFor="addrLine1">Address Line 1<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Enter Address line 1" name="addrLine1"  defaultValue={state.addrLine1||""} onChange={handleChange} readOnly={editable} required />
						
					</div>											
					<div className="col-md-6">
						<label htmlFor="addrLine2">Address Line 2<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Enter Address line 2" name="addrLine2"  defaultValue={state.addrLine2||""} onChange={handleChange} readOnly={editable} required />
						
					</div>	
					<div className="col-md-6">
						<label htmlFor="city">City<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" name="city"  defaultValue={state.city||""} onChange={handleChange} readOnly={editable} required />
						
					</div>
					<div className="col-md-6">
						<label htmlFor="empstate">State</label>
						<input type="text" className={`form-control ${classes.readOnlyField}`} name="empstate"  defaultValue={state.empstate} readOnly />
					</div>
					<div className="col-md-4">
						<label htmlFor="pincode">Pin code<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" name="pincode"  defaultValue={state.pincode||""} onChange={handleChange} readOnly={editable} required />
						
					</div>
					<div className="col-md-8">
						<label htmlFor="dataAcesMail">Data Aces Mail</label>
						<input type="text" className={`form-control ${classes.readOnlyField}`} name="dataAcesMail"  defaultValue={state.dataAcesMail} readOnly />
					</div>
					<div className="col-md-12">
						<label htmlFor="remote" style={{marginRight:"2rem"}}>Remote Employee<span className={classes.red}>*</span></label>						
						<span className={classes.marginRight}><input className={classes.radio} type="radio" name="remote" value="Yes" checked={remote==="Yes"} onChange={handleChange} disabled={editable}/> Yes</span>	
						<span className={classes.marginRight}><input className={classes.radio} type="radio" name="remote" value="No" checked={remote==="No"} onChange={handleChange} disabled={editable}/> No</span>					
					</div>										
					<div className="col-md-7">
						<label htmlFor="aadharNo">Aadhar Number<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Aadhar Number" name="aadharNo" defaultValue={state.aadharNo||""} onChange={handleChange} readOnly={editable} required />												
					</div>
					<div className="col-md-5">
						<label htmlFor="panNo">PAN Number<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="PAN Number" name="panNo" defaultValue={state.panNo||""} onChange={handleChange} readOnly={editable} required />												
					</div>
				</div>
				<div className="row mt-3">	
					<div className="col-md-7">
						<label htmlFor="nameAsBank">Name as in Bank<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Name as in bank" name="nameAsBank"  defaultValue={state.nameAsBank||""} onChange={handleChange} readOnly={editable} required />												
					</div>										
					<div className="col-md-5">
						<label htmlFor="bank">Bank<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Enter name of the bank" name="bank"  defaultValue={state.bank||""} onChange={handleChange} readOnly={editable}  required />
						
					</div>
					<div className="col-md-6">
						<label htmlFor="accountNo">Account Number<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="Account number" name="accountNo"  defaultValue={state.accountNo||""} onChange={handleChange} readOnly={editable} required />
						
					</div>	
					<div className="col-md-6">
						<label htmlFor="ifsc">IFS Code<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="IFS Code" name="ifsc"  defaultValue={state.ifsc||""} onChange={handleChange} readOnly={editable} required />
						
					</div>											
					<div className="col-md-12">
						<label htmlFor="uanPF">UAN/PF<span className={classes.red}>*</span></label>
						<input type="text" className="form-control" placeholder="PF Number" name="uanPF"  defaultValue={state.uanPF||""} onChange={handleChange} readOnly={editable} required />
						
					</div>
					<div className="col-md-6">
						<label htmlFor="designation">Designation</label>
						<input type="text" className={`form-control ${classes.readOnlyField}`} placeholder="Designation" name="designation"  defaultValue={state.designation||""} readOnly />
					</div>
					<div className="col-md-6">
						<label htmlFor="status">Status</label>
						<input type="text" className={`form-control ${classes.readOnlyField}`} placeholder="status" name="status"  defaultValue={state.status||""} readOnly />
					</div>
					<div className="col-md-6">
						<label htmlFor="doj">Date of Joining</label>
						<input type="date" className={`form-control ${classes.readOnlyField}`} name="doj"  defaultValue={state.doj||""} readOnly />
					</div>
					<div className="col-md-6">
						<label htmlFor="manager">Reporting Manager</label>
						<input type="text" className={`form-control ${classes.readOnlyField}`} name="manager" defaultValue={state.manager||""} readOnly />											
					</div>
					<div className="col-md-12">
						<Checkbox checked={checked} onChange={handleCheckBoxChange} inputProps={{ 'aria-label': 'controlled' }} onClick={modalOpen} disabled={editable} />
						<label style={{fontSize:15}}>I ensure that all the details are correct to best of my knowledge</label>												
					</div>
																
				</div>
				<div className="mt-5 text-center">
					<CDBBtn className="btn btn-dark rounded" color="dark" onClick={updateProfile} disabled={isDisabled} style={{opacity:isDisabled ? '50%' : '100%'}}>
						Save Profile
					</CDBBtn>
				</div>
			</div>
			<Modal isOpen={modal}>
				<ModalBody>
				<div>
					<p className="align-items-center text-center p-2">
						Profile details can be updated only once.<br/> Make sure that you update correct information
					</p>								
					<div className="d-flex justify-content-center" type="submit" id="AddBtn">
						<CDBBtn color="dark" onClick={()=>setModal(false)}>
						OK
						</CDBBtn>
					</div>
				</div>
				</ModalBody>
			</Modal>			
		</div>
	)
	}


