import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import ApiClient from "../ApiClient"

export const userSlice = createSlice({
    name:"user",
    initialState:{       
        user:null,
    },
    reducers:{
        setUser: (state,action) =>{
            state.user = action.payload;
        },
        logout: (state) =>{
            state.user = null;
        }
           
    }
});

export const fetchUserAction = () => async dispatch => {
    try{
        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/user  `)
        dispatch(userSlice.actions.setUser(data[0]))
    }
    catch(error){
        dispatch(userSlice.actions.setUser(null))
    }
}

export const {setUser, logout} = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;