import React from 'react'
import { useState, useEffect } from 'react';
import { CDBBtn } from 'cdbreact'
import '../css/Dailystatus.css'
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux"
import { selectUser } from "../stores/userSlice";
import { selectEmployees } from 'src/stores/employeeSlice';
import axios from 'axios';
import ms from "ms";
import moment from 'moment';
import { toast } from 'react-toastify';
import ProjectCard from 'src/components/ProjectCard';

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        padding: "8px",
        borderRadius: "2px",
        width: "100%",
        textAlign: "left"
    },
    flexContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    modal: {
        marginTop: "200px"
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    }
});

export default function DsrArchieve() {
    const Kaitongo = process.env.REACT_APP_KAITONGO_MANAGER.split(',');
    const classes = useStyles()
    const employees = useSelector(selectEmployees)
    const initialState = {
        empCode: "",
        startDate: moment(new Date() - ms('1d')).format('YYYY-MM-DD'),
        endDate: moment(new Date() - ms('1d')).format('YYYY-MM-DD')
    }
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [dsr, setDsr] = useState([]);
    const [state, setState] = useState(initialState)
    const user = useSelector(selectUser);
    const [empCode, setEmpCode] = useState("");
    const [KaitongoEmp, setKaitongoEmp] = useState([]);
    useEffect(() => {
        if (user !== null) {
            if (Kaitongo.includes(user.emp_code)) {
                setEmpCode(user.emp_code);
            } else {
                setEmpCode("");
            }
        }
    }, [user])

    useEffect(async () => {
        if (empCode) {
            setKaitongoEmp(employees.filter((ele) => ele.EMP_WORK_EMAIL.indexOf("@kaitongo.com") !== -1)?.map((ele) => ele.EMP_CODE))
        }
    }, [empCode,employees])

    

    const getTimesheet = async (error = "Show") => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/dsr/getAllReports`, {
                code: state.empCode,
                start_date: state.startDate,
                end_date: state.endDate,
            })
            setDsr(data)
            if (data?.length > 0 || error === "notShow") {
                document.getElementById("getreport").style.display = "block";
                //setState(initialState)
            } else toast.error("No matching records found")
        } catch (error) {
            console.log(error)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    const getReports = () => {
        if (state.startDate && state.endDate) {
            if (new Date(state.startDate) <= new Date(state.endDate))
                getTimesheet();
            else toast.error("Start Date should be lesser than End Date")
        } else if ((!state.startDate || !state.endDate)) {
            toast.error("Please select start and End Dates")
        } else getTimesheet();
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const initDateRange = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDate) - minsec);
        const max_date = new Date(new Date());
        setMaxDate(moment(max_date).format('YYYY-MM-DD'))
        setMinDate(moment(min_date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        initDateRange();
    }, [state.startDate]);

    useEffect(() => {
        getTimesheet('notShow');
    }, [])

    const currentUrl = window.location.pathname;

    return (
        <>
            {
                empCode == "" ?
                    <div className="container" >
                        <div className='DSRArchive__div'>
                            <div className='filters'>
                                <div>
                                    <label htmlFor="empCode">Employee Name</label><br />
                                    <select name="empCode" value={state.empCode} onChange={handleChange} required>
                                        <option disabled hidden></option>
                                        {
                                            employees && employees?.filter((el) => el.STATUS === "Active")?.map((emp) => <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>)
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='startDate'>Start Date</label><br />
                                    <input name='startDate' type='date' className="set-date-width" value={state.startDate} onChange={handleChange} max={maxDate} />
                                </div>
                                <div>
                                    <label htmlFor='endDate'>End date </label><br />
                                    <input name='endDate' type='date' className="set-date-width" value={state.endDate} onChange={handleChange} min={minDate} max={maxDate} />
                                </div>
                                <div className='btn1'>
                                    <CDBBtn color="dark" flat className="CDBBtn" onClick={getReports} >
                                        Get Reports
                                    </CDBBtn>
                                </div>
                            </div>
                        </div>
                        <div id="getreport" style={{ display: "none", overflow: "auto" }}>
                            {
                                dsr?.length > 0 && dsr?.map((status, index) => <ProjectCard key={index} data={status} />)
                            }
                        </div>
                    </div>
                    :
                    <div className={currentUrl != "/admin" ? "container" : null} >
                        <div className={currentUrl != "/admin" ? "DSRArchive__div" : "DSRArchive__div m-0 mb-3 w-100"}>
                            {currentUrl != "/admin" ? null : <h4>DSR Archive</h4>}
                            <div className='filters'>
                                <div>
                                    <label htmlFor="empCode">Employee Name</label><br />
                                    <select name="empCode" value={state.empCode} onChange={handleChange} required>
                                        <option disabled hidden></option>
                                        {
                                            employees && employees?.filter((el) => el.STATUS === "Active" && el.EMP_WORK_EMAIL.indexOf("@kaitongo.com") !== -1)?.map((emp) => <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>)
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='startDate'>Start Date</label><br />
                                    <input name='startDate' type='date' className="set-date-width" value={state.startDate} onChange={handleChange} max={maxDate} />
                                </div>
                                <div>
                                    <label htmlFor='endDate'>End date </label><br />
                                    <input name='endDate' type='date' className="set-date-width" value={state.endDate} onChange={handleChange} min={minDate} max={maxDate} />
                                </div>
                                <div className='btn1'>
                                    <CDBBtn color="dark" flat className="CDBBtn" onClick={getReports} >
                                        Get Reports
                                    </CDBBtn>
                                </div>
                            </div>
                        </div>
                        <div id="getreport" style={{ display: "none", overflow: "auto" }}>
                            {
                                dsr?.length > 0 && dsr?.filter((ele) => KaitongoEmp.includes(ele.EMP_CODE))?.map((status, index) => <ProjectCard key={index} data={status} />)
                            }
                        </div>
                    </div>

            }
        </>
    )
}
