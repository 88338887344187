import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux"
import { selectUser } from 'src/stores/userSlice';
import { selectEmployees } from 'src/stores/employeeSlice';
import { CDBBtn } from 'cdbreact'
import axios from "axios"
import ms from "ms";
import moment from 'moment';
import ApiClient from "../ApiClient"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Card from "../components/Card"

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    alignCenter: {
        textAlign: "center"
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    },
    green: {
        color: "green",
        fontWeight: '400',
        fontSize: '0.875rem'
    }

});

function WFHTabSA() {
    const classes = useStyles();
    const user = useSelector(selectUser)
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [wfhRows, setWfhRows] = useState([]);
    const [wfhCols, setWfhCols] = useState([]);
    const employees = useSelector(selectEmployees)
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const initialState = {
        empName: "",
        startDt: "",
        endDt: ""
    }
    const [state, setState] = useState(initialState)

    const deleteWfhReject = async (row) => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/leave/rejectDeleteWfh`, { REQUEST_ID: row.REQUEST_ID, STATUS: "Reject" })
            toast.success("Delete Request Rejected Successfully");
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        } catch (error) {
            toast.error("Something went wrong. Please try again later")
        }
    }

    const deleteWfhAccept = async (row) => {
        
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/leave/deleteWfh`, { data: { data: row } })
            toast.success("WFH Deleted Successfully");
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        } catch (error) {
            toast.error("Something went wrong. Please try again later")
        }
        
    }
    const fetchNewRequests = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/newWFHRequest`, { params: { mail: user?.mail,date:moment(new Date()-ms('15d')).format('YYYY-MM-DD') } })
            let wfhTableCols = [{
                headerName: "Name",
                field: "EMP_NAME",
                sortable: true,
                width: 250,
                renderCell: (params) => (
                    <span className={params?.row?.REQUEST_TYPE ?  classes.green :null }>{params?.row?.EMP_NAME}</span>
                )
            }, {
                headerName: "#Days",
                field: "NO_OF_DAYS",
                sortable: true,
                width: 80,
                renderCell: (params) => (
                    <span className={params?.row?.REQUEST_TYPE ?  classes.green :null }>{params?.row?.NO_OF_DAYS}</span>
                )
            }, {
                headerName: "Start Date",
                field: "START_DATE",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span className={params?.row?.REQUEST_TYPE ?  classes.green :null }>{moment(params.row?.START_DATE).format("DD-MMM-YY")}</span>
                )
            }, {
                headerName: "End Date",
                field: "END_DATE",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span className={params?.row?.REQUEST_TYPE ?  classes.green :null }>{moment(params.row?.END_DATE).format("DD-MMM-YY")}</span>
                )
            }, {
                headerName: "Reason",
                field: "REASON",
                sortable: true,
                width: 170,
                renderCell: (params) => (
                    <span className={params?.row?.REQUEST_TYPE ?  classes.green :null }>{params?.row?.REASON}</span>
                )
            },
            {
                headerName: "Status",
                field: "STATUS",
                sortable: true,
                width: 100,
                renderCell: (params) => (
                    <span className={params?.row?.REQUEST_TYPE ?  classes.green :null }>{!params?.row?.REQUEST_TYPE?params?.row?.STATUS:"Delete WFH"}</span>
                )
            },
            {
                headerName: "Action",
                field: "ACTION",
                sortable: true,
                width: 100,
                renderCell: (params) => (
                    !params?.row?.REQUEST_TYPE ?
                        <span>
                            <Tooltip title="Accept">
                                <i className="fa fa-check" style={{ marginRight: '10px', cursor: "pointer" }} onClick={() => acceptWfhReq(params.row)}></i>
                            </Tooltip>
                            <Tooltip title="Reject">
                                <i className="fa fa-times" aria-hidden="true" style={{ marginRight: '10px', cursor: "pointer" }} onClick={() => rejectWfhReq(params.row)}></i>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <i className="fa fa-trash" aria-hidden="true" style={{ cursor: "pointer" }} onClick={() => deleteWfhReq(params.row)}></i>
                            </Tooltip>
                        </span> :
                         <span className='text-center'>
                            <Tooltip title="Reject">
                                <i className="fa fa-times" aria-hidden="true" style={{ color: "#d90101", cursor: "pointer", marginRight: "6px" }} onClick={() => deleteWfhReject(params.row)}></i>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <i className="fa fa-trash" style={{ color: "#4f9fcf", cursor: "pointer", marginRight: "6px" }} onClick={() => deleteWfhAccept(params.row)}></i>
                            </Tooltip>
                        </span>

                )
            }
            ];
            setColumns(wfhTableCols)
            let tempData=data?.filter((ele)=>ele?.STATUS=="Pending" || ele?.DELETE_STATUS =="Request")
            setRows(tempData)
        }
        catch (error) {
            console.log(error)
        }
    }

    const acceptWfhReq = async (row) => {
        try {
            await axios.get(`${process.env.REACT_APP_BASE_URL}/leave/acceptWfh?id=${row.REQUEST_ID}`, { params: { name: user.name } })
            fetchNewRequests();
            toast.success("WFH request accepted")
        } catch (error) {
            console.log(error)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    const rejectWfhReq = async (row) => {
        try {
            await axios.get(`${process.env.REACT_APP_BASE_URL}/leave/rejectWfh?id=${row.REQUEST_ID}`)
            fetchNewRequests();
            toast.success("WFH request rejected")
        } catch (error) {
            console.log(error)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    const deleteWfhReq = async (row) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/leave/deleteWfh`, { data: { data: row } })
            fetchNewRequests();
            toast.success("WFH request deleted")
        } catch (error) {
            console.log(error)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    const getRecordsByDate = async () => {
        try {
            if (state.startDt && state.endDt) {
                if (new Date(state.startDt) <= new Date(state.endDt)) {
                    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getWfhRecords`, {
                        code: state.empName,
                        startDt: state.startDt,
                        endDt: state.endDt,
                    })
                    if (data.length === 0) {
                        toast.error("No matching records found")
                        document.getElementById("wfhRecordsTable").style.display = "none";
                    } else {
                        document.getElementById("wfhRecordsTable").style.display = "block";
                        let wfhTableCols = [{
                            headerName: "Emp ID",
                            field: "EMP_ID",
                            sortable: true,
                            width: 100
                        }, {
                            headerName: "Name",
                            field: "EMP_NAME",
                            sortable: true,
                            width: 220
                        }, {
                            headerName: "#Days",
                            field: "NO_OF_DAYS",
                            sortable: true,
                            width: 75
                        }, {
                            headerName: "Start Date",
                            field: "START_DATE",
                            sortable: true,
                            width: 130,
                            valueGetter: (params) => {
                                return moment(params.row?.START_DATE).format("DD-MMM-YYYY");
                            }
                        }, {
                            headerName: "End Date",
                            field: "END_DATE",
                            sortable: true,
                            width: 130,
                            valueGetter: (params) => {
                                return moment(params.row?.END_DATE).format("DD-MMM-YYYY");
                            }
                        }, {
                            headerName: "Reason",
                            field: "REASON",
                            sortable: true,
                            width: 200
                        }, {
                            headerName: "Status",
                            field: "STATUS",
                            sortable: true,
                            width: 100,
                            valueGetter: (params) => {
                                return (params.row?.STATUS) ? (params.row?.STATUS) : "Pending";
                            },
                        }
                        ];
                        setWfhCols(wfhTableCols)
                        setWfhRows(data)
                    }
                    setIsDisabled(false)
                } else toast.error("Start Date should be less than End Date")
            }
            else {
                setIsDisabled(false)
                toast.error("Start Date and End Date fields are mandatory")
            }
        }
        catch (error) {
            console.log(error)
            setIsDisabled(false)
            let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
            toast.error(errmsg)
        }
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
       
    }

    const resetFilter = () => {
        setState(initialState)
        document.getElementById("wfhRecordsTable").style.display = "none";
    }

    const initDateRange = () => {
        const minsec = ms('0d')
        const min_date = new Date(new Date(state.startDt) - minsec);
        const max_date = new Date(new Date());
        setMinDate(moment(min_date).format('YYYY-MM-DD'));
        setMaxDate(moment(max_date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        initDateRange();
    }, [state.startDt]);

    useEffect(() => {
        fetchNewRequests();
    }, [user])

    return (
        <div className='main__div'>
            <Card>
                <h4>New WFH Requests</h4>
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.EMP_NAME + row.START_DATE + row.END_DATE + row.STATUS}
                    {...rows}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    hideFooterSelectedRowCount
                />
            </Card>
            <Card>
                <h4>WFH Records</h4>
                <div className="row mt-2">
                    <div className="col-md-3">
                        <label htmlFor="empName">Employee Name</label><br />
                        <select name="empName" className="form-select" value={state.empName} onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                employees && employees.filter((el) => el.STATUS === "Active").map((emp) => {
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor='startDt'>Start Date<span className={classes.red}>*</span></label><br />
                        <input name='startDt' type='date' className="form-control" value={state.startDt} onChange={handleChange} required
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor='endDt'>End Date<span className={classes.red}>*</span></label><br />
                        <input name='endDt' type='date' className="form-control" value={state.endDt} onChange={handleChange} min={minDate} max={maxDate} required />
                    </div>
                    <div className="col-md-3">
                        <div style={{ float: "right" }}>
                            <div style={{ display: "inline-block", marginRight: "10px" }}>
                                <CDBBtn color="dark" flat className="CDBBtn" onClick={getRecordsByDate} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                                    Get Data
                                </CDBBtn>
                            </div>
                            <div style={{ display: "inline-block", marginRight: "10px" }}>
                                <CDBBtn color="dark" flat className="CDBBtn" onClick={resetFilter}>
                                    Reset
                                </CDBBtn>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="wfhRecordsTable" style={{ display: "none", overflow: "auto" }}>
                    <DataGrid
                        className={classes.root}
                        getRowId={row => row.REQUEST_ID}
                        {...wfhRows}
                        rows={wfhRows}
                        columns={wfhCols}
                        autoHeight
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        hideFooterSelectedRowCount
                    />
                </div>
            </Card>
        </div>
    )
}

export default WFHTabSA