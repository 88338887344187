import React from 'react'
import { useState } from 'react';
import { CDBBtn } from 'cdbreact'
import { Modal, ModalBody } from 'reactstrap'
import { makeStyles } from '@mui/styles';
import {useSelector} from "react-redux"
import { selectProjects } from 'src/stores/projectSlice';
import { selectEmployees } from 'src/stores/employeeSlice';
import moment from 'moment'
import '../css/Dailystatus.css'

const useStyles = makeStyles({   
    cardWrapper:{
        display:"inline-block",
        width:"33%"

    },
    spacing:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    flexContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    modal:{
        marginTop: "200px"
    },
    modalBody:{
        overflow: "auto",
        minHeight: "30vh"
    }
});
export default function ProjectCard(props) {
    const classes = useStyles()
    const { data } = props
    const allProjects = useSelector(selectProjects) 
    const employees = useSelector(selectEmployees) 
    const [open, setOpen] = useState(false)
    const [description, setDescription] = useState('')

    const viewDesc = (desc) => {
        setOpen(true)
        setDescription(desc)
    }

    const getProject = (code) =>{
        const project = allProjects.find(item => item.PROJECT_CODE === code)
        return project?.PROJECT_NAME
    }

     const getEmpName = (code) =>{
        const emp = employees.find(el => el.EMP_CODE === code)        
        return emp?.EMP_NAME
    }

    return (
        <div className={classes.cardWrapper}> 
            <div className="rounded m-2 bg-white card-container">
                <div className="form-group font-weight-bold m-2 inner-font">
                    <i className="fa fa-envelope font-weight-bold"></i>
                     <span>DSR - {getEmpName(data?.EMP_CODE)} - {moment(data?.STATUS_DATE).format("DD-MMM-YYYY")}</span>
                </div>
                <hr className='border border-dark' />
                <div className="form-group text-secondary inner-font">
                    <span><b>Project name</b> - {getProject(data?.PROJECT_CODE)}</span>
                </div>
                <div className="form-group text-secondary inner-font">
                    <span><b>Task name</b> - {data?.TASK_NAME}</span>
                </div>
                <div className="form-group text-secondary inner-font">
                    <span><b>Task type</b> - {data?.TASK_TYPE}</span>
                </div>
                <div className="form-group text-secondary inner-font">
                    <span><b>Status</b> - {data?.COMPLETION_STATUS}</span>
                    <span></span>
                </div>
                <div className="form-group text-secondary inner-font">
                    <span><b>Hours</b> - {data?.HOURS_SPENT}</span>
                </div>
                <hr />
                <div>
                    <CDBBtn onClick={() => viewDesc(data?.TASK_DESC)} color="dark" className="btn btn-dark text-center ml-3" flat >
                        View Description
                    </CDBBtn>
                </div>
            </div>             
            <Modal isOpen={open} className={classes.modal} toggle={() => setOpen(!open)}>
                <ModalBody className={classes.modalBody}>
                    <div>
                        <strong>Task Updates</strong>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
