import React, { useEffect } from 'react'
import Card from 'src/components/Card'
import Footer from './Footer';
import { makeStyles } from '@mui/styles';
import Sign from './../images/sign.png';
import moment from 'moment';
import { parse } from 'path-browserify';

const useStyles = makeStyles({

    hrletter: {
        border: " 1px solid black",
        borderCollapse: "collapse",
    },

});
function formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const formattedDate = `${day}  ${months[monthIndex]}, ${year}`;
    return formattedDate;
}
const today = new Date();
function OfferLetter(props) {
    const datediff = () => {
        const timeDiff = new Date(props?.data?.valid) - today;
        return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    }
    const classes = useStyles();
    return (
        <div className="container">
            <Card>
                <div className='d-flex justify-content-between'>
                    <p>{props?.data?.Name}<br />
                        {props?.data?.address1}<br />
                        {props?.data?.pincode}<br />
                        EMail:  {props?.data?.mail} <br />Mobile:  {props?.data?.number}</p>
                    <p>{formatDate(today)}</p>
                </div>
                <p>Dear {props.data.Name},</p>

                <p>With reference to your application and subsequent discussions, D-Aces India Private Limited (hereinafter referred to as Data Aces) is pleased to offer you the position of Software Engineer reporting to the Director. Data Aces welcomes you to its family. You will be based out of Chennai. Your remuneration details and Terms & Conditions of Employment are enclosed with this letter.</p>
                <p >After reading through this offer contents, please sign one copy of the letter to indicate your acceptance of the Offer Letter & Terms and Conditions of Employment. Please send it to us within {datediff()} days of receiving this offer letter. If the offer is not accepted within {datediff()} days, Data Aces reserves the right to rescind the offer.</p>
                <p>At the time of joining, you are requested to furnish certificates and mark sheets indicating your academic accomplishments. If previously employed, you are required to furnish the last drawn salary certificate or pay slip, experience certificate, and relieving certificates from the previous employers. Originals of the above-mentioned documents should be made available for scrutiny and copies for records. The enclosed offer is subject to the verification of the above documents and completion of the course. You are also requested to bring your current Passport along with Valid VISAs at the time of joining.</p>
                <p>Data Aces wishes you all the success. We look forward to a long and fruitful professional engagement with you.</p>

                <p className='pagebreakneed'> Date of Joining:     {formatDate(new Date(props?.data?.doj))}</p>


                <table style={{ "width": "100%" }} className={classes.hrletter}>
                    <tr className={classes.hrletter}>
                        <th className={classes.hrletter}>Remuneration Details</th>
                        <th colspan="2">{moment(new Date()).format('DD-MMM-YYYY')}</th>

                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>
                            Name <br /> Title  <br /> Location</td>
                        <td colSpan="2">
                            {props?.data?.Name}  <br />
                            {props?.data?.designation}  <br />
                            {props?.data?.address1}<br />
                            {props?.data?.pincode}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <th className={classes.hrletter}>Components</th>
                        <th className={classes.hrletter}>Per Month</th>
                        <th className={classes.hrletter}>Per Annum</th>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Basic</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.basic)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.basic) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>HRA</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.hra)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.hra) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Conveyance</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.conveyance)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.conveyance) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Education</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.education)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.education) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Medical</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.medical)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.medical) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Special Allowance</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.sp)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.sp) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>PF</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.pf)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.pf) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Graduity</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.graduity)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.graduity) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td className={classes.hrletter}>Performance Bonus</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.bonus)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.bonus) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <th className={classes.hrletter}>Total</th>
                        <td className={classes.hrletter}>{parseInt(props?.data?.total)}</td>
                        <td className={classes.hrletter}>{parseInt(props?.data?.total) * 12}</td>
                    </tr>
                    <tr className={classes.hrletter}>
                        <td colSpan="3"><p>⦁ Only "Basic" will be reckoned for PF, Gratuity and Leave Encashment.<br />⦁ PF mentioned above is the Company Contribution.<br />⦁ Income Tax will be deducted at source wherever applicable as per Indian Income Tax Rules.<br />⦁ Any of the above allowances may undergo changes or withdrawn at the sole discretion of the Management and will be governed by the rules and regulation of the Company from time to time.<br />⦁ Performance Bonus will be paid on successful completion of 12 months of service, subject to satisfactory performance</p></td>
                    </tr>
                </table>

                <p>Please maintain strict confidentiality of your Remuneration Details. Do not discuss, divulge, or communicate to anyone in any manner and you are responsible to protect your Compensation Package & Terms of Employment.</p>

                <center className='pagebreakneed'><strong>D-Aces India Private Limited<br />- Terms and Conditions of Employment</strong></center><br />

                <p>Following are the Terms and Conditions of your employment with D-Aces India (P) Ltd. You are expected to adhere to the following Terms and Conditions while you are employed with this dynamic growth oriented Company. Company reserves the right to change the Terms and Conditions based on the Company's policies.</p>
                <ul>
                    <li ><strong> Place of Work</strong><br />Your base location will be as specified in your offer letter. You may be transferred to any of the Company's other locations anywhere in India or abroad as and when required by the Company.</li>
                    <li><strong> Work week and Hours of Work </strong><br />Monday to Friday will be observed as a regular Work Week. You will be required to work 40 Hours per week. You will be following the Holiday schedule published for your place of work.</li>
                    <li><strong> Provident Fund </strong><br />You will be entitled to join the Provident Fund Scheme as per Provident Fund rules and regulations when it is set up by the company. Your contribution and Company's contribution to Provident Fund will be as per the prevailing PF rules.</li>
                    <li><strong> Gratuity </strong> <br />You will be entitled to receive Gratuity in accordance with the Company scheme as per prevailing Gratuity rules.</li>
                    <li><strong> Medical Benefits </strong><br />You will be eligible for medical benefits as mentioned in your remuneration details in accordance with the Company's scheme.</li>
                    <li><strong> Salary Review </strong><br />Salary Revisions will be based on Individual as well as Company performance.</li>
                    <li><strong> Retirement</strong><br />You shall retire at the age of 58 years, as per the current Company rules.</li>
                    <li><strong> Previous Employment Verification</strong><br />The company reserves the right to verify your documents and background through internal and external agencies. These may include your current and previous employment history, educational and professional credentials. Any discrepancy found will lead to termination of your employment with the Company.</li>
                    <li className='pagebreakneed'><strong> Leave</strong><br />You are eligible for an Earned leave of 15 date per calendar year. Earned will be calculated at the rate of 1 day for every 15 date worked. Earned Leave will be credited on Apr 1st of each year. A maximum of 10 date of leave per calendar year could be accumulated and carried forward to the next year with a maximum accumulation of 60 date.<br></br>In addition to the Earned leave, you can avail yourself of a maximum of two Special date Off per year on the occasion of your Birthday and Marriage Anniversary Day. All Weekly Offs, Paid Holidate and Special date Off falling in between your Leave period will not be counted as leave.</li>
                    <li><strong> Termination of Employment</strong><br />Your employment with the Company will be "at will", meaning that either you or the Company may terminate your employment at any time and for any reason, with or without cause.<br></br>Either Party will be allowed to terminate the contract of Employment by giving three months' notice in writing, subject however to the Company's right to pay salary in lieu thereof.<br></br>However, should you sign any service undertaking or agreement with the company as a part of your employment process or later in the course of employment with the company; you will be entitled to terminate the employment contract only after you comply with the terms and conditions of service undertaking or agreement in addition to the notice period mentioned above.<br></br>The Company shall have a right to terminate your employment without any notice and without any compensation in the event of any of the following<br></br>The Company shall have a right to terminate your employment without any notice and
                        without any compensation in the event of any of the following

                        <ul>
                            <li>
                                If any of your actions are in breach of the confidentially
                            </li>
                            <li>
                                Breach on your part of any terms and conditions of this contract or any other
                                rules madeapplicable to you in respect of your employment with the Company
                            </li>
                            <li>
                                Violation on your part of the authenticity and information declared at the time of
                                joiningthe Company
                            </li>
                            <li>
                                If your performance is found wanting and if no improvement is found in your
                                performance in spite of being informed
                            </li>
                            <li>
                                If you are found physically or mentally unfit to discharge your duties
                            </li>
                            <li className='pagebreakneed'>
                                If the company finds it difficult to gainfully employ you due to causes beyond the
                                control of the Company

                            </li>
                            <li>
                                Any misconduct on your part
                            </li>
                            <li>
                                Failure to carry out any of your duties and obligation
                            </li>
                        </ul>
                    </li>
                    <li> <strong>
                        Absence from work </strong><br></br>
                        In the event of your absence from work for more than two working date without
                        written permission from the Company, it would be assumed that you have voluntarily
                        abandoned the contract with the Company and your contract with the Company would
                        stand terminated. In such an event you shall be liable to pay the salary in lieu of
                        shortfall in the notice period and other dues payable to the Company as specified and
                        the Company has the right to file for the loss.
                    </li>
                    <li><strong>
                        Confidential Information </strong><br />
                        During the term of your employment, you may have access to and become familiar
                        with various confidential information belonging to the Company, its affiliates, and
                        customers. You shall acknowledge that such confidential information is owned and
                        shall continue to be owned solely by the Company, its affiliates, and customers as the
                        case may be. You shall agree not to use, communicate, reveal or otherwise make
                        available such information for any purpose whatsoever or to divulge such information
                        to any person, partnership, corporation or entity other than those specifically
                        designated by the Company unless such employee is compelled to disclose it by
                        judicial process.<br></br>
                        You will be required, as a condition of your employment with the Company, to sign the
                        Company's standard Confidential Information and Invention Assignment Agreement.

                    </li>
                    <li><strong>
                        Intellectual Property Rights</strong> <br />
                        The Intellectual Property rights associated with the Projects executed during the
                        Employment solely belong to D-Aces and all the work products including the source
                        code are the properties of D-Aces and solely belong to D-Aces and can't be disclosed to
                        anyone unless it is required to be disclosed for a judicial purpose. All such disclosures for
                        judicial purposes shall have prior written approvals by a D-Aces authorized signatory.
                    </li>
                    <li><strong>
                        Outside Activities </strong> <br />
                        While you render services to the Company, you agree that you will not engage in any
                        other employment, consulting or other business activity without the written consent of
                        the Company.In addition, while you render services to the company, you will not assist
                        any person or entity in competing with the Company, in preparing to compete with the
                        Company or in hiring any <br className='pagebreakneed' /> employees or consultants of the Company
                    </li>
                    <li><strong>
                        Non-Solicitation  </strong><br />
                        On termination of this contract either by the company or yourself, for a period of one year you should not directly or indirectly solicit, take up employment or do any sort of business with any person, company or firm with whom you were associated and the organization is having business during the period of one year prior to the termination of your employment. <br />

                        On termination of this contract either by the company or yourself, for a period of one year, you should not directly or indirectly entice, encourage or otherwise ask Company's current employees to leave their current employment to work with or for another organization or business that competes with the Company or is the customer of the Company.

                    </li>
                </ul>
                <br />
                {/* <div>
                    <p> Yours Faithfully for D-Aces India Private Limited </p>
                    <p> I have read the above contents and accept the same</p>
                </div> */}

                <div className='d-flex justify-content-between'>
                    <p>Yours Faithfully<br /> For D-Aces India Private Limited</p>

                    <p>I have read the contents and accepted <br /> the offer with the conditions stated</p>
                </div>
                <img src={Sign} style={{ height: "60px" }} />
                <div className='d-flex justify-content-between'>
                    <p>Venkata Rangarajan, Director</p>

                    <p>{props.data.Name}</p>
                </div>

            </Card>
        </div>

    )
}

export default OfferLetter