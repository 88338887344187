import { Button, collapseClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { CDBBtn } from 'cdbreact'
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';
import Card from 'src/components/Card';
const initialState = {
    Salutation: "",
    Name: "",
    Gender: "",
    Nationality: "",
    FathersName: "",
    MaritalStatus: "",
    SpouseName: "",
    BloodGroup: "",
    PhysicallyChallenged: "",
    DateOfBirth: "",
    Bank: "",
    BankBranch: "",
    IFCCode: "",
    BankAccountNumber: "",
    NameInBank: "",
    PFNo: "",
    FreshEmployement: "",
    PAN_Number: "",
    AADHAAR_Number: "",
    NamePerAadhaar: "",
    GenderPerAadhaar: "",
    DOB_Per_Aadhaar: "",
    Location: "",
    PersonalEmail: "",
    MobileNumber: "",
    EmergencyContact: "",
    RelationshipOfEmergency: "",
    PanCard: "",
    AadharCard: "",
    photo: "",
    ciia: "",
    education: ""
}

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    },
    errormsg: {
        color: "red",
        fontSize: "12px",
        marginLeft: "10px",
    },
    radio: {
        width: "2rem"
    },
});

const initialoffer = {
    valid: "",
    CTC: "",
    Designation: ""
}
function CandidateInfo() {
    const classes = useStyles();
    const [state, setState] = useState(initialState);
    const params = useParams();
    const nav = useNavigate();
    const [open, setOpen] = useState(false)
    const [pdfURL, setPdfURL] = useState("");
    const [valid, setvalid] = useState("");
    const [Offer, setOffer] = useState("");
    const [work, setWork] = useState([]);
    const [ug, setUg] = useState([]);
    const [pg, setPg] = useState([]);
    const getCandidate = async () => {
        try {
            var data = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getCandidate`, { params: { token: params.token } })
            data.data[0].DateOfBirth = moment(new Date(data.data[0].DateOfBirth)).format("YYYY-MM-DD");
            data.data[0].DOB_Per_Aadhaar = moment(new Date(data.data[0].DOB_Per_Aadhaar)).format("YYYY-MM-DD");
            setState(data.data[0]);
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong, please try again later")
        }
    }
    const parseWork = (input, file) => {
        const [Designation, From, To] = input?.split(',');
        return { Designation, From, To, file };
    };

    const parseUgPg = (input, file) => {
        const [Degree, department, collegeName, Passed] = input?.split(',');
        return { Degree, department, collegeName, Passed, file };
    };

    useEffect(() => {
        if (state.Name != "") {
            const outputWork = [
                parseWork(state.WORK_EXPERIENCE_1, state.WORK_EXP_CERTIFICATE_1),
                parseWork(state.WORK_EXPERIENCE_2, state.WORK_EXP_CERTIFICATE_2),
                parseWork(state.WORK_EXPERIENCE_3, state.WORK_EXP_CERTIFICATE_3),
                parseWork(state.WORK_EXPERIENCE_4, state.WORK_EXP_CERTIFICATE_4),
                parseWork(state.WORK_EXPERIENCE_5, state.WORK_EXP_CERTIFICATE_5)
            ];
            const outputUg = [
                parseUgPg(state.UG_DEGREE_1, state.UG_CERTIFICATE_1),
                parseUgPg(state.UG_DEGREE_2, state.UG_CERTIFICATE_2),
                parseUgPg(state.UG_DEGREE_3, state.UG_CERTIFICATE_3)
            ];
            const outputPg = [
                parseUgPg(state.PG_DEGREE_1, state.PG_CERTIFICATE_1),
                parseUgPg(state.PG_DEGREE_2, state.PG_CERTIFICATE_2),
                parseUgPg(state.PG_DEGREE_3, state.PG_CERTIFICATE_3)
            ];
            setWork(outputWork)
            setUg(outputUg);
            setPg(outputPg);
        }
    }, [state])

    const verifyIntern = async () => {
        if (valid) {
            try {
                var data = await axios.put(`${process.env.REACT_APP_BASE_URL}/admin/CandidateVerify`, { token: params.token, validdate: valid })
                toast.success("Candidate Verifed Successfully");
                setTimeout(() => {
                    nav("/hr-panel")
                }, 3000);
            } catch (error) {
                console.log(error);
                toast.error("Something went wrong, please try again later")
            }
        } else toast.error("Please Fill Offer Letter valid Date")
    }

    const verifyEmployee = async () => {
        if (Offer.valid && Offer.CTC && Offer.Designation) {
            try {
                var data = await axios.put(`${process.env.REACT_APP_BASE_URL}/admin/CandidateVerify`, { token: params.token, validdate: Offer.valid, ctc: parseInt(Offer.CTC), Designation: Offer.Designation })
                toast.success("Candidate Verifed Successfully");
                setTimeout(() => {
                    nav("/hr-panel")
                }, 3000);
            } catch (error) {
                console.log(error);
                toast.error("Something went wrong, please try again later")
            }
        } else (toast.error("Fill mandatory Fields"))
    }

    const showFile = (fileName) => {
        if (fileName) {
            setOpen(true);
            setPdfURL(`${process.env.REACT_APP_PUBLIC_URL}/public/onboarding/${fileName}`)
        } else toast.error("No File Found")
    }

    const handleInternOffer = (evt) => {
        const value = evt.target.value;
        setvalid(value);
    }

    function handleOffer(evt) {
        const value = evt.target.value;
        setOffer({
            ...Offer,
            [evt.target.name]: value
        });
    }
    useEffect(() => {
        getCandidate()
    }, [])

    return (
        <div className="d-flex">
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: 'scroll' }}>
                <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
                    <div className='px-5 mt-3 '>
                        <Card>
                            <form className='form'>
                                <div className="row mt-2">
                                    <h3 className='fw-bold text-muted'>General Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Salutation<span className={classes.red}>*</span></label><br />
                                        <select disabled className="form-select" name="Salutation" value={state.Salutation} required>
                                            <option selected hidden>Select Salutation</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Dr">Dr</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Full Name<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="Full_Name" value={state.Name} placeholder='Enter Name' required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Gender<span className={classes.red}>*</span></label><br />
                                        <select disabled className="form-select" name="Gender" value={state.Gender} required>
                                            <option selected hidden>Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Nationality<span className={classes.red}>*</span></label><br />
                                        {/* <input className="form-control " type='text' disabled name="Nationality" value={state.Nationality} placeholder='Enter Your Natianality' required /> */}
                                        <select disabled className="form-select" name="Nationality" value={state.Nationality} required>
                                            <option selected hidden>Select Your Natianality</option>
                                            <option value="India">India</option>
                                            <option value="US">US</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Father's Name<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="FathersName" value={state.FathersName} placeholder="Enter Your Father's Name" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Marital Status<span className={classes.red}>*</span></label><br />
                                        <select disabled className="form-select" name="MaritalStatus" value={state.MaritalStatus} required>
                                            <option selected hidden>Select Marital Status</option>
                                            <option value="Married">Married</option>
                                            <option value="Single">Single</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Spouse Name</label><br />
                                        <input className="form-control " type='text' disabled name="SpouseName" value={state.SpouseName} placeholder="Enter Your Spouse Name" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Blood Group<span className={classes.red}>*</span></label><br />
                                        {/* <input className="form-control " type='text' disabled name="BloodGroup" value={state.BloodGroup} placeholder="Enter Your Blood Group" required /> */}
                                        <select disabled className="form-select" name="BloodGroup" value={state.BloodGroup} required>
                                            <option selected hidden>Select Your Blood Group</option>
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                            <option value="I Don't Know">I Don't Know</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Physically Challenged</label><br />
                                        <select disabled className="form-select" name="PhysicallyChallenged" value={state.PhysicallyChallenged} required>
                                            <option selected hidden>Select Physically Challenged</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Date Of Birth<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='date' disabled name="DateOfBirth" value={state.DateOfBirth} placeholder="DD-MM-YYYY" max={`${(new Date().getFullYear() - 18)}-01-01`} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Upload Passport Size Copy
                                            <span className={classes.red}>*</span></label><br />
                                        {/* <input className="form-control " onChange={handleImageChange} type='file' name="Upload_Passport_Size_photo_Copy" placeholder="Enter Your Emergency number" required /> */}
                                        <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(state.photo)}>View</CDBBtn>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Address<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="address" value={state.Location} placeholder="Enter Your Address" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Pincode<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='number' disabled name="pincode" value={state.pincode} placeholder="Enter Your Pincode" required />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Bank Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Bank Name<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="Bank_Name" value={state.Bank} placeholder="Enter Your Bank Name" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Bank Branch<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="BankBranch" value={state.BankBranch} placeholder="Enter Your Branch Name" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">IFC Code<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="IFCCode" value={state.IFCCode} placeholder="Enter Your IFC Code" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Bank Account Number<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="BankAccountNumber" value={state.BankAccountNumber} placeholder="Enter Your Account Number" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Name in Bank Account<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="NameInBank" value={state.NameInBank} placeholder="Enter Your Name In Bank" required />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Professional Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">PF No</label><br />
                                        <input className="form-control " type='text' disabled name="PFNo" value={state.PFNo} placeholder="Enter Your PF No" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Whether Fresh employement for PF<span className={classes.red}>*</span></label><br />
                                        <select disabled className="form-select" name="FreshEmployement" value={state.FreshEmployement} required>
                                            <option selected hidden>Select Fresh Employement</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Name as per Aadhaar<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="NamePerAadhaar" value={state.NamePerAadhaar} placeholder="Enter Your Name per Aadhaar" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">AADHAAR Number<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="AADHAAR_Number" value={state.AADHAAR_Number} placeholder="Enter Your AADHAAR Number" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Gender as per Aadhaar<span className={classes.red}>*</span></label><br />
                                        <select disabled className="form-select" name="GenderPerAadhaar" value={state.GenderPerAadhaar} required>
                                            <option selected hidden>Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Date of Birth as per Aadhaar<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='date' disabled name="Date_of_Birth_as_per_Aadhaar" value={state.DOB_Per_Aadhaar} placeholder="DD-MM-YYYY" max={`${(new Date().getFullYear() - 18)}-01-01`} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName"> AADHAAR Card Upload
                                            <span className={classes.red}>* (front and back)</span></label><br />
                                        {/* <input className="form-control " onChange={handlePdfChange} type='file' name="Aadhaar_Card_Upload" placeholder="Enter Your Emergency number" required />
                                        {PdfError?.Aadhaar_Card_Upload && <div className={classes.red}>{PdfError.Aadhaar_Card_Upload}</div>} */}
                                        <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(state.AadharCard)}>View</CDBBtn>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">PAN Number<span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="PAN_Number" value={state.PAN_Number} placeholder="Enter Your PAN Number" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">PAN Card Upload
                                            <span className={classes.red}>* (front and back)</span></label><br />
                                        {/* <input className="form-control " type='file' name="Pan_Card_Upload" onChange={handlePdfChange} placeholder="Enter Your Emergency number" required />
                                        {PdfError?.Pan_Card_Upload && <div className={classes.red}>{PdfError.Pan_Card_Upload}</div>} */}
                                        <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(state.PanCard)}>View</CDBBtn>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName"> CIIA
                                            <span className={classes.red}>*</span></label><br />
                                        {/* <input className="form-control " type='file' onChange={handlePdfChange} name="ciia" placeholder="Enter Your Emergency number" required />
                                        {PdfError?.ciia && <div className={classes.red}>{PdfError.ciia}</div>} */}
                                        <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(state.ciia)}>View</CDBBtn>
                                    </div>
                                </div>
                                {
                                    state?.FreshEmployement == "No" ?
                                        <h5 className='fw-bold text-muted'>Work Experience</h5>
                                        : null
                                }
                                {work?.filter((ele) => ele.Designation != "")?.map((form, index) => (
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <label htmlFor="newEmpName">Designation<span className={classes.red}>*</span></label><br />
                                            <input className="form-control" disabled type='text' name="Designation" value={work[index].Designation} placeholder="Enter Your Designation" required />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="newEmpName">From<span className={classes.red}>*</span></label><br />
                                            <input className="form-control" disabled type='date' name="From" value={work[index].From} placeholder="Select From" required />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="newEmpName">To<span className={classes.red}>*</span></label><br />
                                            <input className="form-control" disabled type='date' name="To" value={work[index].To} placeholder="Select To" required />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="newEmpName"> Experience Certificate
                                                <span className={classes.red}>*</span></label><br />
                                                <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(work[index].file)}>View</CDBBtn>
                                        </div>
                                    </div>
                                ))}
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Contact Details</h3>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Mobile Number
                                            <span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="MobileNumber" value={state.MobileNumber} placeholder="Enter Your Mobile Number" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Personal Email Address
                                            <span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="PersonalEmail" value={state.PersonalEmail} placeholder="Enter Your Personal Email Address" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Emergency contact number
                                            <span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="EmergencyContact" value={state.EmergencyContact} placeholder="Enter Your Emergency number" required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="newEmpName">Relationship of emergency contact number
                                            <span className={classes.red}>*</span></label><br />
                                        <input className="form-control " type='text' disabled name="RelationshipOfEmergency" value={state.RelationshipOfEmergency} placeholder="Enter Your Relationship" required />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <h3 className='fw-bold text-muted'>Educational Details</h3>
                                    {
                                        state?.TYPE == "Intern" ?
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">Roll Number On College<span className={classes.red}>*</span></label><br />
                                                <input className="form-control " type='text' disabled name="RollNo" value={state.rollNo} placeholder="Enter Your Roll No" required />
                                            </div>
                                            : null
                                    }
                                    <div className="col-md-3">
                                        <label htmlFor=""> 10th MarkSheet Upload
                                            <span className={classes.red}>*</span></label><br />
                                        <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(state.tenth)}>View</CDBBtn>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="newEmpName">Passed Out Year<span className={classes.red}>*</span></label><br />
                                        <select className="form-control" name="TenthPassed" value={state.TENTHPASSED} placeholder="Enter Your College Name" disabled required>
                                            <option value="" selected disabled></option>
                                            {
                                                [...Array(30 + 1)].map((_, index) => (
                                                    <option key={index} value={new Date().getFullYear() - index}>
                                                        {new Date().getFullYear() - index}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="newEmpName"> 12th MarkSheet Upload
                                            <span className={classes.red}>*</span></label><br />
                                        <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(state.twelth)}>View</CDBBtn>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="newEmpName">Passed Out Year<span className={classes.red}>*</span></label><br />
                                        <select className="form-control" name="TwelthPassed" value={state.TWELTHPASSED} placeholder="Enter Your College Name" disabled required>
                                            <option value="" selected disabled></option>
                                            {
                                                [...Array(30 + 1)].map((_, index) => (
                                                    <option key={index} value={new Date().getFullYear() - index}>
                                                        {new Date().getFullYear() - index}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <h5 className='fw-bold text-muted mt-2'>UG</h5>
                                    {ug?.filter((ele => ele.Degree != ""))?.map((form, index) => (
                                        <div className='row'>
                                            <div className="col-md-2">
                                                <label htmlFor="newEmpName">Degree<span className={classes.red}>*</span></label><br />
                                                <select className="form-control" name="Degree" value={ug[index].Degree} placeholder="Enter Your Degree" disabled required>
                                                    <option value="" selected disabled>Select an option</option>
                                                    <option value="BSc">B.Sc</option>
                                                    <option value="BBA">B.B.A</option>
                                                    <option value="BE">B.E</option>
                                                    <option value="BTech">B.Tech</option>
                                                    <option value="BCA">B.CA</option>
                                                    <option value="BA">BA</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">Department<span className={classes.red}>*</span></label><br />
                                                <input className="form-control" type='text' name="department" value={ug[index].department} placeholder="Enter Your Department" disabled required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">College Name<span className={classes.red}>*</span></label><br />
                                                <input className="form-control" type='text' name="collegeName" value={ug[index].collegeName} placeholder="Enter Your College Name" disabled required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName"> UG Provisional/Degree Certificate
                                                    <span className={classes.red}>*</span></label><br />
                                                <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(ug[index].file)}>View</CDBBtn>
                                            </div>
                                            <div className="col-md-1">
                                                <label htmlFor="newEmpName">Passed Year<span className={classes.red}>*</span></label><br />
                                                <select className="form-control" name="Passed" value={ug[index].Passed} placeholder="Enter Your College Name" disabled required>
                                                    <option value="" selected disabled></option>
                                                    {
                                                        [...Array(25 + 1)].map((_, index) => (
                                                            <option key={index} value={new Date().getFullYear() - index}>
                                                                {new Date().getFullYear() - index}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                    ))}
                                    {
                                        pg?.[0]?.Degree != "" ?
                                            <h5 className='fw-bold text-muted'>PG</h5> : null
                                    }
                                    {pg?.filter(ele => ele.Degree != "")?.map((form, index) => (
                                        <div className='row'>
                                            <div className="col-md-2">
                                                <label htmlFor="newEmpName">Degree</label><br />
                                                <select className="form-control " name="Degree" value={pg[index].Degree} placeholder="Enter Your Degree" disabled required>
                                                    <option value="" selected disabled>Select an option</option>
                                                    <option value="MSc">MSc</option>
                                                    <option value="MBA">MBA</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MTech">MTech</option>
                                                    <option value="MCA">MCA</option>
                                                    <option value="MA">MA</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">Department</label><br />
                                                <input className="form-control " type='text' name="department" value={pg[index].department} placeholder="Enter Your Department" disabled required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName">College Name</label><br />
                                                <input className="form-control " type='text' name="collegeName" value={pg[index].collegeName} placeholder="Enter Your College Name" disabled required />
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="newEmpName"> PG Provisional/Degree Certificate
                                                </label><br />
                                                <CDBBtn color="dark" flat className="CDBBtn mt-0 mx-auto w-25" onClick={() => showFile(pg[index].file)}>View</CDBBtn>
                                            </div>
                                            <div className="col-md-1">
                                                <label htmlFor="newEmpName">Passed Year</label><br />
                                                {/* <input className="form-control " type='number' name="PgPassed" value={pg[index].PgPassed} placeholder="Enter Your College Name" onChange={(event) => handlePgFormChange(index, event)} required /> */}
                                                <select className="form-control " name="PgPassed" value={pg[index].Passed} placeholder="Enter Your College Name" disabled required>
                                                    <option value="" disabled></option>
                                                    {
                                                        [...Array(25 + 1)].map((_, index) => (
                                                            <option key={index} value={new Date().getFullYear() - index}>
                                                                {new Date().getFullYear() - index}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                    ))}
                                    <div className='col-md-4'></div>

                                    <div>
                                        <hr></hr>
                                    </div>
                                    {
                                        state?.TYPE == "Intern" ?

                                            <div>
                                                <div className="col-md-4">
                                                    <label htmlFor="newEmpName"> valid Offer Letter Date
                                                        <span className={classes.red}>*</span></label><br />
                                                    <input className="form-control " type='date' name="valid" value={valid} placeholder="DD-MM-YYYY" min={moment(new Date()).format('YYYY-MM-DD')} onChange={handleInternOffer} required />
                                                </div>
                                                <div className="col-md-12 text-center">

                                                    <CDBBtn color="dark" flat className="CDBBtn mx-auto w-25" onClick={() => verifyIntern()} >
                                                        Verify
                                                    </CDBBtn>

                                                </div>
                                            </div>
                                            : state?.TYPE == "Employee" ?
                                                <div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="newEmpName"> valid  Date
                                                            <span className={classes.red}>*</span></label><br />
                                                        <input className="form-control " type='date' name="valid" value={Offer.valid} placeholder="DD-MM-YYYY" min={moment(new Date()).format('YYYY-MM-DD')} onChange={handleOffer} required />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="newEmpName">CTC
                                                            <span className={classes.red}>*</span></label><br />
                                                        <input className="form-control " type='number' name="CTC" value={Offer.CTC} placeholder="Enter CTC " onChange={handleOffer} required />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="newEmpName">Designation
                                                            <span className={classes.red}>*</span></label><br />
                                                        <input className="form-control " type='text' name="Designation" value={Offer.Designation} placeholder="Enter Designation" onChange={handleOffer} required />
                                                    </div>
                                                    <div className="col-md-12 text-center">

                                                        <CDBBtn color="dark" flat className="CDBBtn mx-auto w-25" onClick={() => verifyEmployee()} >
                                                            Verify
                                                        </CDBBtn>

                                                    </div>
                                                </div>
                                                : null
                                    }
                                </div>
                            </form>
                        </Card>
                        <Modal size="lg" isOpen={open} toggle={() => setOpen(!open)}>
                            <ModalBody style={{
                                overflow: "auto",
                            }}>
                                <div>
                                    <iframe
                                        title="PDF Viewer"
                                        src={pdfURL}
                                        style={{ width: '100%', height: '500px' }}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <CDBBtn color="dark" flat className="CDBBtn mx-auto w-25" onClick={() => setOpen(!open)}>
                                        Close
                                    </CDBBtn>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CandidateInfo