import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { CDBBtn } from 'cdbreact';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Card from 'src/components/Card';
import { selectUser } from 'src/stores/userSlice';
import { Modal, ModalBody } from 'reactstrap'
import Footer from './Footer';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    }, modal: {
        marginTop: "200px"
    },
    modalBody: {
        overflow: "auto",
        minHeight: "30vh"
    }
});
function QueriesPage() {
    const classes = useStyles();
    const [isDisabled, setIsDisabled] = useState(false)
    const user = useSelector(selectUser)
    const initialState = {
        message: ""
    }
    const [state, setState] = useState(initialState);
    const [state2, setState2] = useState(initialState);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false)
    const [description, setDescription] = useState([])
    const [chatToken, setChatToken] = useState("");
    function handleChangeMessage(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            message: value
        });
    }

    function handleChangeMessage2(evt) {
        const value = evt.target.value;
        setState2({
            ...state2,
            message: value
        });
    }
    const viewDesc = (desc) => {
        setOpen(true)
        setDescription(desc)
        setChatToken(desc[0].REQUEST_ID);

    }

    const getQueries = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getQueries`)
            var userData = await user;
            var empCodeData = await data?.filter((ele) => ele?.EMP_CODE == userData?.emp_code)
            var statusfilter = await empCodeData?.filter((ele) => ele.STATUS != "Closed")
            setRows(statusfilter)
            let leaveTableCols = [{
                headerName: "Raised Date",
                field: "RAISED_DATE",
                sortable: true,
                width: 120,
                renderCell: (params) => (
                    <span>{moment(params?.row?.RAISED_DATE).format("DD-MMM-YYYY")}</span>
                )
            }, {
                headerName: "Description",
                field: "DESCRIPTION",
                sortable: true,
                width: 450,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <span className='text-center'> <CDBBtn onClick={() => viewDesc(params?.row?.messages)} color="dark" className="btn btn-dark text-center ml-3" flat >
                        View Description
                    </CDBBtn></span>
                )
            },
            {
                headerName: "Status",
                field: "STATUS",
                sortable: true,
                width: 100,
                renderCell: (params) => (
                    <span>{params?.row?.STATUS}</span>
                )
            }, {
                headerName: "Action",
                field: "Action",
                sortable: true,
                width: 100,
                renderCell: (params) => {
                    return <>

                        {
                            params?.row?.STATUS == "Pending" ? null :
                                <Tooltip title="Close">
                                    <span>
                                        <i className="fa fa-check" style={{ color: "green", cursor: "pointer", marginRight: "6px" }} onClick={() => handleClose(params.row)}></i>
                                    </span>
                                </Tooltip>
                        }

                    </>
                }
            }]
            setColumns(leaveTableCols)
        } catch (error) {
            console.log(error)
        }
    }

    // const handlePending = async (row) => {
    //     try {
    //         await axios.put(`${process.env.REACT_APP_BASE_URL}/user/queryStatus`, { requestId: row.REQUEST_ID, status: "PENDING", message: row?.RESOLVED_MESSAGE })
    //         toast.success("Resubmit Successfully");
    //         getQueries();
    //     } catch (error) {
    //         toast.error("Something went wrong. Please try again later")
    //     }
    // }

    const handleClose = async (row) => {
        const data = {
            'raised_id': row.REQUEST_ID,
            'status': "Closed",
        }
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/user/queryStatus`, data)
            toast.success("Closed Successfully");
            getQueries();
        } catch (error) {
            toast.error("Something went wrong, please try again later")
        }
    }
    useEffect(() => {
        getQueries();
    }, [user])


    const handleSubmit = async () => {
        if (state.message.length > 2) {
            const data = {
                'raised_by': user.name,
                'raised_date': moment(new Date).format("YYYY-MM-DD"),
                'message': state.message,
                'raised_id': uuidv4(),
                'empcode': user.emp_code,
            }
            setIsDisabled(true)
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/user/queries`, data)
                toast.success('Query added successfully')
                setState(initialState)
                setIsDisabled(false)
                getQueries();
            } catch (error) {
                console.log(error)
                setIsDisabled(false)
                toast.error("Something went wrong, please try again later")
            }
        } else {
            toast.error("All fields are required")
        }
    }

    const resubmitting = async () => {
        if (state2.message.length > 2) {
            const data = {
                'raised_by': user.name,
                'raised_date': moment(new Date).format("YYYY-MM-DD"),
                'message': state2.message,
                'raised_id': chatToken,
                'TYPE': "EMP_QUERY",
                'status': "Pending",
            }
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/user/addQueries`, data)
                toast.success('Query added successfully')
                setState2(initialState)
                getQueries();
                setOpen(false);
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong, please try again later")
            }
        } else {
            toast.error("All fields are required")
        }
    }
    return (
        <div className="d-flex profile">
            <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                <div style={{ height: "100%", overflowY: "scroll" }}>
                    <div style={{ boxShadow: "0 0 5px 2px #aaafb3" }}>
                        <div className={classes.container}>
                            <Card>
                                <h4>Submit Your Queries </h4><br />
                                <div className='row mt-2'>

                                    <div className="col-md-9">
                                        <label htmlFor="password">Message<span className={classes.red}>*</span></label><br />
                                        <textarea rows={4} className="form-control" placeholder="Enter message" value={state.message} onChange={handleChangeMessage} required></textarea>
                                    </div>
                                    <div className="col-md-3 mt-5 pt-4">
                                        <div style={{ float: "right" }}>
                                            <CDBBtn color="dark" flat className="CDBBtn" onClick={() => handleSubmit()} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                                                Submit
                                            </CDBBtn>
                                        </div>
                                    </div>
                                    <span style={{ color: 'red', marginTop: "20px" }}>Note*-Message should not exceed 30 words</span>
                                </div>
                            </Card>
                            <Card>
                                <h4>Your Queries</h4>
                                <DataGrid
                                    className={classes.root}
                                    getRowId={rows => rows.REQUEST_ID}
                                    rows={rows ?? []}
                                    columns={columns}
                                    autoHeight
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 5, page: 0 },
                                        },
                                    }}
                                    // pageSizeOptions={[5, 10]}
                                    hideFooterSelectedRowCount
                                />

                            </Card>
                            <Modal isOpen={open} className={classes.modal} toggle={() => setOpen(!open)}>
                                <ModalBody className={classes.modalBody}>
                                    <div>
                                        {
                                            description?.map((ele) => {
                                                if (ele.TYPE == "EMP_QUERY") {
                                                    return (
                                                        <>
                                                            <div> <strong>YOUR QUERY</strong>   <small>{ele.RAISED_DATE}</small></div>

                                                            <p>{ele.MESSAGE}</p>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <div> <strong>RESOLVED MESSAGE</strong>   <small>{ele.RAISED_DATE}</small></div>

                                                            <p>{ele.MESSAGE}</p>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className='row mt-2'>

                                        <div className="col-md-9">
                                            <label htmlFor="password">Message<span className={classes.red}>*</span></label><br />
                                            <textarea rows={4} className="form-control" value={state2.message} onChange={handleChangeMessage2} placeholder="Enter message" required></textarea>
                                        </div>
                                        <div className="col-md-3 mt-5 pt-4">
                                            <div style={{ float: "right" }}>
                                                <CDBBtn color="dark" flat className="CDBBtn"
                                                    onClick={() => resubmitting()}
                                                >
                                                    Submit
                                                </CDBBtn>
                                            </div>
                                        </div>
                                        <span style={{ color: 'red', marginTop: "20px" }}>Note*-Message should not exceed 30 words</span>
                                    </div>

                                </ModalBody>
                            </Modal>
                            <Footer></Footer>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </div>

    )
}

export default QueriesPage