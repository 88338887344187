import { useState, useEffect } from 'react'
import "../css/EmployeeTabSA.css"
import "../css/commonStyles.css"
import { CDBBtn } from 'cdbreact'
import moment from 'moment'
// import validator from "validator";
import { makeStyles } from '@mui/styles';
import validator from 'validator';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux"
import { selectEmployees } from 'src/stores/employeeSlice';
import ApiClient from "../ApiClient"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from "../components/Card"
import Footer from './Footer'
import AddressProofLetter from './AddressProofLetter'
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import img from '../../src/images/DAFullLogo.png';
import footerimg from '../../src/images/NameLogo.png'
import ExitLetter from './ExitLetter'
import InternshipCompletionLetter from './InternshipCompletionLetter'
const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows": {
            fontFamily: 'Montserrat'
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Montserrat',
            fontWeight: 'bold'
        },
    },
    container: {
        padding: "2%"
    },
    red: {
        color: "red",
        fontSize: "12px"
    },
    errormsg: {
        color: "red",
        fontSize: "12px",
        marginLeft: "10px",
    },
    radio: {
        width: "2rem"
    },
});

function EmployeeTabSA() {
    const classes = useStyles();
    const employees = useSelector(selectEmployees)
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [disableStatus, setDisableStatus] = useState(false)
    const [disableProfile, setDisableProfile] = useState(false)
    const [IntCodeFrLetter, setIntCodeFrLetter] = useState()
    const [internList, setInternList] = useState()
    let State = require('country-state-city').State;
    const [activeEmp, setActiveEmp] = useState("Yes")
    const initialState = {
        newEmpName: "",
        empMail: "",
        empID: "",
        reportingManager: "",
        dateOfJoin: "",
        location: "",
        empState: "",
        city: "",
        designation: "",
        password: "",
        empCode: "",
        status: "",
        exitDate: "",
        resignation: "",
        employee: "",
        allowUpdate: ''
    }
    const [state, setState] = useState(initialState)
    const [internDataState, setInternDataState] = useState({
        InternCode: '',
        completionStatus: '',
        DateOfCompletion: ''
    })
    const CanadianStates = State.getAllStates().filter((el) => {
        return el.countryCode === "CA"
    }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    const IndianStates = State.getAllStates().filter((el) => {
        return el.countryCode === "IN"
    }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    const USStates = State.getAllStates().filter((el) => {
        return el.countryCode === "US"
    }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
        if (evt.target.name === "empMail") {
            if (validator.isEmail(value)) {
                setEmailError('Valid')
            } else {
                setEmailError('Enter valid Email!')
            }
        }
        else if (evt.target.name === "activeEmp") {
            setActiveEmp(evt.target.value)
        }
    }
    function handleInternChange(e) {
        setInternDataState({
            ...internDataState,
            [e.target.name]: e.target.value
        });
    }
    const updateEmpStatus = async () => {
        if (state.status && state.empCode && state.exitDate && state.resignation) {
            setDisableStatus(true)
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/changeEmpSts`, {
                    status: state.status,
                    code: state.empCode,
                    exitDate: moment(state.exitDate).format("YYYY-MM-DD"),
                    resignation: moment(state.resignation).format("YYYY-MM-DD"),
                })
                toast.success("Status updated successfully");
                setDisableStatus(false)
                setState(initialState)
            }
            catch (error) {
                setDisableStatus(false)
                console.log(error)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        } else {
            toast.error("All fields are mandatory");
        }
    }

    const allowProfileUpdate = async () => {
        if (state.employee && state.allowUpdate) {
            setDisableProfile(true)
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/allowProfileUpdate`, {
                    employee: state.employee,
                    allowUpdate: state.allowUpdate,
                })
                toast.success("Status updated successfully");
                setDisableProfile(false)
                setState(initialState)
            }
            catch (error) {
                setDisableProfile(false)
                console.log(error)
                let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                toast.error(errmsg)
            }
        } else {
            toast.error("All fields are mandatory");
        }
    }

    const addNewEmployee = async () => {
        if (state.newEmpName && state.empMail && state.empID && state.reportingManager && state.dateOfJoin && state.location && state.empState && state.city && state.designation && state.password && state.role) {
            if (emailError === "Valid") {
                setIsDisabled(true)
                try {
                    await axios.post(`${process.env.REACT_APP_BASE_URL}/register`, {
                        empname: state.newEmpName,
                        empmail: state.empMail,
                        empID: state.empID,
                        manager: state.reportingManager,
                        joiningDate: state.dateOfJoin,
                        location: state.location,
                        empState: state.empState,
                        city: state.city,
                        designation: state.designation,
                        password: state.password,
                        role:state.role
                    })
                    toast.success("New user added successfully")
                    setIsDisabled(false)
                    setState(initialState)
                }
                catch (error) {
                    setIsDisabled(false)
                    console.log(error)
                    let errmsg = (error?.response) ? (error?.response?.data?.message ? error?.response?.data?.message : `${error?.response?.status} - ${error?.response?.statusText}`) : "Something went wrong. Please try again later"
                    toast.error(errmsg)
                }
            } else toast.error("Please enter a valid email")
        }
        else
            toast.error("All fields are mandatory to create new user")
    }

    const getEmployeeDetails = () => {
        let empTableColumns = [{
            headerName: "Emp ID",
            field: "EMP_ID",
            sortable: true,
            width: 100,
        }, {
            headerName: "Employee Name",
            field: "EMP_NAME",
            sortable: true,
            width: 200,
        }, {
            headerName: "Email",
            field: "EMP_WORK_EMAIL",
            sortable: true,
            width: 250,
        }, {
            headerName: "Designation",
            field: "DESIGNATION",
            sortable: true,
            width: 150,
        }, {
            headerName: "Status",
            field: "STATUS",
            sortable: true,
            width: 80,
        }, {
            headerName: "Reporting Manager",
            field: "REPORTING_MANAGER_ID",
            sortable: true,
            width: 100,
            valueGetter: (params) => {
                switch (params.row?.REPORTING_MANAGER_ID) {
                    case 'venkata@data-aces.com': return 'Venkata';
                    case 'shanti@data-aces.com': return 'Shanti';
                    case 'naren@data-aces.com': return 'Naren';
                    default: return params.row?.REPORTING_MANAGER_ID.split('@')[0];
                }
            }
        }, {
            headerName: "Location",
            field: "EMP_COUNTRY",
            sortable: true,
            width: 80,
        }, {
            headerName: "SSN",
            field: "SSN",
            sortable: true,
            width: 150,
            valueGetter: (params) => {
                return params.row?.SSN || "-";
            }
        }, {
            headerName: "Passport Number",
            field: "PASSPORT_NO",
            sortable: true,
            width: 150,
            valueGetter: (params) => {
                return params.row?.VISA_NO || "-";
            }
        }, {
            headerName: "Passport Expiry Date",
            field: "PASSPORT_EXPIRY_DT",
            sortable: true,
            width: 150,
            valueGetter: (params) => {
                return params.row?.VISA_EXPIRY_DT || "-";
            }
        }, {
            headerName: "Father's Name",
            field: "EMP_FATHERS_NAME",
            sortable: true,
            width: 150,
            valueGetter: (params) => {
                return params.row?.EMP_FATHERS_NAME || "-";
            }
        }, {
            headerName: "DOB",
            field: "EMP_DATE_OF_BIRTH",
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return moment(params.row?.EMP_DATE_OF_BIRTH).format("DD-MMM-YYYY") || "-";
            }
        }, {
            headerName: "Blood Group",
            field: "EMP_BLOOD_GROUP",
            sortable: true,
            width: 100,
            valueGetter: (params) => {
                return params.row?.EMP_BLOOD_GROUP || "-";
            }
        }, {
            headerName: "Mobile Number",
            field: "EMP_MOBILE_NO",
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return params.row?.EMP_MOBILE_NO || "-";
            }
        }, {
            headerName: "Address",
            field: "",
            sortable: true,
            width: 400,
            headerAlign: 'center',
            valueGetter: (params) => {
                return `${params.row?.EMP_ADDRESS_1 || ''}, ${params.row?.EMP_ADDRESS_2 || ''}, ${params.row?.EMP_CITY || ''}`;
            }
        }, {
            headerName: "State",
            field: "EMP_STATE",
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return params.row?.EMP_STATE || "-";
            }
        }, {
            headerName: "WFH",
            field: "REMOTE_EMPLOYEE",
            sortable: true,
            width: 80,
        }, {
            headerName: "Aadhar No.",
            field: "EMP_AADHAR_NO",
            sortable: true,
            width: 130,
            valueGetter: (params) => {
                return params.row?.EMP_AADHAR_NO || "-";
            }
        }, {
            headerName: "PAN No.",
            field: "EMP_PAN_NO",
            sortable: true,
            width: 130,
            valueGetter: (params) => {
                return params.row?.EMP_PAN_NO || "-";
            }
        }, {
            headerName: "PF/UAN No",
            field: "EMP_PF_NO",
            sortable: true,
            width: 130,
            valueGetter: (params) => {
                return params.row?.EMP_PF_NO || "-";
            }
        }, {
            headerName: "Name as in Bank",
            field: "EMP_NAME_AS_IN_BANK",
            sortable: true,
            width: 200,
            valueGetter: (params) => {
                return params.row?.EMP_NAME_AS_IN_BANK || "-";
            }
        }, {
            headerName: "Bank",
            field: "EMP_BANK_NAME",
            sortable: true,
            width: 80,
            valueGetter: (params) => {
                return params.row?.EMP_BANK_NAME || "-";
            }
        }, {
            headerName: "Account Number",
            field: "EMP_ACCOUNT_NO",
            sortable: true,
            width: 150,
            valueGetter: (params) => {
                return params.row?.EMP_ACCOUNT_NO || "-";
            }
        }, {
            headerName: "IFSC Code",
            field: "EMP_IFSC_NO",
            sortable: true,
            width: 150,
            valueGetter: (params) => {
                return params.row?.EMP_IFSC_NO || "-";
            }
        }, {
            headerName: "Personal Mail",
            field: "EMP_PERSONAL_EMAIL",
            sortable: true,
            width: 250,
            valueGetter: (params) => {
                return params.row?.EMP_PERSONAL_EMAIL || "-";
            }
        }, {
            headerName: "Date of Joining",
            field: "EMP_DATE_OF_JOIN",
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return moment(params.row?.EMP_DATE_OF_JOIN).format("DD-MMM-YYYY") != "Invalid date" ? moment(params.row?.EMP_DATE_OF_JOIN).format("DD-MMM-YYYY") : "-";
            }
        }, {
            headerName: "Date of Resignation",
            field: "EMP_DATE_OF_RESIGNATION",
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return moment(params.row?.EMP_RESIGNATION_DATE).format("DD-MMM-YYYY") != "Invalid date" ? moment(params.row?.EMP_RESIGNATION_DATE).format("DD-MMM-YYYY") : "-";
            }
        }, {
            headerName: "Date of Exit",
            field: "EMP_DATE_OF_EXIT",
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return moment(params.row?.EMP_EXIT_DATE).format("DD-MMM-YYYY") != "Invalid date" ? moment(params.row?.EMP_EXIT_DATE).format("DD-MMM-YYYY") : "-";
            }
        }, {
            headerName: "Letter Generation",
            field: "LETTER_GENERATION",
            width: 220,
            renderCell: (params) => (
                params?.row?.STATUS == "Active" ?
                    <span>
                        <CDBBtn color="dark" flat className="CDBBtn mt-0" onClick={() => handleAddress(params?.row)} >
                            Address Proof
                        </CDBBtn>
                    </span> :
                    <span className='text-center'>
                        <CDBBtn color="dark" flat className="CDBBtn mt-0" onClick={() => handleExit(params?.row)} >
                            Exit Letter
                        </CDBBtn>
                    </span>
            )
        },
        ];
        let modifiedData = [];
        if (activeEmp === "Yes")
            modifiedData = employees.filter((el) => el.STATUS === "Active")
        else
            modifiedData = employees.filter((el) => el.STATUS === "Inactive")
        setRows(modifiedData)
        setColumns(empTableColumns)
    }

    const handleAddress = (row) => {
        var data = {
            Name: row?.EMP_NAME,
            id: row?.EMP_ID,
            designation: row?.DESIGNATION,
            doj: row?.EMP_DATE_OF_JOIN,
            address1: row?.EMP_ADDRESS_1,
            address2: row?.EMP_ADDRESS_2,
        }
        printHandler(<AddressProofLetter data={data} />, "Address-proof-Letter")
    }

    const handleExit = (row) => {
        var data = {
            Name: row?.EMP_NAME,
            id: row?.EMP_ID,
            designation: row?.DESIGNATION,
            doj: moment(row?.EMP_DATE_OF_JOIN).format("DD-MMM-YYYY"),
            exit: moment(row?.EMP_EXIT_DATE).format("DD-MMM-YYYY"),
            resignation: moment(row?.EMP_RESIGNATION_DATE).format("DD-MMM-YYYY")
        }
        printHandler(<ExitLetter data={data} />, "Exit-Letter")
    }

    const printHandler = (content, pdfName) => {
        const printElement = ReactDOMServer.renderToString(content);
        // const printElement = pdfJSX();
        const opt = {
            margin: [30, 10, 30, 10],
            filename: `${pdfName}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            html2canvas: { dpi: 75, scale: 2, letterRendering: true },
            // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            pagebreak: { before: '.pagebreakneed', avoid: ['img', 'table', 'p', 'tr', 'th'] }
        };
        const pageBreakElements = document.getElementsByClassName('page-break');
        for (let i = 0; i < pageBreakElements.length; i++) {
            pageBreakElements[i].style.pageBreakBefore = 'always';
        }
        html2pdf().from(printElement).set(opt).toPdf().get('pdf').then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                // set footer to every page
                pdf.setPage(i);
                // set footer font
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
                pdf.text(78,
                    pdf.internal.pageSize.getHeight() - 20, 'D-ACES INDIA PRIVATE LIMITED');
                pdf.text(23,
                    pdf.internal.pageSize.getHeight() - 15, 'Gateway Office Parks - Block B6 - First Floor | 16 GST Road | Perungalathur Chennai 600063 | INDIA');
                // you can add the line separator as an image, consult the docs below to properly set the place of the image
                pdf.addImage(img, 'png', 80, 5, 45, 13)
                pdf.addImage(footerimg, 'png', 90, pdf.internal.pageSize.getHeight() - 30, 32, 5)
            }
        }).save();
    }

    const renderStates = () => {
        switch (state?.location) {
            case "Canada": return CanadianStates?.map((state) => {
                return (
                    <option key={state.name} value={state.name}>{state.name}</option>
                )
            })
            case "India": return IndianStates?.map((state) => {
                return (
                    <option key={state.name} value={state.name}>{state.name}</option>
                )
            })
            case "US": return USStates?.map((state) => {
                return (
                    <option key={state.name} value={state.name}>{state.name}</option>
                )
            })
            default: return IndianStates?.map((state) => {
                return (
                    <option key={state.name} value={state.name}>{state.name}</option>
                )
            })
        }
    }
    const InternList = async () => {
        let data = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getIntern`)
        setInternList(data.data)
    
    }
    const updateInternStatus = async () => {
        if (!internDataState.InternCode || !internDataState.completionStatus || !internDataState.DateOfCompletion) {
            toast.error('All fields are required')
        } else {
            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/admin/ChangeInternStatus`, internDataState)
                toast.success("Intern Status updated successfully");
                setInternDataState({
                    InternCode: '',
                    completionStatus: '',
                    DateOfCompletion: ''
                })
                InternList()
            } catch (err) {
                console.log(err)
            }
        }       
    }

    const InternCodeForOffer = (e) => {
        setIntCodeFrLetter(e.target.value)
    }

    const generateInternshipCompletionLetter = async () => {
        try {
            var link_id = IntCodeFrLetter;
            var data = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getCandidate`, { params: { token: link_id } })
            const outputUg = [
                parseUgPg(data?.data[0]?.UG_DEGREE_1),
                parseUgPg(data?.data[0]?.UG_DEGREE_2),
                parseUgPg(data?.data[0]?.UG_DEGREE_3),
                parseUgPg(data?.data[0]?.PG_DEGREE_1),
                parseUgPg(data?.data[0]?.PG_DEGREE_2),
                parseUgPg(data?.data[0]?.PG_DEGREE_3)
            ];
            const passedYears = Math.max(...outputUg?.filter((ele) => ele.Passed != "")?.map((item) => parseInt(item?.Passed)));
            const CurrentCollege = outputUg?.filter((ele) => ele.Degree != "")?.filter((obj) => obj.Passed == passedYears);
            var data = {
                Name: data?.data[0].Name,
                rollNo: data?.data[0].rollNo,
                degree: CurrentCollege[0]?.Degree,
                depart: CurrentCollege[0]?.department,
                collegename: CurrentCollege[0]?.collegeName,
                startdate: data?.data[0]?.DATE_OF_JION,
                enddate: data?.data[0]?.DATE_OF_EXIT,
            }
            printHandler(<InternshipCompletionLetter data={data} />, "Internship-Comletion-Letter")
            setIntCodeFrLetter('');
        } catch (error) {
            toast.error("Something went wrong, please try again later")
        }
    }

    const parseUgPg = (input) => {
        const [Degree, department, collegeName, Passed] = input?.split(',');
        return { Degree, department, collegeName, Passed };
    };
    useEffect(() => {
        getEmployeeDetails();
        InternList()
    }, [activeEmp])
    useEffect(()=>{
        getEmployeeDetails();
    },[employees])

    return (
        <div className={classes.container}>
            <Card>
                <h4>Add New Employee</h4>
                <form className='form'>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <label htmlFor="newEmpName">Name<span className={classes.red}>*</span></label><br />
                            <input className="form-control" type='text' name="newEmpName" value={state.newEmpName} placeholder='Enter Name' onChange={handleChange} required />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="empMail"> Email<span className={classes.red}>*</span></label>{
                                emailError !== "Valid" && <span className={classes.errormsg}>{emailError}</span>
                            } <br />
                            <input className="form-control" type='email' name="empMail" value={state.empMail} placeholder='Enter Email' onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="empID"> Employee ID<span className={classes.red}>*</span></label><br />
                            <input className="form-control" type='text' name="empID" value={state.empID} placeholder='Enter Employee ID' onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="Reporting Manager"> Reporting Manager<span className={classes.red}>*</span></label><br />
                            <select className="form-select" name="reportingManager" value={state.reportingManager} onChange={handleChange} required>
                                <option disabled hidden></option>
                                <option value="venkata@data-aces.com">Venkata </option>
                                <option value="shanti@data-aces.com">Shanti </option>
                                <option value="naren@data-aces.com">Naren </option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="Location">Base Location<span className={classes.red}>*</span></label><br />
                            <select className="form-select" name="location" value={state.location} onChange={handleChange} required>
                                <option disabled hidden></option>
                                <option value="Canada">Canada</option>
                                <option value="India">India</option>
                                <option value="US">US</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="empState">State<span className={classes.red}>*</span></label><br />
                            <select className="form-select" name="empState" value={state.empState} onChange={handleChange} required>
                                <option disabled hidden></option>
                                {
                                    renderStates()
                                }
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="city">City<span className={classes.red}>*</span></label><br />
                            <input className="form-control" type='text' name="city" value={state.city} placeholder='Enter City' onChange={handleChange} required />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="dateOfJoin">Date of Joining<span className={classes.red}>*</span></label><br />
                            <input className="form-control" type='date' name="dateOfJoin" value={state.dateOfJoin} onChange={handleChange} required /><br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="designation">Designation<span className={classes.red}>*</span></label><br />
                            <input className="form-control" type='text' name="designation" value={state.designation} placeholder='Enter Designation' onChange={handleChange} required />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="password">Password<span className={classes.red}>*</span></label><br />
                            <input className="form-control" type='text' name="password" value={state.password} placeholder='Create Password' onChange={handleChange} required />
                            {/* <button className="auto-generate" onClick={createPassword}>Auto generate</button> */}
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="role">Role<span className={classes.red}>*</span></label><br />
                            <select className='form-control' name='role' value={state?.role} onChange={handleChange}>
                                <option value="" selected disabled>Select Role</option>
                                <option value="Employee">Employee</option>
                                <option value="GT">Graduate Trainee</option>
                            </select>
                        </div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3'></div>
                        <div className="col-md-3">
                            <div style={{ float: "right" }}>
                                <CDBBtn color="dark" flat className="CDBBtn" onClick={addNewEmployee} disabled={isDisabled} style={{ opacity: isDisabled ? '50%' : '100%' }}>
                                    Add New Employee
                                </CDBBtn>
                            </div>
                        </div>
                    </div>
                </form>
            </Card>
            <Card>
                <h4>Change Employee Status</h4>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label>Employee Name<span className={classes.red}>*</span></label><br />
                        <select name="empCode" value={state.empCode} onChange={handleChange} className="form-select" required>
                            <option disabled hidden></option>
                            {
                                employees && employees?.filter((el) => el.STATUS === "Active").map((emp) => {
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Status<span className={classes.red}>*</span></label><br />
                        <select name="status" className="form-select" value={state.status} onChange={handleChange} required>
                            <option disabled hidden></option>
                            <option value="Inactive">Inactive</option>
                            <option value="Active">Active</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Date of Resignation<span className={classes.red}>*</span></label><br />
                        <input className="form-control" type='date' name="resignation" value={state.resignation} onChange={handleChange} required />
                    </div>
                    <div className="col-md-3">
                        <label>Date of Exit<span className={classes.red}>*</span></label><br />
                        <input className="form-control" type='date' name="exitDate" value={state.exitDate} onChange={handleChange} required />
                    </div>
                    <div className='col-md-7'></div>
                    <div className="col-md-2">
                        <div style={{ float: "right" }}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={updateEmpStatus} disabled={disableStatus} style={{ opacity: disableStatus ? '50%' : '100%' }}>
                                Update
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>
                <h4>Change Intern Status</h4>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label>Intern Name<span className={classes.red}>*</span></label><br />
                        <select name="InternCode" className="form-select" required value={internDataState?.InternCode} onChange={handleInternChange}>
                            <option value=''>Please Select</option>
                            {
                                internList && internList?.filter((el) => el.TYPE === "Intern"&& el?.COMPLETION_STATUS !='Yes').map((emp, index) => {
                                    return <option key={emp.ID} value={emp.ID}>{emp?.NAME+'--'+emp?.INTERN_CODE}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Internship completion status<span className={classes.red}>*</span></label><br />
                        <select name="completionStatus" className="form-select" value={internDataState?.completionStatus} onChange={handleInternChange} required>
                            <option disabled hidden></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Date of Completion<span className={classes.red}>*</span></label><br />
                        <input className="form-control" type='date' name="DateOfCompletion" required value={internDataState?.DateOfCompletion} onChange={handleInternChange} />
                    </div>

                    <div className="col-md-2">
                        <div style={{ float: "right" }}>
                            <CDBBtn color="dark" flat className="CDBBtn" disabled={disableStatus} onClick={updateInternStatus} style={{ opacity: disableStatus ? '50%' : '100%' }}>
                                Update
                            </CDBBtn>
                        </div>
                    </div>
                </div>
                <h4>Generate Internship completion letter</h4>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label>Intern Name<span className={classes.red}>*</span></label><br />
                        <select className="form-select" required value={IntCodeFrLetter} onChange={InternCodeForOffer}>
                            <option value=''>Please Select</option>
                            {
                                internList && internList?.filter((el) =>el?.verified =="Yes"&& el.TYPE == "Intern" && el?.COMPLETION_STATUS =='Yes'&& el?.DATE_OF_EXIT  ).map((emp, index) => {
                                    return <option key={emp.LINK_ID} value={emp.LINK_ID}>{emp?.NAME+'--'+emp?.INTERN_CODE}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-8">
                        <div style={{ float: "right" }}>
                            <CDBBtn color="dark" flat className="CDBBtn" disabled={disableStatus} onClick={generateInternshipCompletionLetter} style={{ opacity: disableStatus ? '50%' : '100%' }}>
                                Generate Letter
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>
                <h4>Allow Employee Profile Update</h4>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor='employee'>Employee Name<span className={classes.red}>*</span></label><br />
                        <select name="employee" className="form-select" value={state.employee} onChange={handleChange} required>
                            <option disabled hidden></option>
                            {
                                employees?.filter((el) => el.STATUS === "Active").map((emp) => {
                                    return <option key={emp.EMP_CODE} value={emp.EMP_CODE}>{emp.EMP_NAME}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="allowUpdate">Allow Update<span className={classes.red}>*</span></label><br />
                        <select name="allowUpdate" className="form-select" value={state.allowUpdate} onChange={handleChange} required>
                            <option disabled hidden></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <div style={{ float: "right" }}>
                            <CDBBtn color="dark" flat className="CDBBtn" onClick={allowProfileUpdate} disabled={disableProfile} style={{ opacity: disableProfile ? '50%' : '100%' }}>
                                Update Status
                            </CDBBtn>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>
                <h4>Employee Details</h4>
                <div className="col-md-12">
                    <span className={classes.marginRight}><input className={classes.radio} type="radio" name="activeEmp" value="Yes" checked={activeEmp === "Yes"} onChange={handleChange} /> Get Active Employees</span>
                    <span className={classes.marginRight}><input className={classes.radio} type="radio" name="activeEmp" value="No" checked={activeEmp === "No"} onChange={handleChange} style={{ marginLeft: "5rem" }} /> Get Inactive employees</span>
                </div>
                <DataGrid
                    className={classes.root}
                    getRowId={row => row.EMP_CODE}
                    rows={rows}
                    columns={columns}
                    autoHeight
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    hideFooterSelectedRowCount
                />
            </Card>
            <Footer />
        </div>
    )
}

export default EmployeeTabSA